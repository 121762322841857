<template>


    <h1 class="formTitle">Hello <b>{{userInfo.username}}</b> ! How Can We Help You ?</h1>

    <div id="whyContactUs" class="step" v-if="showWhyContactUs">
        <h2>Why you are contacting us ?</h2>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault111" value="update" v-model="whatToShowNext" checked>
            <label class="form-check-label" for="flexRadioDefault111">
                Update my license quota
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault222" v-model="whatToShowNext" value="wrong" >
            <label class="form-check-label" for="flexRadioDefault222">
                Something went wrong ?
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault33" v-model="whatToShowNext" value="feedback">
            <label class="form-check-label" for="flexRadioDefault333">
                Leave a feedback !
            </label>
        </div>

    </div>
    <div id="nextStep" class="step" v-if="!showWhyContactUs && !showThankYouMessage">
        <div v-if="whatToShowNext=='update'" >
            <h2>Please specify what do you want to update</h2>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="number_of_projects" id="flexCheckDefault11" v-model="whatToUpdate.number_of_projects">
                <label class="form-check-label" for="flexCheckDefault11">
                    Number of projects that can be created
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="number_of_overall_iterations" id="flexCheckDefault22" v-model="whatToUpdate.number_of_overall_iterations">
                <label class="form-check-label" for="flexCheckDefault22">
                    Number of overall iterations
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="number_of_iterations_per_project" id="flexCheckDefault33" v-model="whatToUpdate.number_of_iterations_per_project">
                <label class="form-check-label" for="flexCheckDefault133">
                    Number of versions per project
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="number_of_active_projects" id="flexCheckDefault44" v-model="whatToUpdate.number_of_active_projects">
                <label class="form-check-label" for="flexCheckDefault144">
                    Number of active projects
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="license_ends" id="flexCheckDefault55" v-model="whatToUpdate.license_ends">
                <label class="form-check-label" for="flexCheckDefault55">
                    Extend license expiration date
                </label>
            </div>
                
        </div>
        <div v-if="whatToShowNext=='wrong'">
            
                <h2>Please describe specifically what went wrong</h2>
            
            
                <textarea class="form-control" v-model="complaintText" id="exampleFormControlTextarea1" rows="6"></textarea>
            
        </div>
        <div v-if="whatToShowNext=='feedback'">
            <h2>We would love to hear back from you !</h2>
            
            
            <textarea class="form-control" v-model="feedbackText" id="exampleFormControlTextarea12" rows="6"></textarea>
        </div>
    </div>
    <div id="thankYouPage" class="step" v-if="showThankYouMessage">
        <h1 class="mb-5">Thank You for contacting us! <br> We'll be in touch with you as soon as possible <br>Sunthetics Team</h1>
        
        <a href="/dashboard"><button class="btn btn-success">Back to dashboard </button></a>
    </div>
    <div id="controlButtons" class="btns" v-if="!showThankYouMessage">
        <button id="previous" class="btn" :disabled="showWhyContactUs" @click="showPreviousStep()">
            Previous
        </button>
        <div>
            <button id="submit" class="btn btn-success" v-if="!showWhyContactUs" @click="submitContact()">
                <p v-if="whatToShowNext=='update'">Get a quota !</p> <p v-if="whatToShowNext=='wrong'">Send complaint</p> <p v-if="whatToShowNext=='feedback'">Submit feedback</p>
            </button>
            <button id="next" class="btn" v-if="showWhyContactUs" @click="showNextStep()">
                Next
            </button>
        </div>
        
    </div>
    
    
    
    

</template>

<script>
import Mybutton from "./Mybutton.vue"
export default {
  name: "ContactformForCLients",
  components: {
      Mybutton
  },
  props:{
      userInfo:{}
  },
  data(){
      return{
          showWhyContactUs:true,
          whatToShowNext:'update',
          showThankYouMessage:false,
          whatToUpdate:{
              "number_of_projects":false,
              'number_of_overall_iterations':false,
              "number_of_iterations_per_project":false,
              "number_of_active_projects":false,
              "license_ends":false
          },
          complaintText:'',
          feedbacktext:''
      }
  },
  methods:{
      showNextStep(){
          this.showWhyContactUs = false
          
      },
      showPreviousStep(){
          this.showWhyContactUs = true
      },
      submitContact(){
          this.showThankYouMessage = true
      }
  },
  
  
};
</script>
<style scoped>
.formTitle{
    font-size: 2.5rem;
    color: #002060;
    width: 50%;
    margin: 5rem auto 3rem auto;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 5px;
    letter-spacing: 2px;
    padding:0.5rem 1rem;
}
.step{
    background-color: #f0f0f0;
    width: 50%;
    margin: auto;
    padding:0.5rem 1rem;
    border-radius:5px;
    height: fit-content;
}
#whyContactUs h2{
    font-size: 2rem;
    width: 100%;
    margin: auto;
    text-align: start;
    margin-bottom: 1rem;
    margin-left: 1rem;
    color: #002060;
}
#whyContactUs h1{
    font-size: 2rem;
    margin: auto;
    text-align: center;
    text-justify: center;
    margin-bottom: 1rem;
    
    color: #002060;
}

#whyContactUs div{
    border: lightgrey 2px solid;
    padding: 0.5rem 1rem;
    height: 4rem;
    display: flex;
    align-items: center;
    margin: 1rem;
    border-radius: 5px;
}
#whyContactUs div label{
    font-size: 1.3rem;
    margin-left: 1rem;
}
#whyContactUs div input{
    margin-left: 0.2rem;
    font-size: 1.3rem;
}

#nextStep {
    display: flex;
    flex-direction: column;
    height: fit-content;
}
#nextStep div{
    height: fit-content;
    width: 100%;
}
#nextStep div div{
    
    margin: 0.8rem auto;
    padding: 1rem 2rem;
    height: 4rem;
    border: lightgray 2px solid;
    
}
#nextStep div div label{
    font-size: 1.1rem;
}


.btns{
    background-color: #f0f0f0;
    width: 30%;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 1rem 2rem;
}
.btns #next , #previous{
    background-color: #002060;
    color: white;
}
.btns p{
    padding: 0;
    margin: 0;
}
#thankYouPage h1{
    text-align: center;
    font-size: 2rem;
}
#thankYouPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
