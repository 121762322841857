<template>
  <div class="content">
    
    <div :class="[dataneeded.suggestedpoints[0][0].length >=4 ? 'tablesCol' : 'tables']">
      <div class="d-flex flex-column align-items-center">
      <div class="">
        <table class="table table-striped table-bordered border-dark bg-light ">
          <thead>
          <tr class="grey-color text-light">
            <th :colspan="dataneeded.suggestedpoints[0][0].length" class="text-center align-middle">Suggested next points | Version {{suggestedPointsVersion+1}}</th>
            
            <th colspan="1" class="text-center">
              <div class="dropdown">
                <button class="btn dropdown-toggle text-light" type="button" id="dropdownMenuButton4"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  Change Version
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                  <li v-for="(version,index) in dataneeded.suggestedpoints.length" :key="version">
                    <a class="dropdown-item font" href="#" @click="changeSuggestedPointsVersion(index)">Version {{index+ 1}}</a>
                  </li>
                  
                </ul>

              </div>
            </th>
          </tr>
          <tr class="text-center">
            <th scope="col">#</th>
            <th v-for="header in dataneeded.headers[0]" :key="header">{{ header }}</th>
          </tr>
          </thead>
          <tbody class="text-center">

          <tr v-for="(arr,index) in dataneeded.suggestedpoints[suggestedPointsVersion]" :key="arr">
            <th scope="row">{{ index + 1 }}</th>

            <td v-for="num in arr" :key="num">
              {{ Number(num).toFixed(2) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>

        <div class="inter d-flex justify-content-around mb-2 ">
          <h4>Interpretation</h4>
          <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleSugg" aria-expanded="false" aria-controls="collapseExampleSugg">
            Show
          </button>
        </div>
        <div class="collapse mb-3 w-50" id="collapseExampleSugg">
        
            <div class="card card-body">
              The table shows the coordinates of the next suggested experiments/data points. These
              coordinates are suggested based on modified Bayesian Optimization acquisition functions to
              reduce uncertainty of the system and achieve accurate reaction predictions with the smallest
              possible datasets.
              </div>
        </div> 
      <hr v-if="dataneeded.suggestedpoints[0][0].length >=4" class="mt-2 mb-3 w-100"/>
        
        
      <hr v-if="dataneeded.suggestedpoints[0][0].length >=4" class="mt-2 mb-5 w-100"/>
      </div>
      <div class="d-flex flex-column align-items-center">
      <div class="">
        <table class="table table-striped rounded table-bordered border-dark bg-light">
          <thead>
          <tr class="text-light grey-color">
            <th :colspan="dataneeded.max_pred_coord[0][0].length+1" class="text-center align-middle">
              Current {{iter_}} | Version {{maxPredVersion+1}}
            </th>
            <th colspan="1" class="text-center">
              <div class="dropdown">
                <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton5"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  Change Version
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton5">
                  <li v-for="(version,index) in dataneeded.suggestedpoints.length" :key="version">
                    <a class="dropdown-item font" href="#" @click="changeMaxPredVersion(index)">Version {{index+ 1}}</a>
                  </li>
                </ul>
              </div>
            </th>
          </tr>
          <tr class="text-center">
            <th scope="col">#</th>
            <th v-for="header in dataneeded.headers[0]" :key="header">{{ header }}</th>
            <th>Target</th>
          </tr>
          </thead>
          <tbody class="text-center">

          <tr v-for=" (arr, index) in dataneeded.max_pred_coord[maxPredVersion]" :key="arr">
            <th scope="row">{{ index + 1 }}</th>
            <td v-for="num in arr" :key="num">{{ Number(num).toFixed(3) }}</td>
            <td>{{ Number(dataneeded.max_pred_eff[maxPredVersion][index]).toFixed(3) }}</td>

          </tr>
          </tbody>
        </table>
      </div>
      <div class="inter d-flex justify-content-between w-50">
            <h4>Interpretation</h4>
            <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleIter" aria-expanded="false" aria-controls="collapseExampleIter">
              Show
            </button>
      </div>
      <div class="collapse mb-3 w-50" id="collapseExampleIter">
          <div class="card card-body ">
            The table shows the coordinates where an optimal reaction performance has been identified for the current prediction.
</div>
      </div> 
      <hr v-if="dataneeded.suggestedpoints[0][0].length >=4" class="mt-2 mb-3 w-100"/>
        
        
      <hr v-if="dataneeded.suggestedpoints[0][0].length >=4" class="mt-2 mb-5 w-100"/>
        </div>   
    </div>
    <hr v-if="dataneeded.suggestedpoints[0][0].length <4" class="mt-2 mb-3 w-100"/>
        
        
      <hr v-if="dataneeded.suggestedpoints[0][0].length <4" class="mt-2 mb-5 w-100"/>

    
    

      
        <div  class=" graph-title d-flex flex-row justify-content-between align-items-center">
                <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Suggested Next Points | Version {{suggestedPointsGraphVersion+1}}</h4>
                <button v-if="parseInt(dataneeded.number_of_inputs[0]) >3" class="btn btn-light text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVariablesToPlot4" aria-expanded="false" aria-controls="collapseVariablesToPlot4">Change variables to plot</button>

                <div class="dropdown">
                  <button class="btn text-light btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1787" data-bs-toggle="dropdown" aria-expanded="false">
                    Change Version
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1787">
                    <li v-for="(version, index) in dataneeded.suggestedpoints.length" :key="version">
                      <a class="dropdown-item" href="#suggestedpoints" @click="changeSuggestedPointsGraphVersion(index)" >Version {{index+1}}</a>
                    </li>

                  </ul>
                </div>
              </div>
        

        <div  id="suggestedpoints"></div>
        <div  class="collapse" id="collapseVariablesToPlot4">
        <div class="d-flex flex-column justify-content-around align-items-center ">

          <h4 class="title">Variables to plot</h4>
          <p class="fw-2">Select three variables to plot, the rest will be in the sliders</p>
          <div class="d-flex flex-row">

            <div class="me-4" v-for="variable in parseInt(dataneeded.number_of_inputs)" :key="variable" >
              <label class="me-2">{{dataneeded.headers[0][variable-1]}}</label>
              <input type="checkbox" v-model="variablesToPlot[variable-1]" @change="checkCheckboxes()">
            </div>
          </div>
          <button class="btn btn-dark" :disabled="!canPlot" @click="plotNewVariables()">Plot</button>
        </div>
      </div>
        <div :class ="noDataPointsAlertClass" style="margin:1rem auto">{{noDataPointsAlertMessage}}</div>
        <div id="suggestedPointsGraph"></div>
        <div class=" inter d-flex justify-content-around">
            <h4>Interpretation</h4>
            <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleSuggGraph" aria-expanded="false" aria-controls="collapseExampleSuggGraph">
              Show
            </button>
          </div>
          <div class="collapse" id="collapseExampleSuggGraph">
              <div class="card card-body">
The graph helps visualize the next suggested experiments/data points in the reaction space. 
The blue points represent the training data that has already been collected and uploaded by the user. In black, the plot highlights the suggested data points to be included in the dataset for the next iteration

These coordinates are suggested based on modified Bayesian Optimization acquisition functions to reduce uncertainty of the system and achieve accurate reaction predictions with the smallest possible datasets.    </div>
          </div> 
        
      
    
  </div>

</template>
<script>
export default {
  name: "Iteration",
  props: {
    dataneeded: {}
  },
  data() {
    return {
      graph: {},
      data: {},
      suggestedPointsVersion:this.dataneeded.versionToShow,
      maxPredVersion:this.dataneeded.versionToShow,
      suggestedPointsGraphVersion:this.dataneeded.versionToShow,
      graph_exists : true,
      dataToPlot:[],
      fourthInputData:[],
      fifthInputData:[],
      sixthInputData:[],
      seventhInputData:[],
      eightInputData:[],
      fourthInputDataToDisplay:"",
      fifthInputDataToDisplay:"",
      sixthInputDataToDisplay:"",
      seventhInputDataToDisplay:"",
      eightInputDataToDisplay:"",
      filteredData:[],
      filteredData2:[],
      filteredData3:[],
      filteredData4:[],
      filteredData5:[],
      filteredInputData:[],
      filteredInputData2:[],
      filteredInputData3:[],
      filteredInputData4:[],
      filteredInputData5:[],
      filteredMaxData:[],
      filteredMaxData2:[],
      filteredMaxData3:[],
      filteredMaxData4:[],
      filteredMaxData5:[],
      vall:"",
      layout:{},
      inputData:[],
      allData:[],
      iterMax:[],
      iterMaxTarget:[],
      noDataPointsAlertClass:'',
      noDataPointsAlertMessage:'',
      iter_:'',
      variablesToPlot:new Array(parseInt(this.dataneeded.number_of_inputs)).fill(false),
      colOne:0,
      colTwo:1,
      colThree:2,
      colFour:3,
      colFive:4,
      colSix:5,
      colSeven:6,
      colEight:7,
      canPlot:false
    }
  },
  created() {

    this.data = this.dataneeded.suggestedpoints
    if(this.dataneeded.number_of_inputs>3){
      this.graph_exists = false
      
      
    }
    else{

      this.graph = this.dataneeded.suggestednextpointsgraph
    }
    if(this.dataneeded.optimisation_target=="max"){
        this.iter_ = "Maxima"
      }
      else if(this.dataneeded.optimisation_target=='min'){
        this.iter_="Minima"
      }
      else{
        this.iter_="for optimal value"
      }

  },
  methods: {
    getgraph() {
      Plotly.newPlot('suggestedpoints', JSON.parse(this.graph[this.suggestedPointsGraphVersion]).data, JSON.parse(this.graph[this.suggestedPointsGraphVersion]).layout)
    },
    changeSuggestedPointsVersion(index){
      this.suggestedPointsVersion = index
    },
    changeMaxPredVersion(index){
      this.maxPredVersion = index
    },
    changeSuggestedPointsGraphVersion(index){
      this.suggestedPointsGraphVersion = index
      if(parseInt(this.dataneeded.number_of_inputs)<=3){

        Plotly.newPlot('suggestedpoints', JSON.parse(this.graph[this.suggestedPointsGraphVersion]).data, JSON.parse(this.graph[this.suggestedPointsGraphVersion]).layout)
      }
      else{
        this.manageSuggestedNextPoint(index)
      }

    },
    changeIterationVersion(index){
      this.suggestedPointsVersion = index
      this.maxPredVersion = index
      this.suggestedPointsGraphVersion = index
      if(parseInt(this.dataneeded.number_of_inputs)<=3){

        Plotly.newPlot('suggestedpoints', JSON.parse(this.graph[this.suggestedPointsGraphVersion]).data, JSON.parse(this.graph[this.suggestedPointsGraphVersion]).layout)
      }
      else{
        this.manageSuggestedNextPoint(index)
      }

    },
    getCol(matrix, col){
       var column = [];
       for(var i=0; i<matrix.length; i++){
          column.push(matrix[i][col]);
       }
       return column;
    },
    manageSuggestedNextPoint(version){
      this.dataToPlot = [...this.dataneeded.suggestedpoints[version]]
      this.inputData = [...this.dataneeded.inputdata[version]]
      this.iterMax = [...this.dataneeded.max_pred_coord[version]]
      
      
      this.allData = [...this.dataToPlot,...this.inputData,...this.iterMax]
      if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.fourthInputData=Array.from(new Set(this.getCol(this.allData,this.colFour)))
        this.fourthInputData.sort(function(a,b){return a-b})
        this.fourthInputDataToDisplay=this.fourthInputData[0]

        this.fifthInputData=Array.from(new Set(this.getCol(this.allData,this.colFive)))
        this.fifthInputData.sort(function(a,b){return a-b})
        this.fifthInputDataToDisplay=this.fifthInputData[0]

        this.sixthInputData=Array.from(new Set(this.getCol(this.allData,this.colSix)))
        this.sixthInputData.sort(function(a,b){return a-b})
        this.sixthInputDataToDisplay=this.sixthInputData[0]

        this.seventhInputData=Array.from(new Set(this.getCol(this.allData,this.colSeven)))
        this.seventhInputData.sort(function(a,b){return a-b})
        this.seventhInputDataToDisplay=this.seventhInputData[0]

        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredData3 = [...this.filteredData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredData4 = [...this.filteredData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredInputData3 = [...this.filteredInputData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredInputData4 = [...this.filteredInputData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)


        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredMaxData3 = [...this.filteredMaxData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredMaxData4 = [...this.filteredMaxData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        this.fourthInputData=Array.from(new Set(this.getCol(this.allData,this.colFour)))
        this.fourthInputData.sort(function(a,b){return a-b})
        this.fourthInputDataToDisplay=this.fourthInputData[0]

        this.fifthInputData=Array.from(new Set(this.getCol(this.allData,this.colFive)))
        this.fifthInputData.sort(function(a,b){return a-b})
        this.fifthInputDataToDisplay=this.fifthInputData[0]

        this.sixthInputData=Array.from(new Set(this.getCol(this.allData,this.colSix)))
        this.sixthInputData.sort(function(a,b){return a-b})
        this.sixthInputDataToDisplay=this.sixthInputData[0]

        this.seventhInputData=Array.from(new Set(this.getCol(this.allData,this.colSeven)))
        this.seventhInputData.sort(function(a,b){return a-b})
        this.seventhInputDataToDisplay=this.seventhInputData[0]

        this.eightInputData=Array.from(new Set(this.getCol(this.allData,this.colEight)))
        this.eightInputData.sort(function(a,b){return a-b})
        this.eightInputDataToDisplay=this.eightInputData[0]



        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredData3 = [...this.filteredData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredData4 = [...this.filteredData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)

        this.filteredData5 = [...this.filteredData4].filter(item => item[this.colEight]==this.eightInputDataToDisplay)



        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredInputData3 = [...this.filteredInputData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredInputData4 = [...this.filteredInputData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)

        this.filteredInputData5 = [...this.filteredInputData4].filter(item => item[this.colEight]==this.eightInputDataToDisplay)



        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredMaxData3 = [...this.filteredMaxData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredMaxData4 = [...this.filteredMaxData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)
        
        this.filteredMaxData5 = [...this.filteredMaxData4].filter(item => item[this.colEight]==this.eightInputDataToDisplay)

      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.fourthInputData=Array.from(new Set(this.getCol(this.allData,this.colFour)))
        this.fourthInputData.sort(function(a,b){return a-b})
        this.fourthInputDataToDisplay=this.fourthInputData[0]

        this.fifthInputData=Array.from(new Set(this.getCol(this.allData,this.colFive)))
        this.fifthInputData.sort(function(a,b){return a-b})
        this.fifthInputDataToDisplay=this.fifthInputData[0]

        this.sixthInputData=Array.from(new Set(this.getCol(this.allData,this.colSix)))
        this.sixthInputData.sort(function(a,b){return a-b})
        this.sixthInputDataToDisplay=this.sixthInputData[0]

        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredData3 = [...this.filteredData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        

        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredInputData3 = [...this.filteredInputData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)


        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredMaxData3 = [...this.filteredMaxData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.fourthInputData=Array.from(new Set(this.getCol(this.allData,this.colFour)))
        this.fourthInputData.sort(function(a,b){return a-b})
        this.fourthInputDataToDisplay=this.fourthInputData[0]

        this.fifthInputData=Array.from(new Set(this.getCol(this.allData,this.colFive)))
        this.fifthInputData.sort(function(a,b){return a-b})
        this.fifthInputDataToDisplay=this.fifthInputData[0]

        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        
        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==4){
        this.fourthInputData=Array.from(new Set(this.getCol(this.allData,this.colFour)))
        this.fourthInputData.sort(function(a,b){return a-b})
        this.fourthInputDataToDisplay=this.fourthInputData[0]

        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        

        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        }
      this.plotSuggestedPoint()
    },
    plotSuggestedPoint(){
      if(parseInt(this.dataneeded.number_of_inputs)==7){
        var slidersSteps = []
        var slidersSteps1 = []
        var slidersSteps2 = []
        var slidersSteps3 = []
        for(let i = 0; i< this.fifthInputData.length;i++){
          slidersSteps1.push({
            label:this.fifthInputData[i],
            method:"skip",
            execute:false,
            value:this.fifthInputData[i]
          })
        }
        for(let i = 0; i< this.fourthInputData.length;i++){
          slidersSteps.push({
            label:this.fourthInputData[i],
            method:"skip",
            execute:false,
            value:this.fourthInputData[i]
          })
        }
        for(let i = 0; i< this.sixthInputData.length;i++){
          slidersSteps2.push({
            label:this.sixthInputData[i],
            method:"skip",
            execute:false,
            value:this.sixthInputData[i]
          })
        }
        for(let i = 0; i< this.seventhInputData.length;i++){
          slidersSteps3.push({
            label:this.seventhInputData[i],
            method:"skip",
            execute:false,
            value:this.seventhInputData[i]
          })
        }
        let hovertextsuggestedpoints = []
        let hoverelementsuggestedpoints = ""
        let hoverelementinputsdata=""
        let hovertextinputsdata = []
        let hovertextmaxima=[]
        let hoverelementmaxima = ""
        for (let i = 0;i<this.filteredData4.length;i++){
          hoverelementsuggestedpoints = `variable 4 : ${this.filteredData4[i][3]} \n variable 5 : ${this.filteredData4[i][4]} \n variable 6 : ${this.filteredData4[i][5]} \n variable 7 : ${this.filteredData4[i][6]}`
          hovertextsuggestedpoints.push(hoverelementsuggestedpoints)
        }
        for (let i = 0;i<this.filteredInputData4.length;i++){
          hoverelementinputsdata = `variable 4 : ${this.filteredInputData4[i][3]} \n variable 5 : ${this.filteredInputData4[i][4]} \n variable 6 : ${this.filteredInputData4[i][5]} \n variable 7 : ${this.filteredInputData4[i][6]}`
          hovertextinputsdata.push(hoverelementinputsdata)
        }
        this.layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(...this.getCol(this.allData, this.colOne)),Math.max(...this.getCol(this.allData, this.colOne))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(...this.getCol(this.allData, this.colTwo)),Math.max(...this.getCol(this.allData, this.colTwo))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(...this.getCol(this.allData, this.colThree)),Math.max(...this.getCol(this.allData, this.colThree))]}
                          },
                          height:750,
                          showlegend: true,
                          legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1
                          },
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps1,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          }]
                                          
                      
                    
                    };
        var trace = {
          x:this.getCol(this.filteredData4,this.colOne),
          y:this.getCol(this.filteredData4,this.colTwo),
          z:this.getCol(this.filteredData4,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,

          },
          name:"Suggested Next Coordinates",
          type: 'scatter3d',
          text:hovertextsuggestedpoints
        }
        var traceInputData = {
          x:this.getCol(this.filteredInputData4,this.colOne),
          y:this.getCol(this.filteredInputData4,this.colTwo),
          z:this.getCol(this.filteredInputData4,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,

          },
          name:"Input Data",
          type:'scatter3d',
          text:hovertextinputsdata
        }
        var traceMaxData = {
          x:this.getCol(this.filteredMaxData4,this.colOne),
          y:this.getCol(this.filteredMaxData4,this.colTwo),
          z:this.getCol(this.filteredMaxData4,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,

          },
          name:`Iteration ${this.iter_}`,
          type:'scatter3d'
        }

        Plotly.react("suggestedPointsGraph",[trace, traceInputData, traceMaxData], this.layout)
        if(this.filteredData4.length == 0 && this.filteredInputData4.length==0 && this.filteredMaxData4.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        this.updateSuggestedPointsGraph7Inputs()
      
      
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        var slidersSteps = []
        var slidersSteps1 = []
        var slidersSteps2 = []
        var slidersSteps3 = []
        var slidersSteps4 = []
        for(let i = 0; i< this.fifthInputData.length;i++){
          slidersSteps1.push({
            label:this.fifthInputData[i],
            method:"skip",
            execute:false,
            value:this.fifthInputData[i]
          })
        }
        for(let i = 0; i< this.fourthInputData.length;i++){
          slidersSteps.push({
            label:this.fourthInputData[i],
            method:"skip",
            execute:false,
            value:this.fourthInputData[i]
          })
        }
        for(let i = 0; i< this.sixthInputData.length;i++){
          slidersSteps2.push({
            label:this.sixthInputData[i],
            method:"skip",
            execute:false,
            value:this.sixthInputData[i]
          })
        }
        for(let i = 0; i< this.seventhInputData.length;i++){
          slidersSteps3.push({
            label:this.seventhInputData[i],
            method:"skip",
            execute:false,
            value:this.seventhInputData[i]
          })
        }
        for(let i = 0; i< this.eightInputData.length;i++){
          slidersSteps4.push({
            label:this.eightInputData[i],
            method:"skip",
            execute:false,
            value:this.eightInputData[i]
          })
        }
        let hovertextsuggestedpoints = []
        let hoverelementsuggestedpoints = ""
        let hoverelementinputsdata=""
        let hovertextinputsdata = []
        let hovertextmaxima=[]
        let hoverelementmaxima = ""
        
        this.layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(...this.getCol(this.allData, this.colOne)),Math.max(...this.getCol(this.allData, this.colOne))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(...this.getCol(this.allData, this.colTwo)),Math.max(...this.getCol(this.allData, this.colTwo))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(...this.getCol(this.allData, this.colThree)),Math.max(...this.getCol(this.allData, this.colThree))]}
                          },
                          height:850,
                          showlegend: true,
                          legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1
                          },
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps1,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:280
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colEight] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          }]
                                          
                      
                    
                    };
        var trace = {
          x:this.getCol(this.filteredData5,this.colOne),
          y:this.getCol(this.filteredData5,this.colTwo),
          z:this.getCol(this.filteredData5,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,

          },
          name:"Suggested Next Coordinates",
          type: 'scatter3d',
          text:hovertextsuggestedpoints
        }
        var traceInputData = {
          x:this.getCol(this.filteredInputData5,this.colOne),
          y:this.getCol(this.filteredInputData5,this.colTwo),
          z:this.getCol(this.filteredInputData5,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,

          },
          name:"Input Data",
          type:'scatter3d',
          text:hovertextinputsdata
        }
        var traceMaxData = {
          x:this.getCol(this.filteredMaxData5,this.colOne),
          y:this.getCol(this.filteredMaxData5,this.colTwo),
          z:this.getCol(this.filteredMaxData5,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,

          },
          name:`Iteration ${this.iter_}`,
          type:'scatter3d'
        }

        Plotly.react("suggestedPointsGraph",[trace, traceInputData, traceMaxData], this.layout)
        if(this.filteredData5.length == 0 && this.filteredInputData5.length==0 && this.filteredMaxData5.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        this.updateSuggestedPointsGraph8Inputs()
      
      
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        var slidersSteps = []
        var slidersSteps1 = []
        var slidersSteps2 = []
        for(let i = 0; i< this.fifthInputData.length;i++){
          slidersSteps1.push({
            label:this.fifthInputData[i],
            method:"skip",
            execute:false,
            value:this.fifthInputData[i]
          })
        }
        for(let i = 0; i< this.fourthInputData.length;i++){
          slidersSteps.push({
            label:this.fourthInputData[i],
            method:"skip",
            execute:false,
            value:this.fourthInputData[i]
          })
        }
        for(let i = 0; i< this.sixthInputData.length;i++){
          slidersSteps2.push({
            label:this.sixthInputData[i],
            method:"skip",
            execute:false,
            value:this.sixthInputData[i]
          })
        }
        this.layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(...this.getCol(this.allData, this.colOne)),Math.max(...this.getCol(this.allData, this.colOne))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(...this.getCol(this.allData, this.colTwo)),Math.max(...this.getCol(this.allData, this.colTwo))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(...this.getCol(this.allData, this.colThree)),Math.max(...this.getCol(this.allData, this.colThree))]}
                          },
                          showlegend: true,
                          legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1
                          },
                      
                          
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps1,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          }]
                                          
                      
                    
                    };
        let hovertextsuggestedpoints = []
        let hoverelementsuggestedpoints = ""
        let hoverelementinputsdata=""
        let hovertextinputsdata = []
        let hovertextmaxima=[]
        let hoverelementmaxima = ""
        for (let i = 0;i<this.filteredData3.length;i++){
          hoverelementsuggestedpoints = `variable 4 : ${this.filteredData3[i][3]} \n variable 5 : ${this.filteredData3[i][4]} \n variable 6 : ${this.filteredData3[i][5]}`
          hovertextsuggestedpoints.push(hoverelementsuggestedpoints)
        }
        for (let i = 0;i<this.filteredInputData3.length;i++){
          hoverelementinputsdata = `variable 4 : ${this.filteredInputData3[i][3]} \n variable 5 : ${this.filteredInputData3[i][4]} \n variable 6 : ${this.filteredInputData3[i][5]}`
          hovertextinputsdata.push(hoverelementinputsdata)
        }
        let traceSuggestedPoints = {
          x:this.getCol(this.filteredData3,this.colOne),
          y:this.getCol(this.filteredData3,this.colTwo),
          z:this.getCol(this.filteredData3,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
          },
          type: 'scatter3d',
          name:"Suggested Next Coordinates",
          
          
          

        }
        let traceInputData = {
          x:this.getCol(this.filteredInputData3,this.colOne),
          y:this.getCol(this.filteredInputData3,this.colTwo),
          z:this.getCol(this.filteredInputData3,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,

          },
          name:"Input Data",
          type:'scatter3d',
          
        }
        var traceMaxData = {
          x:this.getCol(this.filteredMaxData3,this.colOne),
          y:this.getCol(this.filteredMaxData3,this.colTwo),
          z:this.getCol(this.filteredMaxData3,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,

          },
          name:`Iteration ${this.iter_}`,
          type:'scatter3d'
        }

        Plotly.react("suggestedPointsGraph",[traceSuggestedPoints,traceInputData,traceMaxData], this.layout)
        if(this.filteredData3.length == 0 && this.filteredInputData3.length==0 && this.filteredMaxData3.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        this.updateSuggestedPointsGraph6Inputs()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        var slidersSteps = []
        var slidersSteps1 = []
        for(let i = 0; i< this.fifthInputData.length;i++){
          slidersSteps1.push({
            label:this.fifthInputData[i],
            method:"skip",
            execute:false,
            value:this.fifthInputData[i]
          })
        }
        for(let i = 0; i< this.fourthInputData.length;i++){
          slidersSteps.push({
            label:this.fourthInputData[i],
            method:"skip",
            execute:false,
            value:this.fourthInputData[i]
          })
        }
        let hovertextsuggestedpoints = []
        let hoverelementsuggestedpoints = ""
        let hoverelementinputsdata=""
        let hovertextinputsdata = []
        let hovertextmaxima=[]
        let hoverelementmaxima = ""
        for (let i = 0;i<this.filteredData2.length;i++){
          hoverelementsuggestedpoints = `variable 4 : ${this.filteredData2[i][3]} \n variable 5 : ${this.filteredData2[i][4]}`
          hovertextsuggestedpoints.push(hoverelementsuggestedpoints)
        }
        for (let i = 0;i<this.filteredInputData2.length;i++){
          hoverelementinputsdata = `variable 4 : ${this.filteredInputData2[i][3]} \n variable 5 : ${this.filteredInputData2[i][4]}`
          hovertextinputsdata.push(hoverelementinputsdata)
        }
        this.layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(...this.getCol(this.allData, this.colOne)),Math.max(...this.getCol(this.allData, this.colOne))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(...this.getCol(this.allData, this.colTwo)),Math.max(...this.getCol(this.allData, this.colTwo))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(...this.getCol(this.allData, this.colThree)),Math.max(...this.getCol(this.allData, this.colThree))]}
                          },
                          height:650,
                          showlegend: true,
                          legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1
                          },
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps1,
                            
                          }]
                                          
                      
                    
                    };
        var trace = {
          x:this.getCol(this.filteredData2,this.colOne),
          y:this.getCol(this.filteredData2,this.colTwo),
          z:this.getCol(this.filteredData2,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
          },
          type: 'scatter3d',
          name:"Suggested Next Coordinates",
          text:hovertextsuggestedpoints
        }
        var traceInputData = {
          x:this.getCol(this.filteredInputData2,this.colOne),
          y:this.getCol(this.filteredInputData2,this.colTwo),
          z:this.getCol(this.filteredInputData2,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,

          },
            name:"Input Data",
          type:'scatter3d',
          text:hovertextinputsdata
        }
        var traceMaxData = {
          x:this.getCol(this.filteredMaxData2,this.colOne),
          y:this.getCol(this.filteredMaxData2,this.colTwo),
          z:this.getCol(this.filteredMaxData2,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,

          },
          name:`Iteration ${this.iter_}`,
          type:'scatter3d'
          
        }

        Plotly.react("suggestedPointsGraph",[trace, traceInputData,traceMaxData], this.layout)
        if(this.filteredData2.length == 0 && this.filteredInputData2.length==0 && this.filteredMaxData2.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        this.updateSuggestedPointsGraph5Inputs()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==4){
        slidersSteps=[]
        for(let i = 0; i< this.fourthInputData.length;i++){
          slidersSteps.push({
            label:this.fourthInputData[i],
            method:"skip",
            execute:false,
            value:this.fourthInputData[i]
          })
        }
        let hovertextsuggestedpoints = []
        let hoverelementsuggestedpoints = ""
        let hoverelementinputsdata=""
        let hovertextinputsdata = []
        let hovertextmaxima=[]
        let hoverelementmaxima = ""
        for (let i = 0;i<this.filteredData.length;i++){
          hoverelementsuggestedpoints = `variable 4 : ${this.filteredData[i][3]}`
          hovertextsuggestedpoints.push(hoverelementsuggestedpoints)
        }
        for (let i = 0;i<this.filteredInputData.length;i++){
          hoverelementinputsdata = `variable 4 : ${this.filteredInputData[i][3]}`
          hovertextinputsdata.push(hoverelementinputsdata)
        }
        this.layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(...this.getCol(this.allData, this.colOne)),Math.max(...this.getCol(this.allData, this.colOne))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(...this.getCol(this.allData, this.colTwo)),Math.max(...this.getCol(this.allData, this.colTwo))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(...this.getCol(this.allData, this.colThree)),Math.max(...this.getCol(this.allData, this.colThree))]}
                          },
                          showlegend: true,
                          legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          }]
                                          
                      
                    
                    };
        var trace = {
          x:this.getCol(this.filteredData,this.colOne),
          y:this.getCol(this.filteredData,this.colTwo),
          z:this.getCol(this.filteredData,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
          },
            name:"Suggested Next Coordinates",
          type: 'scatter3d',
          text:hovertextsuggestedpoints
        }
        var traceInputData = {
          x:this.getCol(this.filteredInputData,this.colOne),
          y:this.getCol(this.filteredInputData,this.colTwo),
          z:this.getCol(this.filteredInputData,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,

          },
            name:"Input Data",
          type:'scatter3d',
          text:hovertextinputsdata
        }
        var traceMaxData = {
          x:this.getCol(this.filteredMaxData,this.colOne),
          y:this.getCol(this.filteredMaxData,this.colTwo),
          z:this.getCol(this.filteredMaxData,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,

          },
          name:`Iteration ${this.iter_}`,
          type:'scatter3d'
          
        }

        Plotly.react("suggestedPointsGraph",[trace, traceInputData,traceMaxData], this.layout)
        if(this.filteredData.length == 0 && this.filteredInputData.length==0 && this.filteredMaxData.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        this.updateSuggestedPointsGraph4Inputs()
      }
    },
    updateSuggestedPointsGraph8Inputs(){
      var trainingDataGraph = document.getElementById("suggestedPointsGraph")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 0){
          this.fourthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==1){
          this.fifthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==2){
          this.sixthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index==3){
          this.seventhInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index==4){
          this.eightInputDataToDisplay = this.vall
        }
        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredData3 = [...this.filteredData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredData4 = [...this.filteredData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)
        this.filteredData5 = [...this.filteredData4].filter(item => item[this.colEight]==this.eightInputDataToDisplay)


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)

        this.filteredInputData3 = [...this.filteredInputData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)

        this.filteredInputData4 = [...this.filteredInputData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)
        this.filteredInputData5 = [...this.filteredInputData4].filter(item => item[this.colEight]==this.eightInputDataToDisplay)


        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredMaxData3 = [...this.filteredMaxData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredMaxData4 = [...this.filteredMaxData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)
        this.filteredMaxData5 = [...this.filteredMaxData4].filter(item => item[this.colEight]==this.eightInputDataToDisplay)

        var frame = {
          data:[{
          x:this.getCol(this.filteredData5,this.colOne),
          y:this.getCol(this.filteredData5,this.colTwo),
          z:this.getCol(this.filteredData5,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
            name:"Suggested points"

          }
        },
        {
          x:this.getCol(this.filteredInputData5,this.colOne),
          y:this.getCol(this.filteredInputData5,this.colTwo),
          z:this.getCol(this.filteredInputData5,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,
            name:"Input Data"

          }
        },
        {
          x:this.getCol(this.filteredMaxData5,this.colOne),
          y:this.getCol(this.filteredMaxData5,this.colTwo),
          z:this.getCol(this.filteredMaxData5,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,
            name:"Iteration Maxima"

          }
        }
          ]

        }
        Plotly.animate("suggestedPointsGraph", frame)
        if(this.filteredData5.length == 0 && this.filteredInputData5.length==0 && this.filteredMaxData5.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
     
    },
    updateSuggestedPointsGraph7Inputs(){
      var trainingDataGraph = document.getElementById("suggestedPointsGraph")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 0){
          this.fourthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==1){
          this.fifthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==2){
          this.sixthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index==3){
          this.seventhInputDataToDisplay = this.vall
        }
        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredData3 = [...this.filteredData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredData4 = [...this.filteredData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)

        this.filteredInputData3 = [...this.filteredInputData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)

        this.filteredInputData4 = [...this.filteredInputData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)


        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredMaxData3 = [...this.filteredMaxData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        this.filteredMaxData4 = [...this.filteredMaxData3].filter(item => item[this.colSeven]==this.seventhInputDataToDisplay)

        var frame = {
          data:[{
          x:this.getCol(this.filteredData4,this.colOne),
          y:this.getCol(this.filteredData4,this.colTwo),
          z:this.getCol(this.filteredData4,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
            name:"Suggested points"

          }
        },
        {
          x:this.getCol(this.filteredInputData4,this.colOne),
          y:this.getCol(this.filteredInputData4,this.colTwo),
          z:this.getCol(this.filteredInputData4,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,
            name:"Input Data"

          }
        },
        {
          x:this.getCol(this.filteredMaxData4,this.colOne),
          y:this.getCol(this.filteredMaxData4,this.colTwo),
          z:this.getCol(this.filteredMaxData4,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,
            name:"Iteration Maxima"

          }
        }
          ]

        }
        Plotly.animate("suggestedPointsGraph", frame)
        if(this.filteredData4.length == 0 && this.filteredInputData4.length==0 && this.filteredMaxData4.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
     
    },
    
    updateSuggestedPointsGraph6Inputs(){
      var trainingDataGraph = document.getElementById("suggestedPointsGraph")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 0){
          this.fourthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==1){
          this.fifthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==2){
          this.sixthInputDataToDisplay = this.vall
        }
        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredData3 = [...this.filteredData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)

        this.filteredInputData3 = [...this.filteredInputData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)



        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        this.filteredMaxData3 = [...this.filteredMaxData2].filter(item => item[this.colSix]==this.sixthInputDataToDisplay)
        
        var frame = {
          data:[{
            'x':this.getCol(this.filteredData3,this.colOne),
          'y':this.getCol(this.filteredData3,this.colTwo),
          'z':this.getCol(this.filteredData3,this.colThree),
        
        }]}
        var frame2={
          data:[{
          x:this.getCol(this.filteredData3,this.colOne),
          y:this.getCol(this.filteredData3,this.colTwo),
          z:this.getCol(this.filteredData3,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
            name:"Suggested points"

          }
        },
        {
          x:this.getCol(this.filteredInputData3,this.colOne),
          y:this.getCol(this.filteredInputData3,this.colTwo),
          z:this.getCol(this.filteredInputData3,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,
            name:"Input Data"

          }
        },
        {
          x:this.getCol(this.filteredMaxData3,this.colOne),
          y:this.getCol(this.filteredMaxData3,this.colTwo),
          z:this.getCol(this.filteredMaxData3,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,
            name:"Iteration Maxima"

          }
        }
          ],

        }
          frames = [frame,frame2]
        Plotly.animate("suggestedPointsGraph", frame2)
        if(this.filteredData3.length == 0 && this.filteredInputData3.length==0 && this.filteredMaxData3.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
     
    },
    updateSuggestedPointsGraph5Inputs(){
      var trainingDataGraph = document.getElementById("suggestedPointsGraph")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 0){
          this.fourthInputDataToDisplay = this.vall
        }
        else if(datasent.slider._index ==1){
          this.fifthInputDataToDisplay = this.vall
        }
        
        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredData2 = [...this.filteredData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        


        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        this.filteredInputData2 = [...this.filteredInputData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)

        


        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredMaxData2 = [...this.filteredMaxData].filter(item => item[this.colFive]==this.fifthInputDataToDisplay)
        
        
        var frame = {
          data:[{
          x:this.getCol(this.filteredData2,this.colOne),
          y:this.getCol(this.filteredData2,this.colTwo),
          z:this.getCol(this.filteredData2,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
            name:"Suggested Next Coordinates"

          }
        },
        {
          x:this.getCol(this.filteredInputData2,this.colOne),
          y:this.getCol(this.filteredInputData2,this.colTwo),
          z:this.getCol(this.filteredInputData2,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,
            name:"Input Data"

          }
        },
        {
          x:this.getCol(this.filteredMaxData2,this.colOne),
          y:this.getCol(this.filteredMaxData2,this.colTwo),
          z:this.getCol(this.filteredMaxData2,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,
            name:"Iteration Maxima"

          }
        }
          ],

        }
        Plotly.animate("suggestedPointsGraph", frame)
        if(this.filteredData2.length == 0 && this.filteredInputData2.length==0 && this.filteredMaxData2.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        
        

})
      
     
    },
    updateSuggestedPointsGraph4Inputs(){
      var trainingDataGraph = document.getElementById("suggestedPointsGraph")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 0){
          this.fourthInputDataToDisplay = this.vall
        }
        
        
        this.filteredData = [...this.dataToPlot].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        this.filteredInputData = [...this.inputData].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)

        this.filteredMaxData = [...this.iterMax].filter(item => item[this.colFour]==this.fourthInputDataToDisplay)
        
        var frame = {
          data:[{
          x:this.getCol(this.filteredData,this.colOne),
          y:this.getCol(this.filteredData,this.colTwo),
          z:this.getCol(this.filteredData,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#000000',
            opacity:0.8,
            name:"Suggested Next Coordinates"

          }
        },
        {
          x:this.getCol(this.filteredInputData,this.colOne),
          y:this.getCol(this.filteredInputData,this.colTwo),
          z:this.getCol(this.filteredInputData,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'#0000ff',
            opacity:0.8,
            name:"Input Data"

          }
        },
        {
          x:this.getCol(this.filteredMaxData,this.colOne),
          y:this.getCol(this.filteredMaxData,this.colTwo),
          z:this.getCol(this.filteredMaxData,this.colThree),
          mode:"markers",
          marker:{
            size:10,
            color:'yellow',
            opacity:0.8,
            name:"Iteration Maxima"

          }
        }
          ],

        }
        Plotly.animate("suggestedPointsGraph", frame)
        if(this.filteredData.length == 0 && this.filteredInputData.length==0 && this.filteredMaxData.length==0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
     
    },
    checkCheckboxes(){
      let selectedVariables = this.variablesToPlot.filter(item => item===true)
      if(selectedVariables.length==3){
        this.canPlot=true
      }
      else{
        this.canPlot=false
      }
    },
    plotNewVariables(){
      const indexesOfVariables = this.variablesToPlot.flatMap((bool,index) => bool ? index : [])
      this.colOne=indexesOfVariables[0]
      this.colTwo=indexesOfVariables[1]
      this.colThree=indexesOfVariables[2]
      
      const slideIndex = this.variablesToPlot.flatMap((bool,index) => !bool ? index : [])
      this.colFour = slideIndex[0]
      if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.colFive = slideIndex[1]
        this.manageSuggestedNextPoint(this.suggestedPointsGraphVersion)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.colFive = slideIndex[1]
        this.colSix = slideIndex[2]
        this.manageSuggestedNextPoint(this.suggestedPointsGraphVersion)
        
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.colFive = slideIndex[1]
        this.colSix = slideIndex[2]
        this.colSeven = slideIndex[3]
        
        this.manageSuggestedNextPoint(this.suggestedPointsGraphVersion)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        this.colFive = slideIndex[1]
        this.colSix = slideIndex[2]
        this.colSeven = slideIndex[3]
        this.colEight = slideIndex[4]
        this.manageSuggestedNextPoint(this.suggestedPointsGraphVersion)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==4){
        this.manageSuggestedNextPoint(this.suggestedPointsGraphVersion)
      }
    },
    
    
    
  },
  mounted() {
    if(parseInt(this.dataneeded.number_of_inputs)<=3){
      this.getgraph()
      
    }
    if(parseInt(this.dataneeded.number_of_inputs)>3){
      this.graph_exists = false
      this.manageSuggestedNextPoint(this.dataneeded.versionToShow)
    }
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.inter{
  width: 100%;
}

#suggestedpoints{
  width: fit-content;
  max-width: 80%;
  padding: 0 6vw;
  margin: auto;
  border-radius: 20px;
}

#suggestedPointsGraph{
  width: 80%;
  margin: auto;
}





.content{
  
  height: fit-content;
  margin: 2rem auto 1rem auto;
  padding: 0.5vh 1% 2vh 1vw;
  background-color: white;
  
}

.sugg-title{
  width: 70%;
  height: 10%;
  margin: 0 auto 4% auto;
  background-color: transparent;
  border-radius: 20px;
  
  
}
.sugg-title h4{
  font-size: 1rem;
  
  background: #1515b8;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #1515bd);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1054bb, #1010c4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.7vw;
  padding-bottom: 0.6vw;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px ;
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto -1vw;
  border-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
}
.sugg-title button{
  font-size: 1rem;
  margin: auto -1vw auto 0;
  padding: 0.5vw 1vw;
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
  border-radius: 10px;
}
.blue-color{
      background-color: #012b7e;

  

}
.grey-color{
    background-color: #7f7f7f ;

}
.graph-title{
  width: 82%;
  margin: 0 auto;
  padding: 0.1rem 1vw;
  background-color: white;
  border-radius: 5px;
  
}
.graph-title h4{
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin: auto 0;
  padding: 1rem;
  border-radius: 10px;
  border-bottom: black solid 1px;
}
.graph-title button{
  border: white 1px solid;
  background-color: #1054bb;
  font-size: 1.2rem;
  letter-spacing: 2px;
}
button{
  font-size: 1rem;
}

#suggestedpoints{
  width: fit-content;
  max-width: 80%;
  margin: auto;
  border-radius: 20px;
  overflow-x: auto ;
}
.tables{
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}
.tablesCol{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width:1350px) {
  .tables{
    flex-direction: column;
    align-items: center;
  }
}
</style>
