<template>
  <div class="navbar">

    <NavBar/>
  </div>
  <div class="topbar d-flex flex-row justify-content-between align-items-center mb-5 p-2">

    <h1>Create New Project</h1>
    <button class="btn text-light" style="background:#002060" @click="showDash"><i class="bi bi-arrow-left me-3"></i>Back To Dashboard</button>

  </div>
  <div :class="formValidationClass" class="formValidation" v-if="!formValidated && !isDataLegit">
    {{formValidationErrorMessage}}
  </div>
  <div class=" form">
    <form>
      
      <div class="mb-3 form-floating">
        <input type="text" class="form-control projectNameinput" placeholder="Project Name" id="projectName"
               v-model="projectName">


        <label for="projectName" class="form-label">Project Name</label>
        <div v-if="v$.projectName.$error">
          <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">
            Field is required
          </p>
        </div>

      </div>
      <div class="mb-3 form-floating">
        <input type="text" class="form-control descinput" id="description" v-model="projectDescription"
               placeholder="Description">
        <label for="description" class="form-label">Description</label>
        <div v-if="v$.projectDescription.$error">
          <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Description too long</p>
        </div>

      </div>
      <div class="row">

        <div v-if="v$.numberOfInputs.$error">
          <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">CSV File Data must only contain 2,3,4 or 5 features/inputs</p>
        </div>
        <div v-if="v$.numberOfRowsInDataset.$error">
          <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Dataset too big (must be less than 200 data points</p>
        </div>
        <label for="excelfile" class="form-label ms-2" style="color:black;">Upload Dataset (CSV or EXCEL format)</label>
        <div class="col-11">
          <input class="form-control form-control-lg custom-file-upload" type="file" id="excelfile" @change="loadData"
                 pattern="^.+\.(xlsx|xls|csv)$"
                 accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
        </div>
        <button type="button" class="btn btn-light  col-1" data-bs-toggle="tooltip" data-bs-placement="top" title="File should contain the available data.
Each column contains a different process variable or parameter, and the last column corresponds to the target variable the user wishes to predict. Every row corresponds to a different experiment or data point.
The first row may include the name of each variable. If no name is included, they will be automatically named Variable 1, Variable 2…
">
          <i class="bi bi-info-circle"></i>
        </button>
      </div>
      <div class="limits" v-if="loadLimits">

        <div>
          <br>
          <p v-if="v$.inputMinLimList.$error || v$.inputMaxLimList.$error || v$.limPointCloseness.$error"
             :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">

            Please fill in correctly all the fields
            <br>
            Values must be numbers
          </p>
          <div v-for="num in parseInt(numberOfInputs)" :key="num">
            <div class="input">

              <p class="mt-3 ms-2" v-if="hasHeader === 'no'" style="color:white;">Input {{ num }}</p>
              <p v-if="hasHeader === 'yes'" class="text-light">Variable {{ header[num - 1] }}</p>
              <div class="minmax">
                <div class="">
                  <label class="text-light ms-1 me-3">Type of variable :  </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="" id="" value="1" v-model.number="variablesType[num - 1]" >
                    <label class="form-check-label" for="">Discrete</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="" id="" value="0" v-model.number="variablesType[num - 1]" >
                    <label class="form-check-label" for="">Continuous</label>
                  </div>
                </div>
                <div class="row">
                  <div class="min form-floating mb-3 col-11">
                    <input type="number" class="form-control" v-model.number="inputMinLimList[num - 1]" id="min"
                           placeholder="Min"/>
                    <label class="ms-2" for="min">Min</label>
                  </div>
                  <button type="button" class="btn btn-light mb-3 col-1" data-bs-toggle="tooltip" data-bs-placement="top"
                          title=
                              "Specifies the lower limit on the range of values that will be considered for this variable in the prediction of the user-defined reaction space. ">
                    <i class="bi bi-info-circle"></i>
                  </button>
                </div>
                <div class="row">
                  <div class="max form-floating mb-3 col-11">
                    <input type="number" class="form-control" v-model.number="inputMaxLimList[num - 1]" id="max"
                           placeholder="Max"/>
                    <label class="ms-2" for="max">Max</label>
                  </div>
                  <button type="button" class="btn btn-light mb-3 col-1" data-bs-toggle="tooltip" data-bs-placement="top"
                          title=
                              " Specifies the upper limit on the range of values that will be considered for this variable in the prediction of the user-defined reaction space. ">
                    <i class="bi bi-info-circle"></i>
                  </button>
                </div>
                <div class="row">
                  <div class="mindistance form-floating col-11">
                    <input type="text" class="form-control" v-model.number="limPointCloseness[num-1]" id="min_distance"
                           placeholder="Min Distance (Recom 0.15)"/>
                    <label class="ms-2" for="min_distance">Exploitation limit</label>

                  </div>
                  <button type="button" class="btn btn-light col-1" data-bs-toggle="tooltip" data-bs-placement="top"
                          title=
                              "The suggestion of experimental points is based on an exploration vs. exploitation approach to ensure that no global optimum is missed in the system. The exploitation limit will define the limit of exploitation of areas close to optimum points.
Warning: Values below 0.15 could lead to longer experimental campaigns as the exploitation will dominate the approach significantly.  ">
                    <i class="bi bi-info-circle"></i>
                  </button>
                </div>
              </div>
              <br/>
              
              

            </div>
          </div>
          <div class="">
                  <label class="text-light ms-1 me-3">Optimization Type :  </label> <br>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="" id="" value="max" v-model="optimisationTarget">
                    <label class="form-check-label" for="">Maximize</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="" id="" value="min"  v-model="optimisationTarget">
                    <label class="form-check-label" for="">Minimize</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="" id="" value="num"  v-model="optimisationTarget">
                    <label class="form-check-label me-1" for="">Value</label>
                    <input type="number" style="width:30%" v-model="optValue" :disabled="optimisationTarget !='num'">
                    
                  </div>
                  <div v-if="v$.optValue.$error">
                      <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Field is required and must be an number</p>
                    </div>
                  
                </div>
        </div>
      </div>

      <div class="row">
        <div class="otherinputs form-floating mt-4 mb-3 col-11">
          <input type="number" class="form-control" v-model.number="visualizationsLayers"
                 placeholder="Visualization Layers (Recommended 50 for 2D, 10 for 3D, 7 for more)" id="vis_layer"/>
          <label class="ms-2" for="vis_layer">Visualization Layers (Recommended 50 for 2D, 10 for 3D, 7 for more)</label>
          <div v-if="v$.visualizationsLayers.$error">
            <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Field is required and must be a positive integer from 3 to {{maxLayers}}</p>
          </div>
        </div>
        <button type="button" class="btn btn-light mt-4 mb-3 col-1" data-bs-toggle="tooltip" data-bs-placement="top"
                title=
                    "Defines the grid that will be created to visualize predictions in your reaction space. Example: 10 layers will
            create a grid of 10 different values for each variable. ">
          <i class="bi bi-info-circle"></i>
        </button>
      </div>
      <div class="row">
        <div class="form-floating mb-3 col-11">
          <input type="number" class="form-control" v-model.number="suggestedPoints"
                 placeholder="Number Of Suggested Points (Max recommended is 5)" id="number_of_suggestion_points"/>
          <label class="ms-2" for="number_of_suggestion_points">Number Of Suggested Points (Max recommended is
            5)</label>

          <div v-if="v$.suggestedPoints.$error">
            <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Field is required and must be a positive integer from 1 to 15</p>
          </div>
        </div>
        <button type="button" class="btn btn-light mb-3 col-1" data-bs-toggle="tooltip" data-bs-placement="top" title=
            "Number of experiments (i.e., data points) that the tool will suggest on each iteration. The user has the
            freedom to choose which of those experiments are run and added to the training set in the next iteration.">
          <i class="bi bi-info-circle"></i>
        </button>
      </div>
      <div class="advancedinputs" v-if="advancedInputs===true">
        <div class="row">
          <div class="form-floating mb-3 col-11">
            <input type="number" class="form-control" v-model.number="maxNeurons" id="max_nodes"
                   placeholder="Maximum number of prediction nodes"/>
            <label class="ms-2" for="max_nodes">Maximum number of prediction nodes</label>
            <div v-if="v$.maxNeurons.$error">
              <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Field is required and must be a positive integer from 10 to 30</p>
            </div>
          </div>
          <button type="button" class="btn btn-light  mb-3 col-1" data-bs-toggle="tooltip" data-bs-placement="top"
                  title=
                      "Our algorithms will often use combinations of neural networks to predict reaction behavior.
                      This value fixed the maximum number of neurons or nodes to be used on each layer. When this number
                      is too high there is a high risk for data overfitting! We recommend increasing this only if the predictions
                      don’t capture the complexity of your system. ">
            <i class="bi bi-info-circle"></i>
          </button>
        </div>
        <div class="row">
          <div class="form-floating mb-3 col-11">
            <input type="number" class="form-control" v-model.number="alphaValue" id="alpha_value" placeholder="Alpha value"/>
            <label class="ms-2" for="alpha_value">Regularization value</label>
            <div v-if="v$.alphaValue.$error">
              <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Field is required and must be a number from 0.1 to 10</p>
            </div>
          </div>
          <button type="button" class="btn btn-light  mb-3 col-1" data-bs-toggle="tooltip" data-bs-placement="top"
                  title=
                      "Place Holder">
            <i class="bi bi-info-circle"></i>
          </button>
        </div>
        <div class="row">
          <div class="form-floating col-11">
            <input type="number" class="form-control" v-model.number="maxIter" id="max_iter" placeholder="2000"/>
            <label class="ms-2" for="max_iter">Maximum number of iterations</label>
            <div v-if="v$.maxIter.$error">
              <p :class="[showErrorMessages ? 'errormessage' : 'hideerrormessage']">Field is required and must be a positive integer from 100 to 5000</p>
            </div>

          </div>
          <button type="button" class="btn btn-light col-1" data-bs-toggle="tooltip" data-bs-placement="top" title=
              "Predictive algorithms will rely on solvers that iterate until convergence or until this number of iterations.">
            <i class="bi bi-info-circle"></i>
          </button>
        </div>
      </div>
      <div v-if="advancedInputs===false" id="advanced">
        <a href="#advanced" @click="showAdvancedInputs()" class="text-dark">Show Advanced Parameters</a>
      </div>
      <div class=" d-flex flex-row justify-content-center mt-5">
        <div v-if="dataNotSent" @click="showFormValidationAlert">

          <button class="btn text-light" style="background:#002060" @click="submit" v-if="formValidated">Create Project</button>
        </div>
        
      </div>
      <div v-if="!dataNotSent">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

    </form>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {integer, maxLength, maxValue, minLength, minValue, numeric, required, requiredIf} from '@vuelidate/validators'
import Mybutton from "./Mybutton.vue"
import NavBar from "./Navbar.vue"
import {Auth} from "aws-amplify"
import XLSX from 'xlsx'



const isAllNumeric = function (value) {
  const arr = []
  for (let i = 0; i < value.length; i++) {
    if (typeof value[i] === 'number') {
      arr.push(true)
    } else {
      arr.push(false)
    }
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === false) {
      return false
    }
  }
  return true
}
export default {
  
  name: "NewProject",
  setup() {
    return {

      v$: useVuelidate()
    }
  },
  data() {
    return {
      dataLoaded: null,
      numberOfInputs: null,
      header: [],
      hasHeader: '',
      loadLimits: false,
      advancedInputs: false,
      dataToSend: {},
      dataNotSent: true,
      projectName: "",
      projectDescription: "",
      inputMinLimList: [],
      inputMaxLimList: [],
      limPointCloseness: [],
      visualizationsLayers: Number,
      suggestedPoints: Number,
      maxNeurons: 12,
      maxIter: 2000,
      alphaValue: 1,
      dataarray: [],
      variablesType:[],
      optimisationTarget:'max',
      optValue:0,

      auth: Auth,
      user: {},
      username: undefined,
      session: {},
      authHeader: null,
      formValidationClass:"",
      formValidationErrorMessage:"",
      showErrorMessages :false,
      maxLayers:"",
      numberOfRowsInDataset:0,
      isDataLegit:""

    };
  },
  props:{
    numActProj:0
  },
  validations() {
    return {
      projectName: {
        required,
        $autoDirty: true
      },
      projectDescription: {
        maxLength: maxLength(150),
        $autoDirty: true
      },
      inputMinLimList: {
        required,
        $autoDirty: true,
        isAllNumeric
      },
      inputMaxLimList: {
        required,
        $autoDirty: true,
        isAllNumeric
      },
      limPointCloseness: {
        required,
        $autoDirty: true,
        isAllNumeric
      },
      visualizationsLayers: {
        required,
        integer,
        $autoDirty: true,
        minValue:minValue(3),
        maxValue:maxValue(this.maxLayers)
      },
      suggestedPoints: {
        required,
        integer,
        $autoDirty: true,
        minValue:minValue(1),
        maxValue:maxValue(15)
      },
      maxNeurons: {
        required,
        integer,
        $autoDirty: true,
        minValue:minValue(10),
        maxValue:maxValue(30)
      },
      maxIter: {
        required,
        integer,
        $autoDirty: true,
        minValue:minValue(100),
        maxValue:maxValue(5000)
      },
      alphaValue: {
        required,
        numeric,
        $autoDirty: true,
        minValue:minValue(0.1),
        maxValue:maxValue(10)
      },
      numberOfInputs: {
        integer,
        minValue: minValue(2),
        maxValue: maxValue(8),
        $autoDirty: true

      },
      optValue:{
        numeric,
        required : requiredIf(function(){
          return this.optimisationTarget==="num"}),
        $autoDirty:true
      },
      numberOfRowsInDataset:{
        maxValue :maxValue(600)
      }

    }
  },
  components: {
    Mybutton,
    NavBar
  },
  methods: {
    getCol(arr, col) {
      var column = [];
      for (var i = 0; i < arr.length; i++) {
        column.push(arr[i][col]);
      }
      return column;
    },
    
    getMin(arr) {
      let array = [...arr]
      let min = Number(array[0])
      for (var i = 0; i < array.length; i++) {
        if (Number(array[i]) < min) min = Number(array[i])
      }
      return min
    },
    getMax(arr) {
      let array = [...arr]
      let max = Number(array[0])
      for (var i = 0; i < array.length; i++) {
        if (Number(array[i]) > max) max = Number(array[i])
      }
      return max
    },

    getOthervariables(filedata) {

      this.numberOfInputs = parseInt((filedata[1].length - 1).toString());
      this.variablesType = []
      for (let i = 0 ; i<this.numberOfInputs;i++){
        this.variablesType.push('0')
      }
      this.header = []
      if (
          typeof filedata[0][0] === "string" &&
          isNaN(filedata[0][0])
      ) {
        this.header = filedata[0];
        this.header.pop()
        this.hasHeader = 'yes';
        this.dataLoaded.shift()
      } else {
        for (let num = 1; num <= this.numberOfInputs; num++) {
          this.header[num - 1] = "Variable " + num
        }
        this.hasHeader = 'no';
      }
      this.isDataLegit = this.dataLoaded.every(row => row.every(cell => typeof cell == 'number'))
      if(this.isDataLegit){

        this.numberOfRowsInDataset = this.dataLoaded.length
      
        this.inputMinLimList=[]
        this.inputMaxLimList=[]
        for (let i = 0; i < this.numberOfInputs; i++) {
          let col = []
          col = this.getCol(this.dataLoaded, i)

          let min = Math.min(...col)
          let max = Math.max(...col)

          this.inputMinLimList.push(min)
          this.inputMaxLimList.push(max)
          this.limPointCloseness[i] = 0.15
        }
        if (this.numberOfInputs === 2) {this.visualizationsLayers = 50; this.maxLayers = 70}
        else if (this.numberOfInputs === 3) {this.visualizationsLayers = 10 ;this.maxLayers =20}
        else if (this.numberOfInputs === 4) {this.visualizationsLayers = 7 ;this.maxLayers=15}
        else if(this.numberOfInputs >= 5){this.visualizationsLayers=7; this.maxLayers=9}

        if(!this.v$.numberOfInputs.$error){

          this.loadLimits = true;
        }
        else{
          this.showFormValidationAlert()
        }
      }
      else{
        this.loadLimits = false
        this.inputMinLimList = []
        this.inputMaxLimList = []
        this.showFormValidationAlert()

      }
      
      


    },

    loadData(e) {
      const file = e.target.files[0];

      const reader = new FileReader()
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result
        const wb = XLSX.read(bstr, {type: 'binary'})
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        /* Convert array of arrays */
        this.dataarray = XLSX.utils.sheet_to_json(ws, {header: 1})
        
        /* Update state */
        
        
        this.dataarray = this.dataarray.filter(item => !(item.length ===0))
        

        this.dataLoaded = [...this.dataarray]
        this.getOthervariables(this.dataarray)
        
        

      }
      reader.readAsBinaryString(file)

    },
    async submit() {

      
      this.dataToSend = {
        "creation_time": Date.now(),
        "project_name": this.projectName,
        "project_description": this.projectDescription,
        "headers": this.header,
        "data_array": this.dataLoaded,
        "input_min_lim_list": this.inputMinLimList,
        "input_max_lim_list": this.inputMaxLimList,
        "lim_point_closeness": this.limPointCloseness,
        "layers": this.visualizationsLayers,
        "n_batch": this.suggestedPoints,
        "number_of_inputs": String(this.numberOfInputs),
        "max_iter": this.maxIter,
        "max_neurons": this.maxNeurons,
        "alpha_value": this.alphaValue,
        "number_of_active_projects":this.numActProj,
        "variables_type":this.variablesType,
        "optimisation_target":this.optimisationTarget,
        "opt_value":this.optValue

      }
      this.dataNotSent = false

      const headers = {
        'Content-Type': "application/json",
        Authorization: this.authHeader
      }

      const response = await fetch(
          "https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/project",
          {
            method: "POST",
            headers,
            body: JSON.stringify(this.dataToSend)
          }
      );
      const projectCreated = await response.json()
      if (projectCreated.message == "done") {
        this.$emit("projectCreated", true)

      }
      

       

    },
    showAdvancedInputs() {
      this.advancedInputs = true
    },
    showDash() {
      this.$emit("showdash", true)
    },
    showFormValidationAlert(){
      if(!this.formValidated){
        this.showErrorMessages = true
        this.formValidationClass = "alert alert-danger"
        this.formValidationErrorMessage = "Please fill in the form correctly. Check the form for more details"
      }
      if (!this.isDataLegit){
        this.formValidationClass = "alert alert-danger"
        this.formValidationErrorMessage = "Data set contains non numeric values, please fix it and retry"
      }
    },
    formValidCheck(){
      return !this.v$.$error
    }
  },
  async created() {
    this.user = await Auth.currentAuthenticatedUser();
    this.username = this.user.username;
    this.session = await Auth.currentSession();
    this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;

    
  },
  computed:{
    formValidated(){
      this.v$.$touch()

      return !this.v$.$error
    }
  },
};
</script>
<style scoped>
.errormessage {
  font-size: 16px;
  color: red;
}
.hideerrormessage {
  display: none;
}
.formValidation{
  width:50%;
  margin:2rem auto;
  text-align: center;
  font-size:1.2rem
}
.topbar{
  background-color: #f0f0f0;
  width: 60rem;
  margin: auto;
  border-radius: 5px;
  padding: 0.5rem 0.3rem;
}
.topbar h1{
  width: fit-content;
  letter-spacing: 2px;
  padding: 0;
}
button{
  letter-spacing: 2px;
}
.navbar {
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  justify-content: center;

}

form {
  background-color: rgba(255, 255, 255, .45);
  padding: 1.5rem 4rem;
  border-radius: 20px;
  font-size: 20px;
  min-width: 500px;
  width: 60rem;
  margin: auto;


}

.uploadfields {
  width: 60%;
  margin: auto;
}

@media screen and (max-width: 400px) {
  .form {
    min-width: 300px;
  }

  form {
    min-width: 300px;
  }
}
</style>
