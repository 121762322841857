<template>
<div class="aboutpage container-sm">
  <NavBar/>
    <img src="../assets/1.jpg" alt=""/>
    <div class="text ">
        <h1>About Us</h1>
        <h3>Sunthetics accelerates sustainable innovation in the chemical industry.</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores perspiciatis reprehenderit cumque necessitatibus aspernatur fugit blanditiis voluptates in! Quasi explicabo autem delectus sit modi optio nemo fugiat possimus, facilis culpa.
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti laudantium obcaecati dignissimos inventore, a explicabo nemo quas hic quia maiores maxime pariatur omnis ut veniam veritatis iusto commodi sed aperiam!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam cumque sed dolor alias consequuntur quo sapiente deleniti optio animi, natus nostrum nihil tempore asperiores aliquam eum veniam ratione magnam distinctio.
        </p>
    </div>

    
</div>
    
</template>

<script>
import NavBar from "../components/Navbar.vue"
export default {
    components:{
        NavBar
    }
}
</script>
<style scoped>
.aboutpage{
    width:100vw;
    display: flex;
    flex-direction: column;
}
.text{
    margin-inline-start: 17%;
    margin-inline-end: 17%;
    margin-block-start: 6%;
}
p{
    line-height: 50px;
    font-size: 20px;
    font-weight: 300;
}
h1{
    font-size: 50px;
}
h3{
    font-size:30px;
    font-weight: 500;
}
</style>
