<template>
  <div class="all text-center input pt-1">
    
    <div :class="[dataneeded.data[0][0].length >5 ? 'tablesCol':'tables']">
      <div class="data mx-3 mb-4">
        
        <table class="table table-striped table-bordered border-dark  rounded">
          <thead class="">
            <tr class="table-color text-light">

              <th :colspan="dataneeded.data[0][0].length" class="align-middle">
                 Data | Version {{dataSetVersionToShow+1}}
              </th>
              
              <th colspan="1" class="text-center">
                <div class="dropdown">
                  <button class="btn text-light btn-sm dropdown-toggle font" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                    Change Version
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li v-for="(version, index) in dataneeded.data.length" :key="version">
                      <a class="dropdown-item" href="#" @click="changeDataSetVersion(index)">Version {{index + 1}}</a>
                    </li>
                  </ul>
                </div>
              </th>
            </tr>
            <tr class="table-bordered border-dark" >
              <th scope="col">#</th>
              <th scope="col" v-for="header in dataneeded.headers[0]" :key="header">
                {{ header }}
              </th>
              <th scope="col">
                Target variable
              </th>
            </tr>
          </thead>
          <tbody>

          <tr v-for="(arr,index) in dataneeded.data[dataSetVersionToShow]" :key="arr">

            <th scope="row" class="">{{ index + 1 }}</th>
            <td v-for="data in arr" :key="data">
              {{ data }}
            </td>
          </tr>
          </tbody>
        </table>
        <br>


      </div>
      
      <div class="data2">
        <div class="row z">

          <div class="">
            <table class="table table-striped table-sm table-bordered border-dark rounded">
              <thead class="">
                <tr class="table-color">
                  <th colspan="1" class="align-middle text-light">Parameters | Version {{parametersVersionToShow+1}}</th>
                  <th colspan="1">
                    <div class="dropdown">
                      <button class="btn text-light btn-sm dropdown-toggle font" type="button" id="dropdownMenuButton2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                        Change Version
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <li v-for="(version, index) in dataneeded.data.length" :key="version">
                          <a class="dropdown-item" href="#" @click="changeParametersVersion(index)">Version {{index + 1}}</a>
                        </li>
                      </ul>
                    </div>
                  </th>
                </tr>
              <tr class="table-bordered border-dark">
                <th scope="col">Attribute</th>
                <th scope="col">Value</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                
                <td> Optimization Algorithm</td>
                <td>{{ dataneeded.ml_method[parametersVersionToShow]=="xgb" ? 'GBDT' : "Artificial Neural Nets"   }}</td>
              </tr>
              <tr>
                
                <td>Visualization Layers</td>
                <td>{{ dataneeded.visualizationlayers[parametersVersionToShow] }}</td>
              </tr>
              <tr>
                
                <td>Number of suggested points</td>
                <td>{{ dataneeded.suggestedpoints[parametersVersionToShow] }}</td>
              </tr>
              <tr v-if="dataneeded.ml_method[parametersVersionToShow]!='xgb'">
                
                <td>Maximum number of prediction nodes</td>
                <td>{{ dataneeded.max_neurons[parametersVersionToShow] }}</td>
              </tr>
              <tr>
                
                <td>Regularization value</td>
                <td>{{ dataneeded.alphav[parametersVersionToShow] }}</td>
              </tr>
              <tr>
              
                <td>Maximum {{dataneeded.ml_method[parametersVersionToShow]=='xgb' ? "Depth" : "Iterations"}}</td>
                <td>{{ dataneeded.maxit[parametersVersionToShow] }}</td>
              </tr>
              <tr v-if="dataneeded.ml_method[parametersVersionToShow]!='xgb'">
                <td>Number of neurons</td>
                <td>{{ dataneeded.neurons_opt[parametersVersionToShow]}}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div class="mt-2"></div>
        <div class="row">

          <div class="limClass">
            <table class="table table-striped table-sm table-bordered border-dark rounded">
              <thead class="">
                <tr class="table-color">
                  <th colspan="1" class="align-middle text-light">MIN/MAX Limits | Version {{limitsVersionToShow+1}}</th>
                  <th :colspan="dataneeded.data[0][0].length">
                    <div class="dropdown">
                      <button class="btn text-light btn-sm dropdown-toggle font" type="button" id="dropdownMenuButton3"
                              data-bs-toggle="dropdown" aria-expanded="false">
                        Change Version
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                      <li v-for="(version, index) in dataneeded.data.length" :key="version">
                        <a class="dropdown-item" href="#" @click="changeLimitsVersion(index)">Version {{index + 1}}</a>
                      </li>
                      </ul>
                    </div>
                  </th>
                </tr>
              <tr class="table-bordered border-dark">
                
                <th scope="col">Attribute</th>
                <th scope="col" v-for="header in dataneeded.headers[0]" :key="header">
                  {{ header }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                
                <td>Minimum limit</td>
                <td v-for="data in dataneeded.inputminlimit[limitsVersionToShow]" :key="data">{{ data }}</td>
              </tr>
              <tr>
                
                <td>Maximum limit</td>
                <td v-for="data in dataneeded.inputmaxlimit[limitsVersionToShow]" :key="data">{{ data }}</td>
              </tr>
              <tr>
                
                <td>Exploitation limit</td>
                <td v-for="data in dataneeded.lim_point_closeness[limitsVersionToShow]" :key="data">{{ data }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

  </div>

</template>
<script>

export default {
  name: "Datainput",
  props: {
    dataneeded: [],
  },
  data() {
    return {
      graph: null,
      dataSetVersionToShow:this.dataneeded.versionToShow,
      parametersVersionToShow:this.dataneeded.versionToShow,
      limitsVersionToShow:this.dataneeded.versionToShow
    }
  },

  methods: {
    changeDataSetVersion(index){
      this.dataSetVersionToShow = index
    },
    changeParametersVersion(index){
      this.parametersVersionToShow = index
    },
    changeLimitsVersion(index){
      this.limitsVersionToShow = index
    },
    changeInputDataVersion(index){
      this.dataSetVersionToShow = index
      this.parametersVersionToShow = index
      this.limitsVersionToShow = index
    }
    
    
  },
  

};

</script>
<style scoped>
.title {
  color: black;
  padding: 0.5rem 0.75rem;
  background-color: rgba(255, 255, 255, 0.993);
  width: fit-content;
  border-radius: 10px;

  font-size: 1rem;
}
.row{
  margin: auto;
}
.input{
  min-height: fit-content;
  margin: 2rem auto 1rem auto;
  height: fit-content;
  background-color: white;
  
}
.table-color{
  /* background: #1616cc;  
  background: -webkit-linear-gradient(to right, #1CB5E0, #1919d3);  
  background: linear-gradient(to right, #1054bb, #1313d3);  */
  background-color: #7f7f7f ;
}
.input-data-title{
  width: 50%;
  height: 10%;
  margin: 0 auto 4% auto;
  background-color: transparent;
  border-radius: 20px;
  
  
  
  
  
}
.input-data-title h4{
  font-size: 1rem;
  
  background: #1515b8;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #1515bd);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1054bb, #1010c4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.7vw;
  padding-bottom: 0.6vw;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px ;
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto -1vw;
  border-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
}
.input-data-title button{
  font-size: 1rem;
  margin: auto -1vw auto 0;
  padding: 0.5vw 1vw;
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.tables{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tablesCol{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.tablesCol .data{
  width: 60%;
  }
.tablesCol .data2{
  width: 60%;
}

.data {
  max-height: 60vh;
  width: 42%;
  overflow-y: scroll;
  display: block;
  
}
.data2{
  height: fit-content;
  width: 42%;
}
@media screen and (min-height:1000px){
  .tables{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .data{
    width: 70%;
  }
  .data2{
    width: 70%;
  }
  .data2 .z{
    margin-top: 5%;
  }
}
@media screen and (max-width:1350px){
  .tables{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .data{
    width: 70%;
  }
  .data2{
    width: 70%;
  }
  .data2 .z{
    margin-top: 5%;
  }
}

table {
  background-color: white;
  font-size: 1rem;
}
.font{
  font-size: 1rem;
}
/* @media screen and (max-width: 1400px) {
  .title {
    font-size: 12px;
  }
}
.font{
  
}
@media screen and (max-width: 1000px) {
  .title {
    font-size: 8px;
  }
} */


</style>
