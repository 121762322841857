<template>
  <div class="loading container" v-if="loading ===false">{{ loaded }}</div>
    
  <div class="pred" v-if="loading">
    <div class="warnings-buttons d-flex flex-column justify-content-between align-items-start">
      <div class="other-data d-flex flex-row justify-content-between align-items-center mb-4">

        <div class="warnings">
            <div class="card-body rounded d d-flex flex-column align-items-start">
              <div class=" warnings-title  d-flex flex-row justify-content-between mx-4">

                
                <h5 class="card-title text-center text-light">Warnings | Version {{ warnings_version+1}}</h5>
                
              </div>
              <br>
              <ul v-if="dataneeded.warnings[warnings_version][0]">
                <li class="warnings-text" v-for="warning in dataneeded.warnings[warnings_version]" :key="warning">
                  {{warning}} <br> <button v-if="warning.startsWith('Warning 101')" class="btn btn-secondary btn-sm mb-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExampleIncreaseNodes" aria-expanded="false" aria-controls="collapseExampleIncreaseNodes">Increase nodes</button>
                  <div class="collapse" id="collapseExampleIncreaseNodes" v-if="warning.startsWith('Warning 101')">
                    <div class="card card-body w-50">
                      <h6>Current number of nodes is {{dataneeded.neurons_opt[warnings_version]}} </h6>
                      <div class="d-flex flex-row  justify-content-between align-items-center">
                        <div class="form-floating ">
                          <input type="number" class="form-control" id="floatingPassword" placeholder="" v-model="increaseNodesValue">
                          <label for="floatingPassword">Increase nodes by : </label>
                        </div>
                        <button v-if="updatedNodesLoading" class="btn btn-sm h-50 btn-success" @click="updateNodes()" :disabled="checkNodesValue">Submit</button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-if="!dataneeded.warnings[warnings_version][0]">
                <li class="font">No Warnings</li>
              </ul>
            </div>
            
        </div>
        <div class="manual-export-btns d-flex flex-column align-items-center justify-content-around">

          
        
                <button class="display-versions-btn btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
                    Export Prediction Data
                </button>
                <div class="versions mt-3 collapse p-1" id="collapseExample2">

                    
                  <div class="versions-list d-flex flex-row justify-content-around align-items-center">
                      <div class="version-item" v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                          <h5 class="p-1 btn" @click="exportAnnPred(index)">Version {{index +1 }}</h5>
                          
                      </div>
                      
                      
                  </div>
                    
                </div>
            
          
          <a class="btn to-manual-pred" href="#manual">Manual Prediction</a>
        </div>
      </div>
      
          <div class="graphs">

          <div class="row">
            <div class="training">
              <div id="carouselExampleControls" class=" rounded carousel-dark carousel slide carousel-fade" data-bs-interval="false">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
                      <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Training data | Version {{training_graph_version+1}}</h4>
                      <button v-if="parseInt(dataneeded.number_of_inputs[0]) >3" class="btn btn-light text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVariablesToPlot2" aria-expanded="false" aria-controls="collapseVariablesToPlot2">Change variables to plot</button>

                      <div class="dropdown">
                        <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Change Version
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                            <a class="dropdown-item font" href="#train" @click="changeTraining(index)" >Version {{index+1}}</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div  class="collapse" id="collapseVariablesToPlot2">
                      <div class="d-flex flex-column justify-content-around align-items-center ">

                        <h4 class="title">Variables to plot</h4>
                        <p class="fw-2">Select three variables to plot, the rest will be in the sliders</p>
                        <div class="d-flex flex-row">

                          <div class="me-4" v-for="variable in parseInt(dataneeded.number_of_inputs)" :key="variable" >
                            <label class="me-2">{{dataneeded.headers[0][variable-1]}}</label>
                            <input type="checkbox" v-model="variablesToPlotTraining[variable-1]" @change="checkCheckboxesTraining()">
                          </div>
                        </div>
                        <button class="btn btn-dark" :disabled="!canPlotTraining" @click="plotNewVariablesTraining()">Plot</button>
                      </div>
                    </div>
                    <div id="trainindata" class="bg-light"></div>
                    <div :class ="noDataPointsAlertClass" style="margin:1rem auto">{{noDataPointsAlertMessage}}</div>
                    <div id="trainingData"></div>
                    
                    <div v-if="parseInt(dataneeded.number_of_inputs)==5">
                    </div>
                    

                  </div>
                  <div class="carousel-item">
                    <div class="graph-title d-flex flex-row justify-content-between align-items-center">
                      <h4 class="title text-dark"><i class="bi bi-clipboard-data"> </i> Training data with prediction scale | Version {{training_graph_fixed_scale_version+1}}</h4>
                      <div class="dropdown">
                        <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                          Change Version
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                            <a class="dropdown-item font" href="#train" @click="changeTrainingFixedScale(index)">Version {{index+1}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="trainingdatafixed" class="bg-light"></div>
                    <div :class ="noDataPointsAlertClassFixed" style="margin:1rem auto">{{noDataPointsAlertMessageFixed}}</div>
                    <div id="trainingDataFixed"></div>
                  </div>
                  

                </div>
                <button class="carousel-control-prev prev" type="button" data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next next" type="button" data-bs-target="#carouselExampleControls"
                        data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              
            </div>
            
            <!--  -->
          </div>
          <br>
          <div class="d-flex justify-content-around">
            <h4>Interpretation</h4>
            <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseInputData" aria-expanded="false" aria-controls="collapseInputData">
              Show
            </button>
          </div>
            <div class="collapse" id="collapseInputData">
                <div class="card card-body">
                  The plot helps visualize the data uploaded by the user in the reaction space. These data points
are used to train the machine-learning algorithms.
Every point corresponds to a distinct combination of the input variables, as described in the plot
axis labels and sliders. The colorscale bar to the side describes the target value.
Use the arrows to change the colorscale, using your training data or the prediction data for
limits on the colorscale.
                </div>
            </div> 
            <br>

          <div class="row mt-5" id="pred" v-if="parseInt(dataneeded.number_of_inputs)<=3">
            <div class="training" >
              <div id="carouselExampleControls1" class=" rounded carousel-dark carousel slide carousel-fade" data-bs-interval="false">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="graph-title d-flex flex-row justify-content-between align-items-center">

                      <h4 class="title text-dark" ><i class="bi bi-graph-up"></i> Prediction | Version {{ann_prediction_version+1}}</h4>
                      <div class="d-flex flex-row">
                        <!-- <button class="btn text-light" @click="exportAnnPred(ann_prediction_version)">Export Prediction Data | Version {{ann_prediction_version+1}}</button> -->
                        <div class="dropdown">
                          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                            Change Version
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                            <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                              <a class="dropdown-item" href="#pred" @click="changeAnnPrediction(index)">Version {{index+1}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div id="ann" class="bg-light"></div>
                  </div>
                  <div class="carousel-item">

                    <div class="graph-title d-flex flex-row justify-content-between align-items-center">

                      <h4 class="title text-dark"><i class="bi bi-graph-up"></i> Prediction with training data scale | Version {{ann_prediction_fixed_scale_version+1}}</h4>
                      <div class="d-flex flex-row">
                        <!-- <button class="btn text-light" @click="exportAnnPred(ann_prediction_fixed_scale_version)">Export Prediction Data | Version {{ann_prediction_fixed_scale_version+1}}</button> -->

                        <div class="dropdown">
                          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-expanded="false">
                            Change Version
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                            <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                              <a class="dropdown-item" href="#pred" @click="changeAnnPredictionFixedScale(index)">Version {{index+1}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div id="annfixed" class="bg-light"></div>
                  </div>
                </div>
                <button class="carousel-control-prev prev" type="button" data-bs-target="#carouselExampleControls1"
                        data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next next" type="button" data-bs-target="#carouselExampleControls1"
                        data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>

            </div>

            

            <!--            <div class="prediction">-->
            <!--                <div id="ann" ><h4>Ann prediciton</h4></div>-->
            <!--                <div id="annfixed" ><h4>Ann predicition with fixed scale</h4></div>-->
            <!--            </div>-->
          </div>
          <div class="row mt-5" v-if="parseInt(dataneeded.number_of_inputs)>3">
            <div class="training">
              <div id="carouselExampleControls5" class=" rounded carousel-dark carousel slide carousel-fade" data-bs-interval="false">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
                      <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Prediction| Version {{ann_prediction_version+1}}</h4>
                      <button class="btn btn-light text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVariablesToPlot" aria-expanded="false" aria-controls="collapseVariablesToPlot">Change variables to plot</button>
                      <div class="dropdown">
                        <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton7" data-bs-toggle="dropdown" aria-expanded="false">
                          Change Version
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton7">
                          <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                            <a class="dropdown-item font" href="#" @click="changeAnnPrediction(index)" >Version {{index+1}}</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div class="collapse" id="collapseVariablesToPlot">
                      <div class="d-flex flex-column justify-content-around align-items-center ">

                        <h4 class="title">Variables to plot</h4>
                        <p class="fw-2">Select three variables to plot, the rest will be in the sliders</p>
                        <div class="d-flex flex-row">

                          <div class="me-4" v-for="variable in parseInt(dataneeded.number_of_inputs)" :key="variable" >
                            <label class="me-2">{{dataneeded.headers[0][variable-1]}}</label>
                            <input type="checkbox" v-model="variablesToPlot[variable-1]" @change="checkCheckboxes()">
                          </div>
                        </div>
                        <button class="btn btn-dark" :disabled="!canPlot" @click="plotNewVariables()">Plot</button>
                      </div>
                    </div>
                    
                   <div v-if="!graphLoaded" class="d-flex flew-row justify-content-center">
                      <div class="spinner-border text-center mt-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div  id="annPrediction" class="bg-light"></div>
                    
                    
                  </div>
                  <div class="carousel-item">
                    <div class="graph-title d-flex flex-row justify-content-between align-items-center">
                      <h4 class="title text-dark"><i class="bi bi-clipboard-data"> </i> Prediction with training scale | Version {{ann_prediction_fixed_scale_version+1}}</h4>
                      <div class="dropdown">
                        <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton88" data-bs-toggle="dropdown" aria-expanded="false">
                          Change Version
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton88">
                          <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                            <a class="dropdown-item font" href="#train" @click="changeAnnPredictionFixedScale(index)">Version {{index+1}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="!graphLoaded" class="d-flex flew-row justify-content-center">
                      <div class="spinner-border text-center mt-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div  id="annPredictionFixed" ></div>
                    
                    
                    
                  </div>
                </div>

                <button v-if="graphLoaded" class="carousel-control-prev prev" type="button" data-bs-target="#carouselExampleControls5"
                        data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button v-if="graphLoaded" class="carousel-control-next next" type="button" data-bs-target="#carouselExampleControls5"
                        data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
          <br>
          <div class="d-flex justify-content-around">
            <h4>Interpretation</h4>
            <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseAnn" aria-expanded="false" aria-controls="collapseAnn">
              Show
            </button>
          </div>
            <div class="collapse" id="collapseAnn">
                <div class="card card-body">
                  The plot helps visualize the predicted reaction behavior. Hover over the dots to read
coordinates and predicted target values.
Each axis and slider corresponds to a different variable from the dataset uploaded by the user.
The colorscale bar to the side describes the target value.
Use the arrows to change the colorscale, using your training data or the prediction data for
limits on the colorscale. <br>
For more than 3 variables, use the selection panel to choose the variables that are plotted in the
3-dimensional space. We recommend selecting variables with higher importance for easier
visualization. <br>
Use the relevant buttons to download predicted data and make manual predictions of the target
value for distint points. <br>
CAUTION: The prediction of reaction performance is completely dependent on the quality of the
data uploaded by the user and the predictive model hyperparameters. As the user uploads
additional data through the “Update and Iterate” tab, the prediction will change. Use the
version tracking buttons to visualize and export data from predictions throughout the
experimental campaign.
                </div>
            </div> 
        </div>
        
      
      
      
    </div>
    <div v-if="dataneeded.heatmap_key.length!=0" class="">
      <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
        <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Variable Impact | Version {{variable_impact_version+1}}</h4>
        <div class="dropdown">
          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Change Version
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
              <a class="dropdown-item font" href="#" @click="changeVariableImpact(index)" >Version {{index+1}}</a>
            </li>

          </ul>
        </div>
      </div> 
      
    </div>
    
    


    
    <br>
    <div v-if="displayManualPrediction" class="manualprediction" >
      

        <h2 class=" text-dark">Manual Prediction</h2>
      
      <div class="manual-content">

        <div class="scrolltable" v-if="showAddedValuesTable">

          <table v-if="showAddedValuesTable" class="table table-bordered rounded">
              
                  <thead>
                      <tr>
                          <th :colspan="dataneeded.headers[0].length + 1" class="text-center text-light" style="background-color: #7f7f7f ;">Data Points To Predict</th>
                      </tr>   
                      <tr>
                          
                          <th  class="text-center" scope="col" v-for="header in dataneeded.headers[0]" :key="header">
                              {{ header }}
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="arr in manualPrecitionArray" :key="arr">
                          
                          <td  class="text-center" v-for="data in arr" :key="data">
                              {{ data }}
                          </td>
                      </tr>
                  </tbody>
          </table>
          
        </div>
        <br>
        <div class="addraw" v-if="showAddValues">
            <table  class="table table-bordered">
                <thead>
                    <tr class="blue-color text-center text-light"> 
                        <th scope="col" v-for="header in dataneeded.headers[0]" :key="header">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                        <td v-for="index in parseInt(dataneeded.number_of_inputs)" :key="index">
                            <input type="number" v-model.number="oneRowArray[index-1]">
                        </td>
                    </tr>
                </tbody>
                <p v-if="v$.oneRowArray.$error" class="errormessage">Fill in all the fields. Values must be numeric</p>
            </table>
            <button class="btn btn-success mb-1" @click="loadDataFromManual">Save Row</button>
            
            <button class="btn btn-warning" @click="hideAddRow">Cancel</button>
        </div>
        <div class="add_data d-flex flex-row" v-if="showAddData" id="manual">
          <div class="d-flex flex-column add">
            <div class="import">
                <label for="csvfile2" class="form-label ms-1 text-dark bg-light p-1" style="width:fit-content; border-radius:10px;">Add Data Points Through CSV or Excel File</label>
                <input class="form-control form-control-md custom-file-upload" id="csvfile2" type="file" @change="loadDataFromImport" pattern="^.+\.(xlsx|xls|csv)$" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" >   
            
                <p class="errormessage" style="font-weight:1000;" v-if="v$.numberOfInputsFromImport.$error" >CSV/Excel File Should have {{ dataneeded.number_of_inputs[0] }} variables</p>
            </div>
            <div>
                <button class="btn btn-success" @click="showAddValuesTable">Add a Data Point</button>
            </div>
          </div>
          <div class="deletesection" v-if="changesOccuredInManual || changesOccuredInImport">
            
              
            <button class="btn btn-sm btn-danger" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3">
                Delete Values
            </button>
            
            <div class="collapse " id="collapseExample3">
              <br>
              <div class="d-flex flex-column deletedata">

                <button class="btn btn-secondary"  @click="cancelAddImport" v-if="changesOccuredInImport">Delete Imported Dataset</button>
                <button class="btn btn-secondary"  @click="cancelAddManually" v-if="changesOccuredInManual">Delete Values Added Manually</button>
              
              </div>
            </div>

          </div>
        </div>
        <br>
        <div v-if="!dataSent">
          <div class="confirmationbtn" v-if="showPredictionButton">
                <div class="dropdown">
                <button class="btn btn-primary btn-lg dropdown-toggle" type="button" id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-expanded="false">
                    Predict Using ANN Model Of : 
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                    <li v-for="(version, index) in dataneeded.sortkeys.length" :key="version">
                        <a class="dropdown-item" href="#manual" @click="manualPrediction(index)">Version {{index+1}}</a>
                    </li>
                </ul>

            </div>
          </div>

      

        </div>
        <div class="spinner" v-if="loadingManual">

          <div class="spinner-border text-dark" role="status" >
                        <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="showPredictionTable" class="prediction-table">
          <table class="table table-bordered">
                              
              <thead>
              <tr class="text-light blue-color">
                  <th :colspan="dataneeded.headers[0].length + 2" class="text-center">Prediction Results</th>

              </tr>
              <tr class="text-center">
              <th scope="col" colspan="1">#</th>
              <th scope="col" v-for="header in dataneeded.headers[0]" :key="header">
                  {{ header }}
              </th>
              <th scope="col" colspan="1">
                  Target variable
              </th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center" v-for="(arr,index) in manualPrecitionArray" :key="arr">
                <th scope="row">{{ index + 1 }}</th>
                <td v-for="data in arr" :key="data">
                    {{ data }}
                </td>
                <td>{{y_pred.pred_to_do[index].toFixed(2)}}</td>
              </tr>
              </tbody>
          </table>
          <div class="d-flex flex-row justify-content-around">
            <button class="btn btn-secondary text-light" @click="makeAnotherPrediction()"> Make Another Prediction</button>
            <button class="btn btn-success" @click="dowloadCsv()">Save Results Into CSV File</button>
          </div>
        </div>
      </div>
    
    </div>
</div>
</template>
<script>
import XLSX from 'xlsx'
import  useVuelidate  from '@vuelidate/core'
import {required, maxLength,minLength, numeric, integer, minValue,maxValue} from '@vuelidate/validators'
import Mybutton from "../Mybutton.vue"
import {Auth} from 'aws-amplify'
const isAllNumeric = function(value){
  const arr = []
  for (let i = 0; i < value.length;i ++){
    if (typeof value[i] ==='number'){
      arr.push(true)
    }
    else{
      arr.push(false)
    }
  }
  for (let i = 0; i < arr.length;i ++){
    if (arr[i]===false){
      return false
    }
  }
  return true
}
export default {
  name: "Prediction",
  setup(){
    return {
            v$: useVuelidate()
        }
  },
  components:{
    Mybutton,
    
  },
  props: {
    dataneeded: {}
  },
  validations(){
    return{
      numberOfInputsFromImport:{
        integer,
        minValue:minValue(this.dataneeded.number_of_inputs[0]),
        maxValue:maxValue(this.dataneeded.number_of_inputs[0]),
        $autoDirty:true

      },
      oneRowArray:{
        minLength:minLength(parseInt(this.dataneeded.number_of_inputs)),
        maxLength:maxLength(parseInt(this.dataneeded.number_of_inputs)),
        $autoDirty:true,
        isAllNumeric
      },
      increaseNodesValue:{
        required,
        integer,
        minValue : minValue(0),
        maxValue:maxValue(30),
        $autoDirty:true
      },
    }
  },
  data() {
    return {
      valueFromSlider:"",
      sliderValues:[],
      active4input:[],
      graphLoaded:false,
      training_graph: {},
      training_fixed_graph: {},
      ann_graph: {},
      ann_fixed_graph: {},
      show1: [true, false],
      show2: [true, false],
      loading: false,
      loadingManual:false,
      showAddData:true,
      training_graph_version:this.dataneeded.versionToShow,
      training_graph_fixed_scale_version:this.dataneeded.versionToShow,
      ann_prediction_version:this.dataneeded.versionToShow,
      ann_prediction_fixed_scale_version:this.dataneeded.versionToShow,
      warnings_version:this.dataneeded.versionToShow,
      manual_prediction_version: this.dataneeded.versionToShow,
      variable_impact_version:this.dataneeded.versionToShow,
      manualPredictionFromImport:[],
      manualPredictionFromManual:[],
      manualPrecitionArray:[],
      showAddedValuesTable:false,
      changesOccuredInImport:false,
      changesOccuredInManual:false,
      oneRowArray:[],
      showAddValues:false,
      showPredictionButton:false,
      dataSent:false,
      numberOfInputsFromImport:null,
      y_pred:[],
      showPredictionTable:false,
      displayWarnings:false,
      displayManualPrediction:true,
      loadingExport:false,
      results:[],
      pred:[],
      max:"",
      min:"",
      increaseNodesValue:1,
      step:"",
      user:{},
      session:{},
      authHeader:'',
      dataset:[],
      fourth_input:[],
      fourth_input_to_display:"",
      inputDataToPlot:[],
      inputDataToPlotClone:[],
      annPredDataToPlot:[],
      annPredDataToPlotClone:[],
      fourthInputTrainingData:[],
      fourthInputPredictionData:[],
      fifthInputTrainingData:[],
      fifthInputPredictionData:[],
      sixthInputTrainingData:[],
      sixthInputPredictionData:[],
      seventhInputTrainingData:[],
      seventhInputPredictionData:[],
      eightInputTrainingData:[],
      eightInputPredictionData:[],
      fourthInputTrainingDataToDisplay:"",
      fourthInputPredictionDataToDisplay:"",
      fifthInputTrainingDataToDisplay:"",
      fifthInputPredictionDataToDisplay:'',
      sixthInputTrainingDataToDisplay:'',
      sixthInputPredictionDataToDisplay:'',
      seventhInputTrainingDataToDisplay:"",
      seventhInputPredictionDataToDisplay:'',
      eightInputTrainingDataToDisplay:[],
      eightInputPredictionDataToDisplay:[],
      active4InputTrainingData:[],
      active4InputPredictionData:[],
      active5InputTrainingData:[],
      active5InputPredictionData:[],

      filteredTrainingData:[],
      filteredTrainingData2:[],
      filteredTrainingData3:[],
      filteredTrainingData4:[],
      filteredTrainingData5:[],
      filteredAnnPredictionData:[],
      filteredAnnPredictionData2:[],
      filteredAnnPredictionData3:[],
      filteredAnnPredictionData2:[],
      max_y_pred:"",
      min_y_pred:"",
      trainingDataGraph:{},
      somvar:true,
      vall:"",
      xxx:"",
      yyy:"",
      zzz:"",
      ccc:"",
      noDataPointsAlertClass:'',
      noDataPointsAlertMessage:'',
      noDataPointsAlertClassFixed:'',
      noDataPointsAlertMessageFixed:'',
      variablesToPlot:new Array(parseInt(this.dataneeded.number_of_inputs)).fill(false),
      variablesToPlotTraining:new Array(parseInt(this.dataneeded.number_of_inputs)).fill(false),
      canPlot:false,
      canPlotTraining:false,
      colOne:0,
      colOneTraining:0,
      colTwo:1,
      colTwoTraining:1,
      colThree:2,
      colThreeTraining:2,
      colFour:3,
      colFourTraining:3,
      colFive:4,
      colFiveTraining:4,
      colSix:5,
      colSixTraining:5,
      colSeven:6,
      colSevenTraining:6,
      colEight:7,
      colEightTraining:7,
      updateNodesData:{},
      updatedNodesLoading:true
    }
  },
  methods: {
    getgraph() {
      if(parseInt(this.dataneeded.number_of_inputs) <=3){

        Plotly.newPlot("trainindata", JSON.parse(this.training_graph[this.training_graph_version]).data, JSON.parse(this.training_graph[this.training_graph_version]).layout)
        Plotly.newPlot("trainingdatafixed", JSON.parse(this.training_fixed_graph[this.training_graph_fixed_scale_version]).data, JSON.parse(this.training_fixed_graph[this.training_graph_fixed_scale_version]).layout)
        Plotly.newPlot("ann", JSON.parse(this.ann_graph[this.ann_prediction_version]).data, JSON.parse(this.ann_graph[this.ann_prediction_version]).layout)
        Plotly.newPlot("annfixed", JSON.parse(this.ann_fixed_graph[this.ann_prediction_fixed_scale_version]).data, JSON.parse(this.ann_fixed_graph[this.ann_prediction_fixed_scale_version]).layout)
        
      }
    },
    changeVariableImpact(index){
      this.variable_impact_version=index
    },
    changeTraining(index){
      this.training_graph_version = index
      this.training_graph_fixed_scale_version = index

      if(parseInt(this.dataneeded.number_of_inputs)==4){
        this.manageTrainingGraph4Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.manageTrainingGraph5Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==3){

        Plotly.newPlot("trainindata", JSON.parse(this.training_graph[this.training_graph_version]).data, JSON.parse(this.training_graph[this.training_graph_version]).layout)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.manageTrainingGraph6Inputs()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.manageTrainingGraph7Inputs()
      }
    },
    changeTrainingFixedScale(index){
      this.training_graph_fixed_scale_version = index
      this.training_graph_version = index

      if(parseInt(this.dataneeded.number_of_inputs)==4){
        this.manageTrainingGraph4Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.manageTrainingGraph5Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==3){

        Plotly.newPlot("trainingdatafixed", JSON.parse(this.training_fixed_graph[this.training_graph_fixed_scale_version]).data, JSON.parse(this.training_fixed_graph[this.training_graph_fixed_scale_version]).layout)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.manageTrainingGraph6Inputs()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.manageTrainingGraph7Inputs()
      }

    },
    async changeAnnPrediction(index){
      this.ann_prediction_version = index
      this.ann_prediction_fixed_scale_version = index

      if(parseInt(this.dataneeded.number_of_inputs)==4){
        await this.managePredictionGraph4Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        await this.managePredictionGraph5Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==3){

        Plotly.newPlot("ann", JSON.parse(this.ann_graph[this.ann_prediction_version]).data, JSON.parse(this.ann_graph[this.ann_prediction_version]).layout)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        await this.managePredictionGraph6Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        await this.managePredictionGraph7Inputs()
      }

    },
    async changeAnnPredictionFixedScale(index){
      this.ann_prediction_fixed_scale_version = index
      this.ann_prediction_version = index

      if(parseInt(this.dataneeded.number_of_inputs)==4){
        await this.managePredictionGraph4Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        await this.managePredictionGraph5Inputs(index)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==3){

        Plotly.newPlot("annfixed", JSON.parse(this.ann_fixed_graph[this.ann_prediction_fixed_scale_version]).data, JSON.parse(this.ann_fixed_graph[this.ann_prediction_fixed_scale_version]).layout)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        await this.managePredictionGraph6Inputs()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        await this.managePredictionGraph7Inputs()
      }


    },
    changePredictionVersion(index){
      this.training_graph_version = index
      this.training_graph_fixed_scale_version = index
      this.ann_prediction_version = index
      this.ann_prediction_fixed_scale_version = index
      
      this.getgraph()
    },
    changeWarningsVersion(index){
      this.warnings_version = index
    },
    loadDataFromImport(e) {
      this.manualPredictionFromImport = []
      const file  = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          this.manualPredictionFromImport = XLSX.utils.sheet_to_json(ws, { header: 1 })
          console.log("man from imp", this.manualPredictionFromImport)
          if(this.manualPredictionFromImport[this.manualPredictionFromImport.length - 1].length != this.manualPredictionFromImport[1].length){
              this.manualPredictionFromImport.splice(-1,1)
          }
          this.numberOfInputsFromImport = this.manualPredictionFromImport[0].length
          console.log(this.numberOfInputsFromImport)
          this.v$.numberOfInputsFromImport.$touch()
          console.log(this.v$.numberOfInputsFromImport)
          if (
              typeof this.manualPredictionFromImport[0][0] === "string" &&
              isNaN(this.manualPredictionFromImport[0][0])
          ) {
              this.manualPredictionFromImport.shift()
          } 
          if(!this.v$.numberOfInputsFromImport.$error){

            this.manualPrecitionArray = [...this.manualPredictionFromImport,...this.manualPredictionFromManual]
            this.showAddedValuesTable=true
            this.changesOccuredInImport=true
            
            this.showPredictionButton=true
          }

          
      }
      reader.readAsBinaryString(file)
      
    },
    loadDataFromManual(){
      this.v$.oneRowArray.$touch()
        if(!this.v$.oneRowArray.$error){
          this.manualPredictionFromManual.push(this.oneRowArray)
          this.oneRowArray = []
          this.showAddValues=false
          this.manualPrecitionArray = [...this.manualPredictionFromImport,...this.manualPredictionFromManual]
          this.showAddedValuesTable=true
          this.changesOccuredInManual=true
          this.showPredictionButton=true
        }
    },
    showAddValuesTable(){
      this.showAddValues=true
    },
    hideAddRow(){
        this.showAddValues=false
        this.oneRowArray=[]
    },
    cancelAddImport(){
      this.manualPrecitionArray = [...this.manualPredictionFromManual]
      this.manualPredictionFromImport=[]
      this.changesOccuredInImport = false
      if(this.manualPrecitionArray ==0){
        this.showAddedValuesTable=false
        this.showPredictionButton = false
      }
    },
    cancelAddManually(){
      this.manualPrecitionArray = [...this.manualPredictionFromImport]
      this.manualPredictionFromManual=[]
      this.changesOccuredInManual = false
      if(this.manualPrecitionArray.length ==0){
        this.showAddedValuesTable=false
        this.showPredictionButton=false
      }
    },
    async manualPrediction(index){
      const headers = {
                "Content-Type": "application/json",
                Authorization: this.authHeader
            }
      const payload = {
        "sortKey":this.dataneeded.sortkeys[index],
        "data_to_predict":this.manualPrecitionArray
      }
      this.dataSent = true
      this.loadingManual= true
      this.showAddValues = false
      this.showAddData = false
      this.showAddedValuesTable = false
      const response = await fetch(
                `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/manual_prediction`,
                { method:"POST",
                    headers,
                    body:JSON.stringify(payload)}
            )     
      this.y_pred =   await response.json()
      this.loadingManual = false
      
      this.showPredictionTable = true
    },
    makeAnotherPrediction(){
      
      this.showAddData=true
      this.showPredictionTable = false
      this.manualPredictionFromImport=[]
      this.manualPredictionFromManual=[]
      this.manualPrecitionArray=[]
      this.changesOccuredInImport = false
      this.changesOccuredInManual = false
      this.showPredictionButton = false
      this.dataSent=false
    },
    dowloadCsv(){
      
      var csv = ''
      for( let i =0; i< this.manualPrecitionArray.length;i++){
        csv += this.manualPrecitionArray[i].join(',');
        csv +=","+ String(this.y_pred.pred_to_do[i].toFixed(2))
        csv +="\n"
      }
      
  
      
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'manual_prediction_results.csv';
      hiddenElement.click();
    },
    showWarnings(){
      if(!this.displayWarnings){
        this.displayWarnings=true
      }
      else if(this.displayWarnings){
        this.displayWarnings=false
      }
    },
    showManualPrediction(){
      this.displayManualPrediction=true
    },
    async exportAnnPred(index){
      this.loadingExport = true
      const headers = {
          "Content-Type": "application/json",
          Authorization: this.authHeader
      }
      const payload = {
        "sortKey":this.dataneeded.sortkeys[index],
        "export_data":true
      }
      const response = await fetch(
          `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/export_ann`,
          { method:"POST",
              headers,
              body:JSON.stringify(payload)}
      )  
      const presignedUrl = await response.json()
      window.open(presignedUrl.presigned_url, "_blank")

      this.loadingExport=false
      
    },
    async getAnnPred(versionToShow){
      this.graphLoaded=false
      const headers = {
          "Content-Type": "application/json",
          Authorization: this.authHeader
      }
      const payload = {
        "sortKey":this.dataneeded.sortkeys[versionToShow]
      }
      const response = await fetch(
          `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/export_ann`,
          { method:"POST",
              headers,
              body:JSON.stringify(payload)}
      )  
      const csv2 = await response.json()
      let dat = csv2.ann_pred.split("\n")
      this.graphLoaded = true
      return dat.map(item => item.split(",").map(Number))
      
      
      
    },
    
    getCol(matrix, col){
       var column = [];
       for(var i=0; i<matrix.length; i++){
          column.push(matrix[i][col]);
       }
       return column;
    },
    

    async manage4inputs(version){
      this.manageTrainingGraph4Inputs(version)
      await this.managePredictionGraph4Inputs(version)
    },
    async manage5inputs(version){
      this.manageTrainingGraph5Inputs(version)
      await this.managePredictionGraph5Inputs(version)
    },
    async manage6inputs(version){
      this.manageTrainingGraph6Inputs(version)
      await this.managePredictionGraph6Inputs(version)
    },
    async manage7inputs(version){
      this.manageTrainingGraph7Inputs(version)
      await this.managePredictionGraph7Inputs(version)
    },
    async manage8inputs(version){
      this.manageTrainingGraph8Inputs(version)
      await this.managePredictionGraph8Inputs(version)
    },
                
    
    manageTrainingGraph4Inputs(version){
      this.inputDataToPlot = this.dataneeded.data_array[version]
      this.max_y_pred = this.dataneeded.max_y_pred[version]
      this.min_y_pred = this.dataneeded.min_y_pred[version]
      this.fourthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFourTraining)))
      this.fourthInputTrainingData.sort(function(a,b){return a-b})
      
      this.plot4InputsTrainingData()
      this.plot4InputsTrainingDataFixedScale()
    },
    manageTrainingGraph5Inputs(version){
      this.inputDataToPlot = [...this.dataneeded.data_array[version]]
      this.max_y_pred = this.dataneeded.max_y_pred[version]
      this.min_y_pred = this.dataneeded.min_y_pred[version]
      this.fourthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFourTraining)))
      this.fourthInputTrainingData.sort(function(a,b){return a-b})

      this.fourthInputTrainingDataToDisplay = this.fourthInputTrainingData[0]
      this.fifthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFiveTraining)))
      this.fifthInputTrainingData.sort(function(a,b){return a-b})
      this.fifthInputTrainingDataToDisplay = this.fifthInputTrainingData[0]
      this.slide5InputsTrainingData( this.fifthInputTrainingDataToDisplay)



    },
    manageTrainingGraph6Inputs(version){
      this.inputDataToPlot = [...this.dataneeded.data_array[version]]
      this.max_y_pred = this.dataneeded.max_y_pred[version]
      this.min_y_pred = this.dataneeded.min_y_pred[version]

      this.fourthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFourTraining)))
      this.fourthInputTrainingData.sort(function(a,b){return a-b})
      this.fourthInputTrainingDataToDisplay = this.fourthInputTrainingData[0]

      this.fifthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFiveTraining)))
      this.fifthInputTrainingData.sort(function(a,b){return a-b})
      this.fifthInputTrainingDataToDisplay = this.fifthInputTrainingData[0]

      this.sixthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colSixTraining)))
      this.sixthInputTrainingData.sort(function(a,b){return a-b})
      this.sixthInputTrainingDataToDisplay = this.sixthInputTrainingData[0]

      this.slide6InputsTrainingData(this.fifthInputTrainingDataToDisplay, this.sixthInputTrainingDataToDisplay)

    },
    manageTrainingGraph7Inputs(version){
      this.inputDataToPlot = [...this.dataneeded.data_array[version]]
      this.max_y_pred = this.dataneeded.max_y_pred[version]
      this.min_y_pred = this.dataneeded.min_y_pred[version]

      this.fourthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFourTraining)))
      this.fourthInputTrainingData.sort(function(a,b){return a-b})
      this.fourthInputTrainingDataToDisplay = this.fourthInputTrainingData[0]

      this.fifthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFiveTraining)))
      this.fifthInputTrainingData.sort(function(a,b){return a-b})
      this.fifthInputTrainingDataToDisplay = this.fifthInputTrainingData[0]

      this.sixthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colSixTraining)))
      this.sixthInputTrainingData.sort(function(a,b){return a-b})
      this.sixthInputTrainingDataToDisplay = this.sixthInputTrainingData[0]

      this.seventhInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colSevenTraining)))
      this.seventhInputTrainingData.sort(function(a,b){return a-b})
      this.seventhInputTrainingDataToDisplay = this.seventhInputTrainingData[0]

      this.slide7InputsTrainingData(this.fifthInputTrainingDataToDisplay, this.sixthInputTrainingDataToDisplay,this.seventhInputTrainingDataToDisplay)

    },
    manageTrainingGraph8Inputs(version){
      this.inputDataToPlot = [...this.dataneeded.data_array[version]]
      this.max_y_pred = this.dataneeded.max_y_pred[version]
      this.min_y_pred = this.dataneeded.min_y_pred[version]

      this.fourthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFourTraining)))
      this.fourthInputTrainingData.sort(function(a,b){return a-b})
      this.fourthInputTrainingDataToDisplay = this.fourthInputTrainingData[0]

      this.fifthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colFiveTraining)))
      this.fifthInputTrainingData.sort(function(a,b){return a-b})
      this.fifthInputTrainingDataToDisplay = this.fifthInputTrainingData[0]

      this.sixthInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colSixTraining)))
      this.sixthInputTrainingData.sort(function(a,b){return a-b})
      this.sixthInputTrainingDataToDisplay = this.sixthInputTrainingData[0]

      this.seventhInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colSevenTraining)))
      this.seventhInputTrainingData.sort(function(a,b){return a-b})
      this.seventhInputTrainingDataToDisplay = this.seventhInputTrainingData[0]

      this.eightInputTrainingData = Array.from(new Set(this.getCol(this.inputDataToPlot,this.colEightTraining)))
      this.eightInputTrainingData.sort(function(a,b){return a-b})
      this.eightInputTrainingDataToDisplay = this.eightInputTrainingData[0]


      this.slide8InputsTrainingData()


    },
    plot4InputsTrainingData(){
      var trace1 = {
                    x:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),this.colOneTraining),
                    y:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),this.colTwoTraining),
                    z:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),this.colThreeTraining),
                  
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),4),
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.inputDataToPlot,4)),
                      cmax:Math.max(...this.getCol(this.inputDataToPlot,4)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color}<extra></extra> ",
                    type: 'scatter3d'
                  };
      var dataa=[trace1]
      var slidersSteps = []
      for (let i =0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i].toString(),
          method:"animate",
          args:[
            [this.fourthInputTrainingData[i].toString()]
            
          ]
        })
      }
      var framess=[]
      this.inputDataToPlotClone = [...this.inputDataToPlot]
      for (let i =0; i< this.fourthInputTrainingData.length;i++){
        this.filteredTrainingData = this.inputDataToPlotClone.filter(item => item[this.colFourTraining]==this.fourthInputTrainingData[i])
        var xx = this.getCol(this.filteredTrainingData,this.colOneTraining)
        var yy = this.getCol(this.filteredTrainingData,this.colTwoTraining)
        var zz = this.getCol(this.filteredTrainingData,this.colThreeTraining)
        var costumdataa = this.getCol(this.filteredTrainingData,4)
        framess.push({
          name : this.fourthInputTrainingData[i].toString(),
          data : [
            {
              'x' : xx,
              'y' : yy,
              'z' : zz,
              "marker.color" : costumdataa,
              
            }
          ]
        })
      }
      var layoutt = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlotClone, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlotClone, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlotClone, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlotClone, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlotClone, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlotClone, this.colThreeTraining))]}
                          },
                          
                          height:600,
                          sliders : [{
                            pad:{
                              t:30
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 10
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                            
                          }]
                                          
                      
                    
                    };
                    Plotly.react("trainingData",{
                      data : dataa,
                      layout : layoutt,
                      frames : framess
                      
                    })

                    
                    

    },
    plot4InputsTrainingDataFixedScale(){
      var trace1 = {
                    x:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),this.colOneTraining),
                    y:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),this.colTwoTraining),
                    z:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),this.colThreeTraining),
                  
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.getCol(this.inputDataToPlot.filter(item => item[this.colFourTraining]== this.fourthInputTrainingData[0]),4),
                      colorbar:{thickness:20},
                      cmin:this.min_y_pred,
                      cmax:this.max_y_pred,
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color}<extra></extra> ",
                    type: 'scatter3d'
                  };
      var dataa=[trace1]
      var slidersSteps = []
      for (let i =0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i].toString(),
          method:"animate",
          args:[
            [this.fourthInputTrainingData[i].toString()]
            
          ]
        })
      }
      var framess=[]
      this.inputDataToPlotClone = [...this.inputDataToPlot]
      for (let i =0; i< this.fourthInputTrainingData.length;i++){
        this.filteredTrainingData = this.inputDataToPlotClone.filter(item => item[this.colFourTraining]==this.fourthInputTrainingData[i])
        var xx = this.getCol(this.filteredTrainingData,this.colOneTraining)
        var yy = this.getCol(this.filteredTrainingData,this.colTwoTraining)
        var zz = this.getCol(this.filteredTrainingData,this.colThreeTraining)
        var costumdataa = this.getCol(this.filteredTrainingData,4)
        framess.push({
          name : this.fourthInputTrainingData[i].toString(),
          data : [
            {
              'x' : xx,
              'y' : yy,
              'z' : zz,
              "marker.color" : costumdataa,
              
            }
          ]
        })
      }
      var layoutt = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlotClone, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlotClone, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlotClone, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlotClone, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlotClone, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlotClone, this.colThreeTraining))]}
                          },
                          height:600,
                          sliders : [{
                            pad:{
                              t:30
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 10
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                            
                          }]
                                          
                      
                    
                    };
                    Plotly.react("trainingDataFixed",{
                      data : dataa,
                      layout : layoutt,
                      frames : framess
                      
                    })

                    
                    

    },
    slide5InputsTrainingData(value){
      this.fifthInputTrainingDataToDisplay = value
      this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
      this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
      this.xxx =  this.getCol(this.filteredTrainingData2,this.colOneTraining)
      this.yyy =  this.getCol(this.filteredTrainingData2,this.colTwoTraining)
      this.zzz =  this.getCol(this.filteredTrainingData2,this.colThreeTraining)
      this.ccc =  this.getCol(this.filteredTrainingData2,5)
      this.plot5InputTrainingData( )
      this.plot5InputTrainingDataFixedScale()
      this.updateTrainingDataGraph5Inputs()
      this.updateTrainingDataGraph5InputsFixedScale()

    },
    slide6InputsTrainingData(value1, value2){
      this.fifthInputTrainingDataToDisplay = value1
      this.sixthInputTrainingDataToDisplay = value2
      this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
      this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
      this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining]==this.sixthInputTrainingDataToDisplay)

      this.xxx = this.getCol(this.filteredTrainingData3,this.colOneTraining)
      this.yyy = this.getCol(this.filteredTrainingData3,this.colTwoTraining)
      this.zzz = this.getCol(this.filteredTrainingData3,this.colThreeTraining)
      this.ccc = this.getCol(this.filteredTrainingData3,6)
      this.plot6InputTrainingData()
      this.updateTrainingDataGraph6Inputs()
      this.plot6InputTrainingDataFixedScale()
      this.updateTrainingDataGraph6InputsFixedScale()
    },
    slide7InputsTrainingData(value1, value2, value3){
      this.fifthInputTrainingDataToDisplay = value1
      this.sixthInputTrainingDataToDisplay = value2
      this.seventhInputTrainingDataToDisplay = value3
      this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
      this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
      this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining]==this.sixthInputTrainingDataToDisplay)
      this.filteredTrainingData4 = [...this.filteredTrainingData3].filter(item => item[this.colSevenTraining]==this.seventhInputTrainingDataToDisplay)
      

      this.xxx = this.getCol(this.filteredTrainingData4,this.colOneTraining)
      this.yyy = this.getCol(this.filteredTrainingData4,this.colTwoTraining)
      this.zzz = this.getCol(this.filteredTrainingData4,this.colThreeTraining)
      this.ccc = this.getCol(this.filteredTrainingData4,7)

      this.plot7InputTrainingData()
      this.updateTrainingData7Inputs()
      this.plot7InputTrainingDataFixedScale()
      this.updateTrainingData7InputsFixedScale()
    },
    slide8InputsTrainingData(){
      this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
      this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
      this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining]==this.sixthInputTrainingDataToDisplay)
      this.filteredTrainingData4 = [...this.filteredTrainingData3].filter(item => item[this.colSevenTraining]==this.seventhInputTrainingDataToDisplay)
      this.filteredTrainingData5 = [...this.filteredTrainingData4].filter(item => item[this.colEightTraining]==this.eightInputTrainingDataToDisplay)


      this.xxx = this.getCol(this.filteredTrainingData5,this.colOneTraining)
      this.yyy = this.getCol(this.filteredTrainingData5,this.colTwoTraining)
      this.zzz = this.getCol(this.filteredTrainingData5,this.colThreeTraining)
      this.ccc = this.getCol(this.filteredTrainingData5,8)

      this.plot8InputTrainingData()
      this.updateTrainingData8Inputs()
      this.plot8InputTrainingDataFixedScale()
      this.updateTrainingData8InputsFixedScale()

    },
    plot5InputTrainingData(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol([...this.inputDataToPlot],5)),
                      cmax:Math.max(...this.getCol([...this.inputDataToPlot],5)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingData",data, layout)
        if(this.filteredTrainingData2.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
      
    },
    plot6InputTrainingData(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol([...this.inputDataToPlot],6)),
                      cmax:Math.max(...this.getCol([...this.inputDataToPlot],6)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []

      for(let i = 0; i<this.sixthInputTrainingData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSixTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingData",data, layout)
        if(this.filteredTrainingData3.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
    },
    plot7InputTrainingData(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol([...this.inputDataToPlot],7)),
                      cmax:Math.max(...this.getCol([...this.inputDataToPlot],7)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      for(let i = 0; i< this.seventhInputTrainingData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.seventhInputTrainingData[i]
        })
      }
      for(let i = 0; i<this.sixthInputTrainingData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSixTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSevenTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingData",data, layout)
        if(this.filteredTrainingData4.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
      
    },
    plot8InputTrainingData(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol([...this.inputDataToPlot],8)),
                      cmax:Math.max(...this.getCol([...this.inputDataToPlot],8)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      var slidersSteps5 = []
      for(let i = 0; i< this.eightInputTrainingData.length;i++){
        slidersSteps5.push({
          label:this.eightInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.eightInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.seventhInputTrainingData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.seventhInputTrainingData[i]
        })
      }
      for(let i = 0; i<this.sixthInputTrainingData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:850,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSixTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSevenTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          },
                          {
                            pad:{
                              t:280
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colEightTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps5,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingData",data, layout)
        if(this.filteredTrainingData5.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
    },
    updateTrainingDataGraph5Inputs(){
      var trainingDataGraph = document.getElementById("trainingData")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.xxx  = this.getCol(this.filteredTrainingData2,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData2,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData2,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData2,5)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingData", frame)
        if(this.filteredTrainingData2.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
     
    },
    updateTrainingDataGraph6Inputs(){
      var trainingDataGraph = document.getElementById("trainingData")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index==2){
          this.sixthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining] ==this.sixthInputTrainingDataToDisplay)

        this.xxx  = this.getCol(this.filteredTrainingData3,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData3,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData3,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData3,6)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingData", frame)
        if(this.filteredTrainingData3.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
     
    },
    updateTrainingData7Inputs(){
      var trainingDataGraph = document.getElementById("trainingData")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index==2){
          this.sixthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index== 3){
          this.seventhInputTrainingDataToDisplay=this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining] ==this.sixthInputTrainingDataToDisplay)
        this.filteredTrainingData4 = [...this.filteredTrainingData3].filter(item => item[this.colSevenTraining] ==this.seventhInputTrainingDataToDisplay)


        this.xxx  = this.getCol(this.filteredTrainingData4,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData4,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData4,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData4,7)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingData", frame)
        if(this.filteredTrainingData4.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
    },
    updateTrainingData8Inputs(){
      var trainingDataGraph = document.getElementById("trainingData")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index==2){
          this.sixthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index== 3){
          this.seventhInputTrainingDataToDisplay=this.vall
        }
        else if(datasent.slider._index==4){
          this.eightInputTrainingDataToDisplay = this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining] ==this.sixthInputTrainingDataToDisplay)
        this.filteredTrainingData4 = [...this.filteredTrainingData3].filter(item => item[this.colSevenTraining] ==this.seventhInputTrainingDataToDisplay)
        this.filteredTrainingData5 = [...this.filteredTrainingData4].filter(item => item[this.colEightTraining]==this.eightInputTrainingDataToDisplay)

        this.xxx  = this.getCol(this.filteredTrainingData5,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData5,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData5,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData5,8)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingData", frame)
        if(this.filteredTrainingData5.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
    },
    plot5InputTrainingDataFixedScale(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:this.min_y_pred,
                      cmax:this.max_y_pred,
                      
                      },
                  
                    costumdata:this.ccc,
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          }]
                                          
                      
                    
                    };
      
      
      Plotly.react("trainingDataFixed", data, layout)
      if(this.filteredTrainingData2.length == 0){
          this.noDataPointsAlertClassFixed = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessageFixed = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessageFixed = ""
          this.noDataPointsAlertClassFixed = ""
        }
    },
    updateTrainingDataGraph5InputsFixedScale(){
      var trainingDataGraph = document.getElementById("trainingDataFixed")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.xxx  = this.getCol(this.filteredTrainingData2,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData2,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData2,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData2,5)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingDataFixed", frame)
        if(this.filteredTrainingData2.length == 0){
          this.noDataPointsAlertClassFixed = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessageFixed = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessageFixed = ""
          this.noDataPointsAlertClassFixed = ""
        }

      })
    },
    plot6InputTrainingDataFixedScale(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:this.min_y_pred,
                      cmax:this.max_y_pred,
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []

      for(let i = 0; i<this.sixthInputTrainingData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSixTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingDataFixed",data, layout)
        if(this.filteredTrainingData3.length == 0){
          this.noDataPointsAlertClassFixed = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessageFixed = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessageFixed = ""
          this.noDataPointsAlertClassFixed = ""
        }
      
    },
    updateTrainingDataGraph6InputsFixedScale(){
      var trainingDataGraph = document.getElementById("trainingDataFixed")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index==2){
          this.sixthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining] ==this.sixthInputTrainingDataToDisplay)

        this.xxx  = this.getCol(this.filteredTrainingData3,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData3,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData3,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData3,6)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingDataFixed", frame)
        if(this.filteredTrainingData3.length == 0){
          this.noDataPointsAlertClassFixed = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessageFixed = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessageFixed = ""
          this.noDataPointsAlertClassFixed = ""
        }
        
        

})
      
     
    },
    plot7InputTrainingDataFixedScale(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:this.min_y_pred,
                      cmax:this.max_y_pred,
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      for(let i = 0; i< this.seventhInputTrainingData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.seventhInputTrainingData[i]
        })
      }
      for(let i = 0; i<this.sixthInputTrainingData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSixTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSevenTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingDataFixed",data, layout)
        if(this.filteredTrainingData4.length == 0){
          this.noDataPointsAlertClassFixed = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessageFixed = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessageFixed = ""
          this.noDataPointsAlertClassFixed = ""
        }
    },
    updateTrainingData7InputsFixedScale(){
      var trainingDataGraph = document.getElementById("trainingDataFixed")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index==2){
          this.sixthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index== 3){
          this.seventhInputTrainingDataToDisplay=this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining] ==this.sixthInputTrainingDataToDisplay)
        this.filteredTrainingData4 = [...this.filteredTrainingData3].filter(item => item[this.colSevenTraining] ==this.seventhInputTrainingDataToDisplay)


        this.xxx  = this.getCol(this.filteredTrainingData4,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData4,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData4,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData4,7)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingDataFixed", frame)
        if(this.filteredTrainingData4.length == 0){
          this.noDataPointsAlertClassFixed = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessageFixed = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessageFixed = ""
          this.noDataPointsAlertClassFixed = ""
        }
        
        

})
      
    },
    plot8InputTrainingDataFixedScale(){
      var trace1 = {
                    x:this.xxx, y:this.yyy, z:this.zzz,
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.ccc,
                      colorbar:{thickness:20},
                      cmin:this.min_y_pred,
                      cmax:this.max_y_pred,
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br>target : %{marker.color} <extra></extra> ",
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      var slidersSteps5 = []
      for(let i = 0; i< this.eightInputTrainingData.length;i++){
        slidersSteps5.push({
          label:this.eightInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.eightInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.seventhInputTrainingData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.seventhInputTrainingData[i]
        })
      }
      for(let i = 0; i<this.sixthInputTrainingData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fifthInputTrainingData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputTrainingData[i]
        })
      }
      for(let i = 0; i< this.fourthInputTrainingData.length;i++){
        slidersSteps.push({
          label:this.fourthInputTrainingData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputTrainingData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOneTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colOneTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colOneTraining))],
                            rangemode:"normal"},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwoTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colTwoTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colTwoTraining))]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThreeTraining],
                            range:[Math.min(...this.getCol(this.inputDataToPlot, this.colThreeTraining)),Math.max(...this.getCol(this.inputDataToPlot, this.colThreeTraining))]}
                          },
                          height:850,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFourTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFiveTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSixTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSevenTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          },
                          {
                            pad:{
                              t:280
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colEightTraining] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps5,
                            
                          }]
                                          
                      
                    
                    };
      
        Plotly.react("trainingDataFixed",data, layout)
        if(this.filteredTrainingData5.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
    },
    updateTrainingData8InputsFixedScale(){
      var trainingDataGraph = document.getElementById("trainingDataFixed")
      trainingDataGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index==2){
          this.sixthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index == 1){
          this.fifthInputTrainingDataToDisplay = this.vall
        }
        else if(datasent.slider._index== 3){
          this.seventhInputTrainingDataToDisplay=this.vall
        }
        else if(datasent.slider._index==4){
          this.eightInputTrainingDataToDisplay = this.vall
        }
        else{
          this.fourthInputTrainingDataToDisplay = this.vall
        }
        this.filteredTrainingData = [...this.inputDataToPlot].filter(item => item[this.colFourTraining]==this.fourthInputTrainingDataToDisplay)
        this.filteredTrainingData2 = [...this.filteredTrainingData].filter(item => item[this.colFiveTraining]==this.fifthInputTrainingDataToDisplay)
        this.filteredTrainingData3 = [...this.filteredTrainingData2].filter(item => item[this.colSixTraining] ==this.sixthInputTrainingDataToDisplay)
        this.filteredTrainingData4 = [...this.filteredTrainingData3].filter(item => item[this.colSevenTraining] ==this.seventhInputTrainingDataToDisplay)
        this.filteredTrainingData5 = [...this.filteredTrainingData4].filter(item => item[this.colEightTraining]==this.eightInputTrainingDataToDisplay)

        this.xxx  = this.getCol(this.filteredTrainingData5,this.colOneTraining)
        this.yyy  = this.getCol(this.filteredTrainingData5,this.colTwoTraining)
        this.zzz  = this.getCol(this.filteredTrainingData5,this.colThreeTraining)
        this.ccc  = this.getCol(this.filteredTrainingData5,8)
        var frame = {
          data:[{
            'x':this.xxx,
          'y':this.yyy,
          'z':this.zzz,
          'marker.color':this.ccc
          }]
        }
        Plotly.animate("trainingDataFixed", frame)
        if(this.filteredTrainingData5.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        
        

})
      
    },
    async managePredictionGraph4Inputs(version){
      this.annPredDataToPlot = await this.getAnnPred(version)
      this.annPredDataToPlot.map(item => item.map(Number))
      this.annPredDataToPlot.pop()
      this.annPredDataToPlot.shift()
      this.manageVariables4Inputs()
    },
    manageVariables4Inputs(){

      this.fourthInputPredictionData = Array.from(new Set(this.getCol(this.annPredDataToPlot,this.colFour)))
      this.fourthInputPredictionData.sort(function(a,b){return a-b})
      this.plot4InputsPredictionData()
      this.plot4InputsPredictionDataFixedScale()
    },
      
    
    plot4InputsPredictionData(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),this.colOne),
                    y:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),this.colTwo),
                    z:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),this.colThree),
                  
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),4),
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.annPredDataToPlot,4)),
                      cmax:Math.max(...this.getCol(this.annPredDataToPlot,4)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color}<extra></extra> ",
                    type: 'scatter3d'
                  };
      var dataa=[trace1]
      var slidersSteps = []
      for (let i =0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3).toString(),
          method:"animate",
          args:[
            [this.fourthInputPredictionData[i].toString()]
            
          ]
        })
      }
      var framess=[]
      this.annPredDataToPlotClone = [...this.annPredDataToPlot]
      for (let i =0; i< this.fourthInputPredictionData.length;i++){
        this.filteredAnnPredictionData = this.annPredDataToPlotClone.filter(item => item[this.colFour]==this.fourthInputPredictionData[i])
        
        var xx = this.getCol(this.filteredAnnPredictionData,this.colOne)
        
        var yy = this.getCol(this.filteredAnnPredictionData,this.colTwo)
        
        var zz = this.getCol(this.filteredAnnPredictionData,this.colThree)
        
        var costumdataa = this.getCol(this.filteredAnnPredictionData,4)
      
        framess.push({
          name : this.fourthInputPredictionData[i].toString(),
          data : [
            {
              'x' : xx,
              'y' : yy,
              'z' : zz,
              "marker.color" : costumdataa,
              
            }
          ]
        })
      }
      var layoutt = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:30
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 10
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                            
                          }]
                                          
                      
                    
                    };
                    Plotly.react("annPrediction",{
                      data : dataa,
                      layout : layoutt,
                      frames : framess
                      
                    })
    },
    plot4InputsPredictionDataFixedScale(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),this.colOne),
                    y:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),this.colTwo),
                    z:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),this.colThree),
                  
                    mode: 'markers',
                    
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.8,
                      colorscale:'Viridis',
                      autocolorscale:false,
                      color:this.getCol(this.annPredDataToPlot.filter(item => item[this.colFour]== this.fourthInputPredictionData[0]),4),
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.inputDataToPlot,4)),
                      cmax:Math.max(...this.getCol(this.inputDataToPlot,4)),
                      
                      },
                  
                    
                    hovertemplate:"x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color}<extra></extra> ",
                    type: 'scatter3d'
                  };
      var dataa=[trace1]
      var slidersSteps = []
      for (let i =0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3).toString(),
          method:"animate",
          args:[
            [this.fourthInputPredictionData[i].toString()]
            
          ]
        })
      }
      var framess=[]
      this.annPredDataToPlotClone = [...this.annPredDataToPlot]
      for (let i =0; i< this.fourthInputPredictionData.length;i++){
        this.filteredAnnPredictionData = this.annPredDataToPlotClone.filter(item => item[this.colFour]==this.fourthInputPredictionData[i])
        
        var xx = this.getCol(this.filteredAnnPredictionData,this.colOne)
        
        var yy = this.getCol(this.filteredAnnPredictionData,this.colTwo)
        
        var zz = this.getCol(this.filteredAnnPredictionData,this.colThree)
        
        var costumdataa = this.getCol(this.filteredAnnPredictionData,4)
      
        framess.push({
          name : this.fourthInputPredictionData[i].toString(),
          data : [
            {
              'x' : xx,
              'y' : yy,
              'z' : zz,
              "marker.color" : costumdataa,
              
            }
          ]
        })
      }
      var layoutt = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:30
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 10
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                            
                          }]
                                          
                      
                    
                    };
                    Plotly.react("annPredictionFixed",{
                      data : dataa,
                      layout : layoutt,
                      frames : framess
                      
                    })
    },
    async managePredictionGraph5Inputs(version){
      this.annPredDataToPlot = await this.getAnnPred(version)
      this.annPredDataToPlot.map(item => item.map(Number))
      this.annPredDataToPlot.pop()
      this.annPredDataToPlot.shift()
      this.manageVariables5Inputs()
    },
    manageVariables5Inputs(){
      this.fourthInputPredictionData = Array.from(new Set(this.getCol(this.annPredDataToPlot,this.colFour)))
      this.fourthInputPredictionData.sort(function(a,b){return a-b})
      this.fourthInputPredictionDataToDisplay = this.fourthInputPredictionData[0]

      this.fifthInputPredictionData = Array.from(new Set(this.getCol(this.annPredDataToPlot,this.colFive)))
      this.fifthInputPredictionData.sort(function(a,b){return a-b})
      this.fifthInputPredictionDataToDisplay= this.fifthInputPredictionData[0]

      this.slide5InputsPredictionData(this.fifthInputPredictionDataToDisplay,0)

    },
    async managePredictionGraph6Inputs(version){
      this.graphLoaded= false
      let slider_one_value = 0
      let slider_two_value = 0
      let slider_three_value = 0
      let columns = [this.colOne,this.colTwo, this.colThree, this.colFour, this.colFive, this.colSix]
      this.annPredDataToPlot = await this.query_data_6_inputs(slider_one_value,slider_two_value,slider_three_value,columns,version)
      let value_list = []
      let arr = []
      for (let i=0;i<parseInt(this.dataneeded.number_of_inputs[0]);i++){
        arr = []
        var step = (this.dataneeded.input_max_lim_list[version][i] - this.dataneeded.input_min_lim_list[version][i])/(this.dataneeded.layers[version] -1)
        
        for(let j=0;j<this.dataneeded.layers[version];j++){
          arr.push(this.dataneeded.input_min_lim_list[version][i] + (step*j))
        }
        value_list.push(arr)
      }
      this.fourthInputPredictionData = value_list[this.colFour]
      this.fifthInputPredictionData = value_list[this.colFive]
      this.sixthInputPredictionData = value_list[this.colSix]
      this.fourthInputPredictionDataToDisplay = 0
      this.fifthInputPredictionDataToDisplay = 0
      this.sixthInputPredictionDataToDisplay = 0
      this.plot6InputPredictionData()
      await this.updatePredictionDataGraph6Inputs()
      this.plot6InputPredictionDataFixedScale()
      await this.updatePredictionDataGraph6InputsFixedScale()

    },
    
    async managePredictionGraph7Inputs(version){
      this.graphLoaded= false
      let slider_one_value = 0
      let slider_two_value = 0
      let slider_three_value = 0
      let slider_four_value = 0
      let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven]
      this.annPredDataToPlot = await this.query_data_7_inputs(slider_one_value,slider_two_value,slider_three_value,slider_four_value,columns,version)
      let value_list = []
      let arr = []
      for (let i=0;i<parseInt(this.dataneeded.number_of_inputs[0]);i++){
        arr = []
        var step = (this.dataneeded.input_max_lim_list[version][i] - this.dataneeded.input_min_lim_list[version][i])/(this.dataneeded.layers[version] -1)
        
        for(let j=0;j<this.dataneeded.layers[version];j++){
          arr.push(this.dataneeded.input_min_lim_list[version][i] + (step*j))
        }
        value_list.push(arr)
      }
      
      this.fourthInputPredictionData = value_list[this.colFour]
      this.fifthInputPredictionData = value_list[this.colFive]
      this.sixthInputPredictionData = value_list[this.colSix]
      this.seventhInputPredictionData = value_list[this.colSeven]
      this.fourthInputPredictionDataToDisplay = 0
      this.fifthInputPredictionDataToDisplay = 0
      this.sixthInputPredictionDataToDisplay = 0
      this.seventhInputPredictionDataToDisplay = 0
      this.plot7InputPredictionData()
      await this.updatePredictionDataGraph7Inputs()
      this.plot7InputPredictionDataFixedScale()
      await this.updatePredictionDataGraph7InputsFixedScale()
    },
    async managePredictionGraph8Inputs(version){
      this.graphLoaded= false
      let slider_one_value = 0
      let slider_two_value = 0
      let slider_three_value = 0
      let slider_four_value = 0
      let slider_five_value = 0
      let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven, this.colEight]
      this.annPredDataToPlot = await this.query_data_8_inputs(slider_one_value,slider_two_value,slider_three_value,slider_four_value,slider_five_value,columns,version)
      let value_list = []
      let arr = []
      for (let i=0;i<parseInt(this.dataneeded.number_of_inputs[0]);i++){
        arr = []
        var step = (this.dataneeded.input_max_lim_list[version][i] - this.dataneeded.input_min_lim_list[version][i])/(this.dataneeded.layers[version] -1)
        
        for(let j=0;j<this.dataneeded.layers[version];j++){
          arr.push(this.dataneeded.input_min_lim_list[version][i] + (step*j))
        }
        value_list.push(arr)
      }
      this.fourthInputPredictionData = value_list[this.colFour]
      this.fifthInputPredictionData = value_list[this.colFive]
      this.sixthInputPredictionData = value_list[this.colSix]
      this.seventhInputPredictionData = value_list[this.colSeven]
      this.eightInputPredictionData = value_list[this.colEight]
      this.fourthInputPredictionDataToDisplay = 0
      this.fifthInputPredictionDataToDisplay = 0
      this.sixthInputPredictionDataToDisplay = 0
      this.seventhInputPredictionDataToDisplay = 0
      this.eightInputPredictionDataToDisplay = 0
      this.plot8InputPredictionData()
      this.updatePredictionDataGraph8Inputs()
      this.plot8InputPredictionDataFixedScale()
      this.updatePredictionDataGraph8InputsFixedScale()
    },
    async query_data_6_inputs(slider_one_value, slider_two_value,slider_three_value,columns ,version){
      const headers={
        Authorization:this.authHeader,
        "Content-Type":"application/json"
      }
      const reqBody={
        "number_of_inputs":this.dataneeded.number_of_inputs[0],
        'sortKey':this.dataneeded.sortkeys[version],
        "slider_one_value":slider_one_value,
        "slider_two_value":slider_two_value,
        "slider_three_value":slider_three_value,
        "input_min_lim_list":this.dataneeded.input_min_lim_list[version],
        "input_max_lim_list":this.dataneeded.input_max_lim_list[version],
        "layers":this.dataneeded.layers[version],
        "columns":columns
        
      }
      const response = await fetch(
          `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/ann_data`,
          { method:"POST",
              headers,
              body:JSON.stringify(reqBody)}
      )  
      const csv2 = await response.json()
      
      
      let dat = csv2.query_array.split("\n")
      this.graphLoaded = true
      return dat.map(item => item.split(",").map(Number))
    },
    async query_data_7_inputs(slider_one_value, slider_two_value,slider_three_value,slider_four_value ,columns,version){
      const headers={
        Authorization:this.authHeader,
        "Content-Type":"application/json"
      }
      const reqBody={
        "number_of_inputs":this.dataneeded.number_of_inputs[0],
        'sortKey':this.dataneeded.sortkeys[version],
        "slider_one_value":slider_one_value,
        "slider_two_value":slider_two_value,
        "slider_three_value":slider_three_value,
        "slider_four_value":slider_four_value,
        "input_min_lim_list":this.dataneeded.input_min_lim_list[version],
        "input_max_lim_list":this.dataneeded.input_max_lim_list[version],
        "layers":this.dataneeded.layers[version],
        "columns":columns
      }
      const response = await fetch(
          `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/ann_data`,
          { method:"POST",
              headers,
              body:JSON.stringify(reqBody)}
      )  
      const csv2 = await response.json()
      let dat = csv2.query_array.split("\n")
      this.graphLoaded = true
      return dat.map(item => item.split(",").map(Number))
    },
    async query_data_8_inputs(slider_one_value, slider_two_value,slider_three_value,slider_four_value,slider_five_value ,columns,version){
      const headers={
        Authorization:this.authHeader,
        "Content-Type":"application/json"
      }
      const reqBody={
        "number_of_inputs":this.dataneeded.number_of_inputs[0],
        'sortKey':this.dataneeded.sortkeys[version],
        "slider_one_value":slider_one_value,
        "slider_two_value":slider_two_value,
        "slider_three_value":slider_three_value,
        "slider_four_value":slider_four_value,
        "slider_five_value":slider_five_value,
        "input_min_lim_list":this.dataneeded.input_min_lim_list[version],
        "input_max_lim_list":this.dataneeded.input_max_lim_list[version],
        "layers":this.dataneeded.layers[version],
        "columns":columns
      }
      const response = await fetch(
          `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/ann_data`,
          { method:"POST",
              headers,
              body:JSON.stringify(reqBody)}
      )  
      const csv2 = await response.json()
      let dat = csv2.query_array.split("\n")
      this.graphLoaded = true
      return dat.map(item => item.split(",").map(Number))
    },
    
    
    slide5InputsPredictionData(value){
      this.fifthInputPredictionDataToDisplay = value
      this.filteredAnnPredictionData = this.annPredDataToPlot.filter(item => item[this.colFour]==this.fourthInputPredictionDataToDisplay)
      this.filteredAnnPredictionData2 = this.filteredAnnPredictionData.filter(item => item[this.colFive]==this.fifthInputPredictionDataToDisplay)
      this.plot5InputPredictionData(this.getCol(this.filteredAnnPredictionData2,this.colOne), this.getCol(this.filteredAnnPredictionData2,this.colTwo), this.getCol(this.filteredAnnPredictionData2,this.colThree), this.getCol(this.filteredAnnPredictionData2,5))
      this.plot5InputPredictionDataFixedScale(this.getCol(this.filteredAnnPredictionData2,this.colOne), this.getCol(this.filteredAnnPredictionData2,this.colTwo), this.getCol(this.filteredAnnPredictionData2,this.colThree), this.getCol(this.filteredAnnPredictionData2,5))
      this.updatePredictionDataGraph()
      this.updatePredictionDataFixedScaleGraph()

    },
    plot5InputPredictionData(x,y,z,target){
      var trace1 = {
                    x:x, y:y, z:z,
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:target,
                      
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.annPredDataToPlot,5)),
                      cmax: Math.max(...this.getCol(this.annPredDataToPlot,5)),
                      },
                  
                    costumdata:target,
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:this.fifthInputPredictionData[i]
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:this.fourthInputPredictionData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:700,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPrediction", data, layout)
    },
    updatePredictionDataGraph(){
      var predictionGraph = document.getElementById("annPrediction")
      predictionGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else{
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        this.filteredAnnPredictionData = [...this.annPredDataToPlot].filter(item => item[this.colFour]==this.fourthInputPredictionDataToDisplay)
        this.filteredAnnPredictionData2 = [...this.filteredAnnPredictionData].filter(item => item[this.colFive]==this.fifthInputPredictionDataToDisplay)
        var xx  = this.getCol(this.filteredAnnPredictionData2,this.colOne)
        var yy  = this.getCol(this.filteredAnnPredictionData2,this.colTwo)
        var zz  = this.getCol(this.filteredAnnPredictionData2,this.colThree)
        var cc  = this.getCol(this.filteredAnnPredictionData2,5)
        var frame = {
          data:[{
            'x':xx,
          'y':yy,
          'z':zz,
          'marker.color':cc
          }]
        }
        Plotly.animate("annPrediction", frame)
      })
    },
    plot5InputPredictionDataFixedScale(x,y,z,target){
      var trace1 = {
                    x:x, y:y, z:z,
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:target,
                      
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.inputDataToPlot,5)),
                      cmax: Math.max(...this.getCol(this.inputDataToPlot,5)),
                      },
                  
                    costumdata:target,
                    hoverdata:target,
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra>',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:this.fifthInputPredictionData[i]
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:this.fourthInputPredictionData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:700,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPredictionFixed", data, layout)
    },
    updatePredictionDataFixedScaleGraph(){
      var predictionGraph = document.getElementById("annPredictionFixed")
      predictionGraph.on('plotly_sliderchange',(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else{
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        this.filteredAnnPredictionData = [...this.annPredDataToPlot].filter(item => item[this.colFour]==this.fourthInputPredictionDataToDisplay)
        this.filteredAnnPredictionData2 = [...this.filteredAnnPredictionData].filter(item => item[this.colFive]==this.fifthInputPredictionDataToDisplay)
        var xx  = this.getCol(this.filteredAnnPredictionData2,this.colOne)
        var yy  = this.getCol(this.filteredAnnPredictionData2,this.colTwo)
        var zz  = this.getCol(this.filteredAnnPredictionData2,this.colThree)
        var cc  = this.getCol(this.filteredAnnPredictionData2,5)
        var frame = {
          data:[{
            'x':xx,
          'y':yy,
          'z':zz,
          'marker.color':cc
          }]
        }
        Plotly.animate("annPredictionFixed", frame)
      })
    
    },
    plot6InputPredictionData(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot,this.colOne), y:this.getCol(this.annPredDataToPlot,this.colTwo), z:this.getCol(this.annPredDataToPlot,this.colThree),
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:this.getCol(this.annPredDataToPlot,6),
                      
                      colorbar:{thickness:20},
                      cmin:this.dataneeded.min_y_pred[this.ann_prediction_version],
                      cmax: this.dataneeded.max_y_pred[this.ann_prediction_version],
                      },
                  
                    costumdata:this.getCol(this.annPredDataToPlot,6),
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      for(let i = 0; i< this.sixthInputPredictionData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colOne]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colOne])]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colTwo]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colTwo])]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colThree]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colThree])]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPrediction", data, layout)
    },
    async updatePredictionDataGraph6Inputs(){
      let graph = document.getElementById("annPrediction")
      graph.on('plotly_sliderchange',async(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==2){
          this.sixthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==0){
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        let columns = [this.colOne,this.colTwo, this.colThree, this.colFour, this.colFive, this.colSix]

        this.annPredDataToPlot = await this.query_data_6_inputs(this.fourthInputPredictionDataToDisplay,this.fifthInputPredictionDataToDisplay, this.sixthInputPredictionDataToDisplay,columns, this.ann_prediction_version)
        var xx = this.getCol(this.annPredDataToPlot,this.colOne)
        var yy = this.getCol(this.annPredDataToPlot,this.colTwo)
        var zz =this.getCol(this.annPredDataToPlot,this.colThree)
        var cc = this.getCol(this.annPredDataToPlot,6)
        var frame = {
          data:[{
            'x':xx,
          'y':yy,

          'z':zz,
          'marker.color':cc,
          
          }]
        }
        Plotly.animate("annPrediction", frame)
      })
    
    },
    plot6InputPredictionDataFixedScale(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot,this.colOne), y:this.getCol(this.annPredDataToPlot,this.colTwo), z:this.getCol(this.annPredDataToPlot,this.colThree),
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:this.getCol(this.annPredDataToPlot,6),
                      
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.dataneeded.data_array[this.ann_prediction_version],6)),
                      cmax: Math.max(...this.getCol(this.dataneeded.data_array[this.ann_prediction_version],6)),
                      },
                  
                    costumdata:this.getCol(this.annPredDataToPlot,6),
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      for(let i = 0; i< this.sixthInputPredictionData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colOne]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colOne])]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colTwo]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colTwo])]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colThree]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colThree])]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPredictionFixed", data, layout)
    },
    async updatePredictionDataGraph6InputsFixedScale(){
      var graphFixed = document.getElementById("annPredictionFixed")
      graphFixed.on('plotly_sliderchange',async(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==2){
          this.sixthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==0){
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven]

        this.annPredDataToPlot = await this.query_data_6_inputs(this.fourthInputPredictionDataToDisplay,this.fifthInputPredictionDataToDisplay, this.sixthInputPredictionDataToDisplay,columns, this.ann_prediction_version)
        
        var xx = this.getCol(this.annPredDataToPlot,this.colOne)
        var yy = this.getCol(this.annPredDataToPlot,this.colTwo)
        var zz =this.getCol(this.annPredDataToPlot,this.colThree)
        var cc = this.getCol(this.annPredDataToPlot,6)
        
        var frame = {
          data:[{
            'x':xx,
          'y':yy,

          'z':zz,
          'marker.color':cc,
          
          }]
        }
        Plotly.animate("annPredictionFixed", frame)
      })
    
    },
    plot7InputPredictionData(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot,this.colOne), y:this.getCol(this.annPredDataToPlot,this.colTwo), z:this.getCol(this.annPredDataToPlot,this.colThree),
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:this.getCol(this.annPredDataToPlot,7),
                      
                      colorbar:{thickness:20},
                      cmin:this.dataneeded.min_y_pred[this.ann_prediction_version],
                      cmax: this.dataneeded.max_y_pred[this.ann_prediction_version],
                      },
                  
                    costumdata:this.getCol(this.annPredDataToPlot,7),
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      for(let i = 0; i< this.seventhInputPredictionData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.sixthInputPredictionData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colOne]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colOne])]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colTwo]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colTwo])]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colThree]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colThree])]}
                          },
                          height:700,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPrediction", data, layout)
    },
    async updatePredictionDataGraph7Inputs(){
      
      let graph = document.getElementById("annPrediction")
      graph.on('plotly_sliderchange',async(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==2){
          this.sixthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==0){
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        else if (datasent.slider._index==3){
          this.seventhInputPredictionDataToDisplay = this.vall
        }
        let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven]

        this.annPredDataToPlot = await this.query_data_7_inputs(this.fourthInputPredictionDataToDisplay,this.fifthInputPredictionDataToDisplay, this.sixthInputPredictionDataToDisplay,this.seventhInputPredictionDataToDisplay,columns, this.ann_prediction_version)
        var xx = this.getCol(this.annPredDataToPlot,this.colOne)
        var yy = this.getCol(this.annPredDataToPlot,this.colTwo)
        var zz =this.getCol(this.annPredDataToPlot,this.colThree)
        var cc = this.getCol(this.annPredDataToPlot,7)
        
        var frame = {
          data:[{
            'x':xx,
          'y':yy,

          'z':zz,
          'marker.color':cc,
          
          }]
        }
        Plotly.animate("annPrediction", frame)
      })
    
    },
    plot8InputPredictionData(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot,this.colOne), y:this.getCol(this.annPredDataToPlot,this.colTwo), z:this.getCol(this.annPredDataToPlot,this.colThree),
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:this.getCol(this.annPredDataToPlot,8),
                      
                      colorbar:{thickness:20},
                      cmin:this.dataneeded.min_y_pred[this.ann_prediction_version],
                      cmax: this.dataneeded.max_y_pred[this.ann_prediction_version],
                      },
                  
                    costumdata:this.getCol(this.annPredDataToPlot,8),
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      var slidersSteps5 = []
      for(let i = 0; i< this.eightInputPredictionData.length;i++){
        slidersSteps5.push({
          label:this.eightInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.seventhInputPredictionData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.sixthInputPredictionData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colOne]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colOne])]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colTwo]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colTwo])]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colThree]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colThree])]}
                          },
                          height:800,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          },
                          {
                            pad:{
                              t:280
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colEight] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps5,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPrediction", data, layout)
    },
    async updatePredictionDataGraph8Inputs(){
      let graph = document.getElementById("annPrediction")
      graph.on('plotly_sliderchange',async(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==2){
          this.sixthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==0){
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        else if (datasent.slider._index==3){
          this.seventhInputPredictionDataToDisplay = this.vall
        }
        else if (datasent.slider._index==4){
          this.eightInputPredictionDataToDisplay = this.vall
        }
        let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven, this.colEight]
        this.graphLoaded= false
        this.annPredDataToPlot = await this.query_data_8_inputs(this.fourthInputPredictionDataToDisplay,this.fifthInputPredictionDataToDisplay, this.sixthInputPredictionDataToDisplay,this.seventhInputPredictionDataToDisplay,this.eightInputPredictionDataToDisplay,columns, this.ann_prediction_version)
        var xx = this.getCol(this.annPredDataToPlot,this.colOne)
        var yy = this.getCol(this.annPredDataToPlot,this.colTwo)
        var zz =this.getCol(this.annPredDataToPlot,this.colThree)
        var cc = this.getCol(this.annPredDataToPlot,8)
        
        var frame = {
          data:[{
            'x':xx,
          'y':yy,

          'z':zz,
          'marker.color':cc,
          
          }]
        }
        Plotly.animate("annPrediction", frame)
      })
    
    },
    plot7InputPredictionDataFixedScale(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot,this.colOne), y:this.getCol(this.annPredDataToPlot,this.colTwo), z:this.getCol(this.annPredDataToPlot,this.colThree),
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:this.getCol(this.annPredDataToPlot,7),
                      
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.dataneeded.data_array[this.ann_prediction_version],7)),
                      cmax:Math.max(...this.getCol(this.dataneeded.data_array[this.ann_prediction_version],7)),
                      },
                  
                    costumdata:this.getCol(this.annPredDataToPlot,7),
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      for(let i = 0; i< this.seventhInputPredictionData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.sixthInputPredictionData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colOne]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colOne])]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colTwo]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colTwo])]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colThree]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colThree])]}
                          },
                          height:700,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPredictionFixed", data, layout)
    },
    async updatePredictionDataGraph7InputsFixedScale(){
      var graphFixed = document.getElementById("annPredictionFixed")
      graphFixed.on('plotly_sliderchange',async(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==2){
          this.sixthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==0){
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        else if (datasent.slider._index==3){
          this.seventhInputPredictionDataToDisplay = this.vall
        }
        let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven]

        this.annPredDataToPlot = await this.query_data_7_inputs(this.fourthInputPredictionDataToDisplay,this.fifthInputPredictionDataToDisplay, this.sixthInputPredictionDataToDisplay,this.seventhInputPredictionDataToDisplay, columns,this.ann_prediction_version)
        var xx = this.getCol(this.annPredDataToPlot,this.colOne)
        var yy = this.getCol(this.annPredDataToPlot,this.colTwo)
        var zz =this.getCol(this.annPredDataToPlot,this.colThree)
        var cc = this.getCol(this.annPredDataToPlot,7)
        var frame = {
          data:[{
            'x':xx,
          'y':yy,

          'z':zz,
          'marker.color':cc,
          
          }]
        }
        Plotly.animate("annPredictionFixed", frame)
      })
    
    },
    plot8InputPredictionDataFixedScale(){
      var trace1 = {
                    x:this.getCol(this.annPredDataToPlot,this.colOne), y:this.getCol(this.annPredDataToPlot,this.colTwo), z:this.getCol(this.annPredDataToPlot,this.colThree),
                    mode: 'markers',
                    marker: {
                      size: 9,
                      line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
                      opacity: 0.6,
                      colorscale:'Viridis',
                      
                      color:this.getCol(this.annPredDataToPlot,8),
                      
                      colorbar:{thickness:20},
                      cmin:Math.min(...this.getCol(this.dataneeded.data_array[this.ann_prediction_version],8)),
                      cmax:Math.max(...this.getCol(this.dataneeded.data_array[this.ann_prediction_version],8)),
                      },
                  
                    costumdata:this.getCol(this.annPredDataToPlot,8),
                    hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z}<br> target : %{marker.color} <extra></extra> ',
                    type: 'scatter3d'
                  };
      var data = [trace1]
      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      var slidersSteps5 = []
      for(let i = 0; i< this.eightInputPredictionData.length;i++){
        slidersSteps5.push({
          label:this.eightInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.seventhInputPredictionData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.sixthInputPredictionData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fifthInputPredictionData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      for(let i = 0; i< this.fourthInputPredictionData.length;i++){
        slidersSteps.push({
          label:this.fourthInputPredictionData[i].toFixed(3),
          method:"skip",
          execute:false,
          value:i
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colOne]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colOne])]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colTwo]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colTwo])]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree],
                            range:[Math.min(this.dataneeded.input_min_lim_list[this.ann_prediction_version][this.colThree]),Math.max(this.dataneeded.input_max_lim_list[this.ann_prediction_version][this.colThree])]}
                          },
                          height:800,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          },
                          {
                            pad:{
                              t:280
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colEight] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps5,
                            
                          }]
                                          
                      
                    
                    };
      
      Plotly.react("annPredictionFixed", data, layout)
    },
    async updatePredictionDataGraph8InputsFixedScale(){
      let graph = document.getElementById("annPredictionFixed")
      graph.on('plotly_sliderchange',async(datasent)=>{
        this.vall = datasent.step.value
        if(datasent.slider._index == 1){
          this.fifthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==2){
          this.sixthInputPredictionDataToDisplay = this.vall
        }
        else if(datasent.slider._index==0){
          this.fourthInputPredictionDataToDisplay = this.vall
        }
        else if (datasent.slider._index==3){
          this.seventhInputPredictionDataToDisplay = this.vall
        }
        else if (datasent.slider._index==4){
          this.eightInputPredictionDataToDisplay = this.vall
        }
        let columns = [this.colOne, this.colTwo, this.colThree, this.colFour, this.colFive,this.colSix, this.colSeven, this.colEight]
        this.graphLoaded= false
        this.annPredDataToPlot = await this.query_data_8_inputs(this.fourthInputPredictionDataToDisplay,this.fifthInputPredictionDataToDisplay, this.sixthInputPredictionDataToDisplay,this.seventhInputPredictionDataToDisplay,this.eightInputPredictionDataToDisplay,columns, this.ann_prediction_version)
        var xx = this.getCol(this.annPredDataToPlot,this.colOne)
        var yy = this.getCol(this.annPredDataToPlot,this.colTwo)
        var zz =this.getCol(this.annPredDataToPlot,this.colThree)
        var cc = this.getCol(this.annPredDataToPlot,8)
        
        var frame = {
          data:[{
            'x':xx,
          'y':yy,

          'z':zz,
          'marker.color':cc,
          
          }]
        }
        Plotly.animate("annPredictionFixed", frame)
      })
    
    },
    checkCheckboxes(){
      let selectedVariables = this.variablesToPlot.filter(item => item===true)
      if(selectedVariables.length==3){
        this.canPlot=true
      }
      else{
        this.canPlot=false
      }
    },
    checkCheckboxesTraining(){
      let selectedVariables = this.variablesToPlotTraining.filter(item => item===true)
      if(selectedVariables.length==3){
        this.canPlotTraining=true
      }
      else{
        this.canPlotTraining=false
      }
    },
    async plotNewVariables(){
      const indexesOfVariables = this.variablesToPlot.flatMap((bool,index) => bool ? index : [])
      this.colOne=indexesOfVariables[0]
      this.colTwo=indexesOfVariables[1]
      this.colThree=indexesOfVariables[2]
      
      const slideIndex = this.variablesToPlot.flatMap((bool,index) => !bool ? index : [])
      this.colFour = slideIndex[0]
      if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.colFive = slideIndex[1]
        this.manageVariables5Inputs()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.colFive = slideIndex[1]
        this.colSix = slideIndex[2]
        await this.managePredictionGraph6Inputs(this.ann_prediction_version)
        
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.colFive = slideIndex[1]
        this.colSix = slideIndex[2]
        this.colSeven = slideIndex[3]
        
        await this.managePredictionGraph7Inputs(this.ann_prediction_version)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        this.colFive = slideIndex[1]
        this.colSix = slideIndex[2]
        this.colSeven = slideIndex[3]
        this.colEight = slideIndex[4]
        await this.managePredictionGraph8Inputs(this.ann_prediction_version)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==4){

        this.manageVariables4Inputs()
      }
    },
    plotNewVariablesTraining(){
      const indexesOfVariables = this.variablesToPlotTraining.flatMap((bool,index) => bool ? index : [])
      this.colOneTraining=indexesOfVariables[0]
      this.colTwoTraining=indexesOfVariables[1]
      this.colThreeTraining=indexesOfVariables[2]
      
      const slideIndex = this.variablesToPlotTraining.flatMap((bool,index) => !bool ? index : [])
      this.colFourTraining = slideIndex[0]
      if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.colFiveTraining = slideIndex[1]
        this.manageTrainingGraph5Inputs(this.training_graph_version)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.colFiveTraining = slideIndex[1]
        this.colSixTraining = slideIndex[2]
        this.manageTrainingGraph6Inputs(this.training_graph_version)
        
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.colFiveTraining = slideIndex[1]
        this.colSixTraining = slideIndex[2]
        this.colSevenTraining = slideIndex[3]
        
         this.manageTrainingGraph7Inputs(this.training_graph_version)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        this.colFiveTraining = slideIndex[1]
        this.colSixTraining = slideIndex[2]
        this.colSevenTraining = slideIndex[3]
        this.colEightTraining = slideIndex[4]
         this.manageTrainingGraph8Inputs(this.ann_prediction_version)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==4){
        this.manageTrainingGraph4Inputs(this.training_graph_version)
      }
    },

    async updateNodes(){
      this.updateNodesData = {
        "sortkey":this.dataneeded.sortkeys[this.warnings_version],
        "sortkeys":this.dataneeded.sortkeys,
        "data_array":this.dataneeded.data_array[this.warnings_version],
        "optimisation_target":this.dataneeded.optimisation_target[0],
        "variables_type":this.dataneeded.variables_type[0],
        "opt_value":this.dataneeded.opt_value[0],
        "project_name":this.dataneeded.projectname,
        "project_description":`Increased number of neurons by : ${this.increaseNodesValue}`,
        "headers":this.dataneeded.headers[0],
        "number_of_inputs":this.dataneeded.number_of_inputs[0],
        "lim_point_closeness":this.dataneeded.lim_point_closeness[this.warnings_version],
        "input_min_lim_list":this.dataneeded.input_min_lim_list[this.warnings_version],
        "input_max_lim_list":this.dataneeded.input_max_lim_list[this.warnings_version],
        "layers":this.dataneeded.layers[this.warnings_version],
        "n_batch":this.dataneeded.n_batch[this.warnings_version],
        "alpha_value":this.dataneeded.alphav[this.warnings_version],
        "max_iter":this.dataneeded.maxit[this.warnings_version],
        "max_neurons":this.dataneeded.max_neurons[this.warnings_version],
        "neurons_opt":parseInt(this.dataneeded.neurons_opt[this.warnings_version])+ parseInt(this.increaseNodesValue),
        "update_nodes":true
      }
      this.updatedNodesLoading = false

      const headers = {
                "Content-Type": "application/json",
                Authorization: this.authHeader
            }
            
            
            const response = await fetch(
                `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/new_version_project`,
                { method:"POST",
                    headers,
                    body:JSON.stringify(this.updateNodesData)}
            )     
            await response.json()
          this.updatedNodesLoading = true

          this.$router.go("/dashboard")
            

      
    }


  },
  
  computed: {
    loaded() {
      if(parseInt(this.dataneeded.number_of_inputs)<=3){

        if (Object.entries(this.ann_fixed_graph).length > 0) {
          this.loading = true
        }
      }
      else{
        this.loading = true
      }
    },
    checkNodesValue(){
      this.v$.$touch()
      return this.v$.$error
    }
  },
  mounted() {
    this.getgraph()

  },
  async created() {
    
    

    if(parseInt(this.dataneeded.number_of_inputs)<=3){
      
      this.training_graph = this.dataneeded.trainingdatagraph
      this.training_fixed_graph =this.dataneeded.trainingdatafixedscalegraph
      
      this.ann_graph = this.dataneeded.annpredictiongraph
      
      this.ann_fixed_graph = this.dataneeded.annpredictionfixedscale
      this.user = await Auth.currentAuthenticatedUser();
      this.session = await Auth.currentSession();
      this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
    }
    else{
      this.user = await Auth.currentAuthenticatedUser();
      this.session = await Auth.currentSession();
      this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
      if(parseInt(this.dataneeded.number_of_inputs)==4){

        await this.manage4inputs(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        await this.manage5inputs(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        await this.manage6inputs(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        await this.manage7inputs(this.dataneeded.versionToShow)
        
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        await this.manage8inputs(this.dataneeded.versionToShow)
        
      }

    }
    
    
    
    
    
    
    
    

  }
}
</script>
<style scoped>
amplify-s3-image{
  --width: 100%;
  
}
.variable_impact{
  
  width: 70%;
  margin: auto;
}
/*.pred {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  row-gap: 20px;*/
/*}*/
.activebtn{
  background-color: #029676;

}
.nonactivebtn{
  background-color: white;
}
#trainingData , #trainingDataFixed, #annPrediction, #annPredictionFixed{
  
    width: 60%;
  
    height: fit-content;
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: white;
}
 .versions{
    width: 80%;
    border: grey solid 1px;
}
.versions-list{
  width: 100%;
  flex-wrap: wrap;
}
.version-item{
  width: fit-content;
  
}
.version-item h5{
  background-color: #ccc9c9;
  cursor: pointer;
}
 

.graphs{
  width: 95%;
  margin: auto;
  
  
  padding: 0rem 1rem;
}
.other-data{
  width: 100%;
  
  height: fit-content;

}
.manual-export-btns{
  width: 30%;
  height: 10rem;
}
.warnings-buttons{
  padding: 0 1rem;
}
.warnings{
  background-color: #f0f0f0;
  width: 70%;
  border-radius: 15px;
  height: fit-content;
  max-height: 15rem;
  overflow-y: auto;
}
.buttons{
  width: 80%;
  height: 70%;
}
.manual-export-btns button{
  background-color: #1054bb;
  color: white;
  font-size: 1rem;
  width: 60%;
}
.to-manual-pred{
  background-color: #1054bb;
  color: white;
  font-size: 1rem;
  width: 60%;
}
.pred{
  
  height: fit-content;
  margin: 0rem auto 1rem auto;
  
  background-color: white;
  
}
.pred-title{
  width: 50%;
  height: 10%;
  margin: 0 auto 4% auto;
  background-color: transparent;
  border-radius: 20px;
  
  
  
  
}
.warnings-title{
  width: fit-content;
  margin: 1vw auto;
  padding: 1vh 1vw;
  border-radius: 5px;
  background-color: #a30707;
  
}
.warnings-title h5{
  font-size: 1rem;
  margin: auto 0;
  
}
.warnings-title button{
  border: white 1px solid;
}
.pred-title h4{
  font-size: 1rem;
  
  background: #1515b8;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #1515bd);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1054bb, #1010c4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.7vw;
  padding-bottom: 0.6vw;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px ;
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto -1vw;
  border-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
}
.pred-title button{
  font-size: 1rem;
  margin: auto -1vw auto 0;
  padding: 0.5vw 1vw;
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
  border-radius: 10px;
}

.prev{
  background-color: rgb(229, 226, 226);
  border-end-start-radius: 20px;
  border-start-start-radius: 20px;
  height: 15vh;
  width: 10%;
  margin: auto 5vw auto 0;
  
}
.next{
  background-color: rgb(229, 226, 226);
  border-start-end-radius: 20px;
  border-end-end-radius: 20px;
  height: 15vh;
  width:10%;
  margin: auto 0 auto 5vw;
}
.blue-color{
  background: #0808ca;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #1717c4);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1054bb, #1111c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.graph-title{
  width: 92%;
  margin: 0 auto;
  padding: 0.1rem 1vw;
  background-color: white;
  border-radius: 5px;
  
}
.title{
  
  padding: 1rem;
  border-radius: 10px;
}
.graph-title h4{
  font-size: 1.6rem;
  margin: auto 0;
  letter-spacing: 2px;
  font-weight: bolder;
  border-bottom: black solid 1px;
  
}
.graph-title button{
  border: white 1px solid;
  background-color: #1054bb;
  font-size: 1.2rem;
  letter-spacing: 2px;
}
.manualprediction{
  width: 60vw;
  margin: 1vw auto 1vw auto;
  

  animation: slideleft 2s;
  

}
.manualprediction h2{
  font-size: 1.4rem;
  letter-spacing: 2px;
  padding: 0.6rem 1vw;
  margin: 0 0 1.5vw 0;
  background-color: white;
  border-radius: 5px;
  border-bottom: black solid 1px;
  width: fit-content;
}
.manual-content{
  background-color: #f0f0f0;
  border-radius: 20px;
  
}
button{
  font-size: 1rem;
}

@keyframes slideleft {
  from{
    margin-left: -20vw;
  }
  to{
    margin: 1vw auto 1vw auto;
  }
}

#trainindata, #trainingdatafixed{
  width: fit-content;
  max-width: 80%;
  margin: auto;
  
  
  border-radius: 20px;
  overflow-x: auto ;
  
}
#ann , #annfixed{
  width: fit-content;
  max-width: 80%;
  margin: auto;
  
  border-radius: 20px;
  overflow-x: auto ;
  
}

.font{
  font-size: 1rem;
}







.add_data{
  background-color: white;
  border-radius: 20px;
  width: 85%;
  margin: auto;
  padding: 1vh 1vw;
  justify-content: space-around;
  align-items: center;
}
.add_data label{
  font-size: 1rem;
}
.add{
  row-gap: 1vw;
  align-items: center;
}
.deletedata{
  align-items: center;
  row-gap: 0.5vw;
}
.deletesection{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addraw{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: auto;
}
.import{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
table{
    background-color: white;
    width: 100%;
    
}
table input{
  width: 6vw;
}
.scrolltable{
  height: fit-content;
  max-height: 60vh;
  overflow-y:scroll ;
  display: block;
  width: 80%;
  margin: auto;
  padding-top: 1vw;
}
.prediction-table{
  width: 80%;
  margin: auto;
  padding-bottom: 1vw;
}
.errormessage{
    color: red;
}
.confirmationbtn{
  width: fit-content;
  margin: auto;
}

.spinner{
  width:fit-content;
  
  margin: auto;
}
</style>
