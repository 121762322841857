<template >
    <div class="all-content">

        <header class="d-flex flex-row justify-content-between">
                <a href="/" class="nav-navbrand"><img class="img  navbar-brand" height="70"
                                                src="../assets/logo1.png"></a>
            <div class="d-flex flex-row justify-content-around align-items-center username-logout">
                <div class="z   d-flex flex-row align-items-center username">{{data.userName}} </div>
                <button class="logout btn" @click="sendToSignOut">Log out</button>
            </div>
            <a class=" offcanvas-btn btn text-white fs-1" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                <i class="bi bi-list"></i>
            </a>
        </header>
        

        <!-- <head class="d-flex flex-row justify-content-between">
            
            <button class="dashboard btn" @click="backToDashboard"><i class="bi bi-arrow-left"></i> Dashboard</button>
            
            <div class="project-title d-flex flex-row align-items-center">{{data.project_name[0]}} Results</div>
        
        </head> -->
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                

                    <div class="d-flex flex-row justify-content-around align-items-center username-logout">
                        <div class="username d-flex flex-row align-items-center">{{data.userName}} </div>
                        <button class="logout btn" @click="sendToSignOut">Log out</button>
                    </div>
                
            
                <div class="sidebar d-flex flex-column align-items-center">
                    <div class="iteration-history d-flex flex-column align-items-center justify-content-between">
                        <div class="d-flex flex-row justify-content-center align-items-center">Iteration History</div>
                        Featured Version : {{versionToShow}}
                    </div>
                   
                    <div class="number-of-variables d-flex flex-column align-items-center justify-content-center">
                        Number of Variables : {{data.number_of_inputs[0]}}
                    </div>
                    <div class="create-edit d-flex flex-column align-items-center justify-content-between pt-2">
                        <h4>Created On : {{data.created_on}}</h4>
                    
                        <p v-if="data.active_project" class="m-0 p-1">Active Project <i class="bi bi-circle-fill" style="color:green"></i></p>
                        <p v-if="!data.active_project" class="m-0 p-1">Inactive Project<i class="bi bi-circle-fill" style="color:red"></i></p>
                    
                        <div v-if="activateProjectAllowed" >
                            <button class="btn p-0" @click="showAlert2">
                                <button class="btn btn-outline-success btn-sm" :disabled="!data.activate_project_allowed" @click="activateDisactivateProject(data.sortKey[0]+'act')">Activate project</button>

                            </button>
                        </div>
                        
                        <div v-if=" data.active_project">
                                <button class="btn btn-outline-danger btn-sm" @click="activateDisactivateProject(data.sortKey[0]+'desac')">Close project</button>
                        </div>
                    </div>
                    <div :class="activeProjectAlertClass" v-html="activeProjectAlertMessage">

                    </div>
                    <div class="display-versions d-flex flex-column align-items-center mt-2">
                        <button class="display-versions-btn btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            Display Versions
                        </button>
                        <div class="versions mt-3">

                            <div class="collapse " id="collapseExample">
                                <div class="versions-list d-flex flex-column">
                                    <div class="version-item" v-for="(version, index) in data.data_array.length" :key="version" @click="changeVersion(index)">
                                        <h5>Version {{index +1 }}</h5>
                                        <p>A comment to the updates</p>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="btn w-100 p-0" @click="showAlert">
                    <button :disabled="!allowIteration" class="update-iterate btn btn-outline" @click="showsection(4)">Update & Iterate</button>
                    </button>
                    <div :class="[updateIterateAlertClass, 'alertSmall'] " v-html="updateIterateAlertMessage"></div>
                    <div class="display-versions d-flex flex-column align-items-center">
                        <button class=" btn btn-sm btn-outline-danger" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4">
                            Delete Versions
                        </button>
                        <div class="versions mt-3">

                            <div class="collapse " id="collapseExample4">
                                <div class="versions-list d-flex flex-column">
                                    <div class="version-item" v-for="(version, index) in data.sortKey" :key="version" @click="sendToDelete(version)">
                                        <h5>Version {{index +1 }}</h5>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                <button class="dashboard text-light btn mb-2" style="background:#002060" @click="backToDashboardRefresh"><i class="bi bi-arrow-left me-3"></i> Dashboard</button>

                </div>
            
            
        </div>

        <main class="d-flex flex-row">
            <div class="sidebar d-flex flex-column align-items-center">
                
                <div class="iteration-history d-flex flex-column align-items-center justify-content-between">
                    <div class="d-flex flex-row justify-content-center align-items-center">{{data.project_name[0]}} Results</div>
                    Featured Version : {{versionToShow}}
                </div>
                <div class="number-of-variables d-flex flex-column align-items-center justify-content-center">
                    Number of Variables : {{data.number_of_inputs[0]}}
                </div>
                <div class="create-edit d-flex flex-column align-items-center justify-content-between pt-2">
                    <h4>Created On : {{data.created_on}}</h4>
                    <h4 v-if="data.modified_on">Edited On : 24/08/2021 15:45</h4>
                </div>
                <p v-if="data.active_project" class="m-0 p-1">Active Project <i class="bi bi-circle-fill" style="color:green"></i></p>
                <p v-if="!data.active_project" class="m-0 p-1">Inactive Project<i class="bi bi-circle-fill" style="color:red"></i></p>
                <div v-if="activateProjectAllowed" >
                    <button class="btn p-0" @click="showAlert2">
                        <button class="btn btn-outline-success" :disabled="!data.activate_project_allowed" @click="activateDisactivateProject(data.sortKey[0]+'act')">Activate project</button>

                    </button>
                </div>
                
                <div v-if=" data.active_project">
                        <button class="btn btn-outline-danger" @click="activateDisactivateProject(data.sortKey[0]+'desac')">Close project</button>
                </div>
                <div :class="[activeProjectAlertClass]" v-html="activeProjectAlertMessage">

                </div>
                <div class="display-versions d-flex flex-column align-items-center mt-5">
                    <button class="display-versions-btn btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Display Versions
                    </button>
                    <div class="versions mt-3">

                        <div class="collapse " id="collapseExample">
                            <div class="versions-list d-flex flex-column">
                                <div class="version-item" v-for="(version, index) in data.project_description" :key="version" @click="changeVersion(index)">
                                    <h5>Version {{index +1 }}</h5>
                                    <p v-if="version.length >0">{{version}}</p>
                                    <p v-if="!version.length>0">No Description Provided</p>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>

                <button class="btn w-100 p-0" @click="showAlert">
                    <button v-if="activelicense" :disabled="!allowIteration" class="update-iterate btn btn-outline" @click="showsection(4)">Update & Iterate</button>
                </button>
                <div :class="updateIterateAlertClass" v-html="updateIterateAlertMessage"></div>
                <div class="display-versions d-flex flex-column align-items-center">
                    <button class=" btn text-light" style="background:#a30707" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4">
                        Delete Versions
                    </button>
                    <div class="versions mt-3">

                        <div class="collapse " id="collapseExample4">
                            <div class="versions-list d-flex flex-column">
                                <div class="version-item" v-for="(version, index) in data.sortKey" :key="version" @click="sendToDelete(index)">
                                    <h5>Version {{index +1 }}</h5>
                                    
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                <button class="dashboard text-light btn mb-2" style="background:#002060" @click="backToDashboard"><i class="bi bi-arrow-left me-3"></i> Dashboard</button>

            </div>
            <div class="project-details d-flex flex-column align-items-center">
            
                <ul class="top-bar d-flex flex-row justify-content-start align-items-end">
                    <li :class="[show[0] ? 'active-tab' : 'non-active-tab']" @click="showsection(0)">INPUT DATA</li>
                    <li :class="[show[1] ? 'active-tab' : 'non-active-tab']" @click="showsection(1)">PREDICTION</li>
                    <li :class="[show[2] ? 'active-tab' : 'non-active-tab']" @click="showsection(2)">{{iter}} & SUGGESTED PTS</li>
                    <li :class="[show[3] ? 'active-tab' : 'non-active-tab']" @click="showsection(3)">ERROR & FEATURE IMPORTANCE</li>
                </ul>
                <div class="components">
                    <slot>
                    <Datainput v-if="show[0]" :dataneeded="inputdata" :key="inputdata.versionToShow"/>
                    <Prediction v-if="show[1]" :dataneeded="predictiondata" :key="predictiondata.versionToShow"/>
                    <Iteration v-if="show[2]" :dataneeded="iterationdata" :key="iterationdata.versionToShow"/>
                    <Errorfi v-if="show[3]" :dataneeded="errordata" :key="errordata.versionToShow"/>
                    <UpdateForm v-if="show[4]" :projectData="data" :tableData="inputdata.data" @backToProject="backToDash2"/>

                    </slot>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import Datainput from "./ProjectPage components/Datainput.vue"
import Prediction from "./ProjectPage components/Prediction.vue"
import Iteration from "./ProjectPage components/Iteration.vue"
import Errorfi from "./ProjectPage components/Errorfi.vue"
import UpdateForm from "./ProjectPage components/UpdateForm.vue"
export default {
    name:"Project2",
    components:{
        Datainput,
        Prediction,
        Iteration,
        Errorfi,
        UpdateForm
    },
    data(){
        return{
            show:[true, false, false, false, false],
            versionToShow:this.data.data_array.length,
            predictiondata: {
                trainingdatagraph: null,
                trainingdatafixedscalegraph: null,
                annpredictionfixedscale: null,
                annpredictiongraph: null,
                warnings: [],
                versionToShow:null,
                heatmap_key:"",
                beeswarm_key:""
            },
            inputdata: {
                inputs: null,
                eff_train: null,
                data: null,
                headers: null,
                visualizationlayers: null,
                inputmaxlimit: null,
                inputminlimit: null,
                suggestedpoints: null,
                lim_point_closeness: null,
                projectname: '',
                max_neurons: null,
                alphav: null,
                maxit: null,
                versionToShow:null,
                ml_method:null
            },
            iterationdata: {
                suggestedpoints: null,
                suggestednextpointsgraph: null,
                headers: null,
                max_pred_coord: null,
                max_pred_eff: null,
                versionToShow:null
            },

            errordata: {
                errorgraph: null,
                featimpgraph: null,
                versionToShow:null,
                shapbars_key:""
            },
            projectToDeleteInitial: this.data.sortKey[0].slice(0,-1),
            projectToDelete:"",
            projectVersionsToDelete:[],
            allowIteration : false,
            activateProjectAllowed:false,
            updateIterateAlertClass:"",
            updateIterateAlertMessage:"",
            activeProjectAlertClass:"",
            activeProjectAlertMessage:"", 
            iter:""
        }
    },
    props:{
        data:{},
        graphs:{},
        activelicense:""
    },
    methods:{
        backToDashboard() {
            this.$emit('dashb', true)
        },
        backToDashboardRefresh(){
            
            this.$router.go()
        },
        showsection(ind) {
            for (var i = 0; i < this.show.length; i++) {
                this.show[i] = false
            }
            this.show[ind] = true

        },
        showsection2(ind) {
            for (var i = 0; i < this.show.length; i++) {
                this.show[i] = false
            }
            this.show[ind] = true
            this.inputdata.versionToShow = this.numberOfVersion -1

        },
        backToDash2() {
            this.$emit("refreshPage", true)
        },
        sendToDelete(version){
            this.projectToDelete = this.projectToDeleteInitial + String(version)
            this.deleteProj(this.projectToDelete)
        },
        deleteProj(index) {
            this.$emit("deleteThisProject", index)
        },
        sendToSignOut() {
            this.$emit("requestSignOut", true)
        },
        changeVersion(index){
            this.inputdata.versionToShow = index,
            this.predictiondata.versionToShow = index,
            this.iterationdata.versionToShow = index,
            this.errordata.versionToShow = index,
            this.versionToShow = index + 1
        },
        activateDisactivateProject(id){
            if(id.substring(id.length - 5) == "desac"){
                this.$emit("activateDisactivateProject", id)
                this.data.active_project=false
            }
            else{
                this.$emit("activateDisactivateProject", id)
                this.activateProjectAllowed = false

            }
            


        },
        showAlert(){
            if(!this.allowIteration){
                this.updateIterateAlertClass = "alert alert-warning text-center"

                if(!this.data.iterations_per_project_allowed){
                    this.updateIterateAlertMessage = "The maximum number of versions allowed is "+ String(this.data.sortKey.length)
                }
                else if(!this.data.overall_iterations_allowed){
                    this.updateIterateAlertMessage = "Your overall iterations are maxed out"
                }
                else if( !this.data.active_project){
                    this.updateIterateAlertMessage = "The project is inactive"
                }
            }
        },
        showAlert2(){
            if(!this.data.activate_project_allowed){
                this.activeProjectAlertClass = "alert alert-warning text-center"
                if(!this.data.overall_iterations_allowed){
                    this.activeProjectAlertMessage = "You can not activate the project, you are out of iterations"
                }
                else{
                    this.activeProjectAlertMessage = "Close other projects so you can activate this project"
                }
            }
        }
    },
    created(){
        this.allowIteration = this.data.active_project && this.data.iterations_per_project_allowed && this.data.overall_iterations_allowed
        this.activateProjectAllowed = !this.data.active_project
        this.inputdata.data = this.data.data_array
        
        this.numberOfVersion = this.inputdata.data.length
        this.inputdata.versionToShow = this.numberOfVersion - 1
        this.inputdata.headers = this.data.headers
        this.inputdata.visualizationlayers = this.data.layers
        this.inputdata.inputmaxlimit = this.data.input_max_lim_list
        this.inputdata.inputminlimit = this.data.input_min_lim_list
        this.inputdata.suggestedpoints = this.data.n_batch
        this.inputdata.lim_point_closeness = this.data.lim_point_closeness
        this.inputdata.projectname = this.data.project_name[0]
        this.inputdata.alphav = this.data.alpha_value
        this.inputdata.maxit = this.data.max_iter
        this.inputdata.max_neurons = this.data.max_neurons
        this.inputdata.ml_method = this.data.ml_method
        if(this.data.optimisation_target[0]=="max"){
            this.iter = "MAXIMA"
        }
        else if(this.data.optimisation_target[0]=="min"){
            this.iter  = "MINIMA"
        }
        else{
            this.iter = "VALUE"
        }
        try{
        this.inputdata.neurons_opt = this.data.neurons_opt
        }
        catch{
        console.log("error")
        }
        
        
        if(parseInt(this.data.number_of_inputs[0])<=3){

            this.predictiondata.trainingdatagraph = this.graphs.training_data
            this.predictiondata.trainingdatafixedscalegraph = this.graphs.training_data_fixed_scale
            this.predictiondata.annpredictionfixedscale = this.graphs.ann_prediction_fixed_scale
            this.predictiondata.annpredictiongraph = this.graphs.ann_prediction

            this.iterationdata.suggestednextpointsgraph = this.graphs.suggested_next_points

            this.errordata.errorgraph = this.graphs.normalized_prediction_error
            this.errordata.featimpgraph = this.graphs.feature_importance
            this.errordata.headers = this.data.headers

        }
        else{
            this.predictiondata.max_y_pred = this.data.max_y_pred
            this.predictiondata.min_y_pred = this.data.min_y_pred
            this.predictiondata.data_array = this.data.data_array

            this.errordata.mean_importance = this.data.mean_importance
            this.errordata.training_error_vector = this.data.training_error_vector
            this.errordata.headers = this.data.headers
            this.errordata.number_of_inputs = this.data.number_of_inputs[0]
            this.errordata.data_array = this.data.data_array

        }

        this.predictiondata.warnings = this.data.warnings
        this.predictiondata.versionToShow = this.numberOfVersion - 1
        this.predictiondata.number_of_inputs = this.data.number_of_inputs[0]
        this.predictiondata.headers = this.data.headers
        this.predictiondata.sortkeys = this.data.sortKey
        this.predictiondata.number_of_inputs = this.data.number_of_inputs
        this.predictiondata.input_min_lim_list = this.data.input_min_lim_list
        this.predictiondata.input_max_lim_list = this.data.input_max_lim_list
        this.predictiondata.layers = this.data.layers
        this.predictiondata.neurons_opt = this.data.neurons_opt
        this.predictiondata.data_array = this.data.data_array
        this.predictiondata.n_batch= this.data.n_batch
        this.predictiondata.lim_point_closeness = this.data.lim_point_closeness
        this.predictiondata.projectname = this.data.project_name[0]
        this.predictiondata.alphav = this.data.alpha_value
        this.predictiondata.maxit = this.data.max_iter
        this.predictiondata.max_neurons = this.data.max_neurons
        this.predictiondata.optimisation_target= this.data.optimisation_target
        this.predictiondata.opt_value  = this.data.opt_value
        this.predictiondata.variables_type = this.data.variables_type
        
        if(this.data.heatmap_key.length!=0){
            this.errordata.heatmap_key = this.data.heatmap_key
            this.errordata.beeswarm_key = this.data.beeswarm_key

            this.errordata.shapbars_key = this.data.shapbars_key
        }


        this.iterationdata.suggestedpoints = this.data.x_new
        this.iterationdata.headers = this.data.headers
        this.iterationdata.max_pred_coord = this.data.max_pred_coor
        this.iterationdata.max_pred_eff = this.data.max_pred_output
        this.iterationdata.number_of_inputs = this.data.number_of_inputs[0]
        this.iterationdata.versionToShow = this.numberOfVersion - 1
        this.iterationdata.inputdata = this.data.data_array
        this.iterationdata.optimisation_target = this.data.optimisation_target[0]
        this.iterationdata.opt_value = this.data.opt_value[0]

        
        this.errordata.versionToShow = this.numberOfVersion - 1
        this.errordata.numberOfVersions = this.data.number_of_inputs.length
        this.errordata.number_of_inputs = this.data.number_of_inputs[0]

        this.errordata.variable_importance = this.data.variable_importance
        this.errordata.r2 = this.data.r2
        this.errordata.rmse = this.data.rmse
        this.errordata.mae = this.data.mae
        this.errordata.mape = this.data.mape
        this.errordata.data_array = this.data.data_array
        this.errordata.y_pred = this.data.y_pred
        this.errordata.shap_values = this.data.shap_values
        


        
    }
}
</script>
<style scoped>
.offcanvas{
    width: fit-content;
}
.offcanvas-btn{
    display: none;
}
@media screen and (max-width:1200px) {
    .offcanvas-btn{
        display: inline;
    }
    main .sidebar{
        visibility: collapse;
    }
    main{
        width: 100vw;
        

    }
    main .project-details{
        
        width: 100%;
        
    }
    .username-logout{
        background-color: #002060;
        padding: 0.2rem 0;
    }
    header .username-logout{
        display: none;
    }
    header .username{
        visibility: hidden;
    }
    header .logout{
        display: none;
    }
    .username{
        color: white;
        letter-spacing: 2px;
        font-size: 1rem;
        height: 50%;
        width: fit-content;
        max-width: 70%;
    }
    .logout{
        letter-spacing: 2px;
        font-size: 0.5rem;
        color: white;
        background-color: rgba(0, 0, 0, 0.349);
        border: none;
        margin-right: 1rem;
        border-radius: 5px;
    }
    .sidebar{
    width: 15rem;
    height: auto;
    min-height: 100vh ;
    padding: 0 0.1rem;
    background-color: #F0F0F0;
    overflow-y: auto;
    border-right: rgb(228, 227, 227) solid 1px;
    }
    p{
        font-size: .8rem;
    }
    button{
        font-size: 0.6rem;
    }
    .iteration-history{
        background-color: #001641;
        border-radius: 5px;
        width: 90%;
        
        min-height: 3rem;
        height: fit-content;
        font-size: 0.8rem;
        color: white;
        padding-bottom: 0.5rem;
        margin-top: 1rem;
        letter-spacing: 1px;
    }
    .iteration-history div{
        height: 50%;
        background-color: white;
        width: 95%;
        padding: 0.5rem 0rem;
        margin-top: 0.5rem;
        border-radius: 5px;
        font-size: 1rem;
        letter-spacing: 3px;
        color: black;
    }
    .number-of-variables{
        background-color: white;
        width: 90%;
        height: 2rem;
        margin: 0.7rem auto ;
        padding: 0.2rem 0rem;
        border-radius: 5px;
        color: black;
        font-size: 0.8rem;
        letter-spacing: 2px;
    }
    .create-edit{
        width: 90%;
        background-color: white;
        height: fit-content;
        border-radius: 5px;
        margin-bottom: 2rem;
    }
    .create-edit h4{
        font-size: 0.8vw;
        width: 98%;
        
        text-align: center;
        border-radius: 5px;
        letter-spacing: 1px;
    }
    .versions{
        width: 90%;
    }
    .display-versions{
        
        min-width: 95%;
        width: fit-content;
        margin-bottom: 1rem;
    }
    .display-versions-btn{
        background-color: #002060;
        color: white;
        width: 80%;
        letter-spacing: 2px;
        font-size: 0.8rem;
        
    }
    .versions-list{
        background-color: white;
        border-radius: 5px;
        max-height: 10rem;
        overflow-y: auto;
        padding: 1rem 0.5rem;
        overflow-y: auto;
        row-gap: 10px;
        width: 100%;
        
    }
    .version-item{
        width: 100%;
        background-color: #F0F0F0 ;
        padding: 0.1rem 0.2rem;
        cursor: pointer;
    }
    .version-item h5{
        font-size: 0.6rem;
    }
    .version-item p{
        font-size: 0.4rem;
    }
    .update-iterate{
        background-color: white;
        border: #002060 solid 2px;
        color: #002060;
        letter-spacing: 2px;
        font-size:0.8rem ;
        
        width: 75%;
        margin-bottom: 1rem;
    }
    .update-iterate:hover{
        background-color: #002060;
        color: white;
        border: white solid 2px;
    }
    
    
}
.all-content{
    height: 100vh;
    width: 100vw;
    background-color: white;
}
main .project-details{
    width: 100rem;
    min-height: 85vh;
    height: fit-content;
    overflow-y: auto;
    background-color: white;
    
}
main .components{
    
    
    height: 86vh;
    overflow-y: auto;
    width: 100%;
}
main .top-bar{
    background-color: #F0F0F0;
    width: 100%;
    padding: 0 2rem;
    height:3rem;
    column-gap: 1rem;

}

main .top-bar li{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    
    font-size: 1rem;
    letter-spacing: 2px;
    padding: 0.5rem 1rem 0 1rem;
    text-align: center;

}
main .top-bar li:hover{
    cursor: pointer;
}
main .active-tab{
    background-color: white;
    border-top: #002060 solid 4px;
    animation: movedown 0.5s;
    animation-fill-mode: forwards;
    padding-top: 0rem;
    
}
main .non-active-tab{
    min-height: 3rem;
    
}
@keyframes movedown {
    from{
        height:3rem
    }        
    to{
        height: 2.5rem;
    }
}
main{
    width: auto;
    min-height: 100vh;
    height: auto;
    
}
main .sidebar{
    width: 27rem;
    height: auto ;
    background-color: #F0F0F0;
    overflow-y: auto;
    border-right: rgb(228, 227, 227) solid 1px;
}
main .iteration-history{
    background-color: #002A7D;
    border-radius: 5px;
    width: 90%;
    
    min-height: 2rem;
    height: fit-content;
    font-size: 1.2rem;
    color: white;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
}
main .iteration-history div{
    height: 40%;
    background-color: white;
    width: 95%;
    padding: 0.5rem 0rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    font-size: 1.5rem;
    letter-spacing: 2px;
    color: black;
}
main .number-of-variables{
    background-color: white;
    width: 90%;
    height: 3.5rem;
    margin: 1rem auto ;
    border-radius: 5px;
    color: dark;
    font-size: 1.3vw;
    letter-spacing: 2px;
}
main .create-edit{
    width: 90%;
    background-color: white;
    height: fit-content;
    border-radius: 5px;
}
main .create-edit h4{
    font-size: 0.8vw;
    width: 95%;
    text-align: center;
    padding: 0.3rem 0.5rem;
    border-radius: 10px;
}
main .versions{
    width: 90%;
}
main .display-versions{
    
    min-width: 95%;
    width: fit-content;
    margin-bottom: 4rem;
}
main .display-versions-btn{
    background-color: #002A7D;
    color: white;
    color: white;
    width: 80%;
    letter-spacing: 2px;
    font-size: 1.2rem;
}
main .versions-list{
    background-color: white;
    border-radius: 10px;
    max-height: 20rem;
    height: fit-content;
    padding: 1rem 0.5rem;
    overflow-y: auto;
    row-gap: 10px;
    
}
main .version-item{
    width: 100%;
    background-color: #F0F0F0 ;
    padding: 0.3rem 0.4rem;
    cursor: pointer;
}
main .version-item h5{
    font-size: 0.8rem;
}
main .version-item p{
    font-size: 0.6rem;
}
main .update-iterate{
    background-color: white;
    border: #002A7D solid 2px;
    color: #002A7D;
    letter-spacing: 2px;
    font-size:1.2rem ;
    margin-bottom: 1rem;
    width: 75%;
}
.update-iterate:hover{
    background-color: #002A7D;
    color: white;
    border: white solid 2px;
}
header{
    width: 100vw;
    height: auto;
    
    background-color: #002060;
}
header .username-logout{
    min-width: 13%;
    width: fit-content;
    column-gap: 10px;
}
header .username{
    color: white;
    letter-spacing: 4px;
    font-size: 1.4rem;
    height: 50%;
    width: fit-content;
    max-width: 70%;
}
header .logout{
    letter-spacing: 4px;
    font-size: 0.8rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.349);
    border: none;
    margin-right: 1rem;
    border-radius: 5px;
}

head{
    width: 100vw;
    height: fit-content;
    padding: 0.6rem 0;
    
    background-color: #F0F0F0 ;
    border-bottom: rgba(0, 0, 0, 0.473) solid 5px;
}
head .dashboard{
    height: 60%;
    margin: auto 1rem auto 1rem ;
    letter-spacing: 4px;
    font-size: 1rem;
    color: white;
    background-color: #002060;
    border: none;
}
head .project-title{
    height: 60%;
    margin: auto 1rem auto 1rem;
    letter-spacing: 4px;
    border-radius: 10px;
    background-color: white;
    padding: 0rem 2.5rem;
    font-size: 1.4rem;
}
.alertSmall{
    font-size: 0.6rem;
}

</style>