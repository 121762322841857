<template>
 <div class="fullPage">
   <div class=" d-flex  flex-row justify-content-center">
     <navbar></navbar>
   </div>

   <div v-if="authState == 'signedin'">
     <ContactformForClients :userInfo="user"/>
   </div>

  <div class="contact-form col-5" v-if="authState == 'not'">
    <Contactform/>
  </div>
 </div>

</template>

<script>
import Contactform from "../components/Contactform.vue"
import Navbar from "../components/Navbar.vue"
import ContactformForClients from "../components/ContactformForClients.vue"
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
export default {
  components:{
    Contactform,
    Navbar,
    ContactformForClients
  },
  data(){
    return{
      authState:"",
      user:'',
      userData:'',
      username:'',
      session:'',
      authHeader:'',
      userAttributesToPass:''
    }
  },
  methods:{
    async getAuthData() {
      try {
        this.userData = await Auth.currentAuthenticatedUser();
        this.username = this.userData.username;
        this.session = await Auth.currentSession();
        this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
        this.userAttributesToPass = await this.userData
        
      } catch (e) {
        console.log("error");
      }
    },
  },
  async created() {
    try{

      this.user = await Auth.currentAuthenticatedUser();
      this.authState = "signedin"
    }
    catch{
      this.authState = "not"
    }

  },
}
</script>
<style scoped>
.fullPage {
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  background-image: linear-gradient(120deg, #193c7091 0%, #002060 150%),
    url("../assets/dash2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
</style>