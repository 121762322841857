<template>

    <div class="graphs">
      
      <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
                <h4 class="text-dark"><i class="bi bi-clipboard-data"> </i> Normalized Prediction Error | Version {{normalizedPredictionErrorVersion+1}}</h4>
                <button v-if="parseInt(dataneeded.number_of_inputs[0]) >3" class="btn btn-light text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVariablesToPlot3" aria-expanded="false" aria-controls="collapseVariablesToPlot3">Change variables to plot</button>

                <div class="dropdown">
                  <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1787" data-bs-toggle="dropdown" aria-expanded="false">
                    Change Version
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1787">
                    <li v-for="(version, index) in parseInt(dataneeded.numberOfVersions)" :key="version">
                      <a class="dropdown-item" href="#err" @click="changeNormalizedPredictionErrorGraph(index)" >Version {{index+1}}</a>
                    </li>

                  </ul>
                </div>
      </div>
      <div  class="collapse" id="collapseVariablesToPlot3">
        <div class="d-flex flex-column justify-content-around align-items-center ">

          <h4 class="title">Variables to plot</h4>
          <p class="fw-2">Select three variables to plot, the rest will be in the sliders</p>
          <div class="d-flex flex-row">

            <div class="me-4" v-for="variable in parseInt(dataneeded.number_of_inputs)" :key="variable" >
              <label class="me-2">{{dataneeded.headers[0][variable-1]}}</label>
              <input type="checkbox" v-model="variablesToPlot[variable-1]" @change="checkCheckboxes()">
            </div>
          </div>
          <button class="btn btn-dark" :disabled="!canPlot" @click="plotNewVariables()">Plot</button>
        </div>
      </div>
      <div :class ="noDataPointsAlertClass" style="margin:1rem auto">{{noDataPointsAlertMessage}}</div>
      <div id="errorgraph"></div>
      <div class="d-flex justify-content-around">
          <h4>Interpretation</h4>
          <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleError" aria-expanded="false" aria-controls="collapseExampleError">
            Show
          </button>
        </div>
        <div class="collapse" id="collapseExampleError">
            <div class="card card-body">
The graph shows the normalized prediction error for the training set. Larger marker sizes describe larger prediction errors and indicate that the predicted values may be less accurate in such areas.

The prediction error has been calculated as the difference between the training and predicted values divided by the training value and normalized against the highest prediction error of the dataset.


CAUTION: The prediction error value is highly dependent on the absolute target value from the dataset uploaded, which is why normalized error values are provided.
           </div>
          </div>
      
      



      
      
        
      <hr class="mt-2 mb-3"/>
          
          
          <hr class="mt-2 mb-3"/>
          <div v-if="showPredObs" class=" graph-title d-flex flex-row justify-content-between align-items-center">
          <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Prediction VS Observed Target Values | Version {{predObsVersion+1}}</h4>
          <div class="dropdown">
            <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButtonAZE" data-bs-toggle="dropdown" aria-expanded="false">
              Change Version
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonAZE">
              <li v-for="(version, index) in dataneeded.numberOfVersions" :key="version">
                <a class="dropdown-item font" href="#" @click="changeVariableImpact(44,index)" >Version {{index+1}}</a>
              </li>

            </ul>
          </div>
        </div> 
        <div v-if="showPredObsNotAvailable && showPredObs" class="alert alert-warning" role="alert">
        No Data Available For This Version
        </div>
        <div v-if="showPredObs " id="predObs">
    </div>
    <hr  v-if="showStats" class="mt-3 mb-3"/>
        
        
        <hr  v-if="showStats" class="mt-2 mb-3"/>
        <div v-if="showStats" class=" graph-title d-flex flex-row justify-content-between align-items-center">
                <h4 class="text-dark"><i class="bi bi-clipboard-data"> </i> Statistical Summary | Version {{statsVersion +1}}</h4>

                <div class="dropdown">
                  <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1787" data-bs-toggle="dropdown" aria-expanded="false">
                    Change Version
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1787">
                    <li v-for="(version, index) in parseInt(dataneeded.numberOfVersions)" :key="version">
                      <a class="dropdown-item" href="#err" @click="changeStatsVersion(index)" >Version {{index+1}}</a>
                    </li>

                  </ul>
                </div>
      </div>
      <table v-if="showStats" class="table  stats">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Coefficient Of Determination (Regression Score) --- R²</td>
            <td>{{dataneeded.r2[statsVersion]=='not_saved' ? 'Not Available' : dataneeded.r2[statsVersion]}}</td>
          </tr>
          <tr>
            <td>Root Mean Square Error  --- RMSE</td>
            <td>{{dataneeded.rmse[statsVersion]=='not_saved' ? 'Not Available' : dataneeded.rmse[statsVersion]}}</td>
          </tr>
          <tr>
            <td>Mean Absolute Error --- MAE</td>
            <td>{{dataneeded.mae[statsVersion]=='not_saved' ? 'Not Available' : dataneeded.mae[statsVersion]}}</td>
          </tr>
          <tr>
            <td>Mean Absolute Percentage Error --- MAPE</td>
            <td>{{dataneeded.mape[statsVersion]=='not_saved' ? 'Not Available' : dataneeded.mape[statsVersion]}}</td>
          </tr>
        </tbody>
      </table>
    <div v-if="dataneeded.shapbars_key.length!=0" class="">
      <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
        <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Variable Importance Across Prediction | Version {{variable_impact_version+1}}</h4>
        <div class="dropdown">
          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Change Version
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="(version, index) in dataneeded.numberOfVersions" :key="version">
              <a class="dropdown-item font" href="#" @click="changeVariableImpact(1,index)" >Version {{index+1}}</a>
            </li>

          </ul>
        </div>
      </div> 
      <div v-if="showImages">

        <div class="variable_impact">

        <amplify-s3-image :img-key="dataneeded.beeswarm_key[variable_impact_version]" :key="dataneeded.beeswarm_key[variable_impact_version]" />   
        <div class="d-flex justify-content-around">
          <h4>Interpretation</h4>
          <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleBeeswarm" aria-expanded="false" aria-controls="collapseExampleBeeswarm">
            Show
          </button>
        </div>
        <div class="collapse" id="collapseExampleBeeswarm">
            <div class="card card-body">
  This bee swarm plot shows how variables impact predictions throughout the model. The y-axis includes the variables, ordered based on importance from high to low. The x-axis shows the impact that each variable has on predictions, and whether it is negative or positive. Dots to the right of zero lead to higher prediction values (i.e., positive effect), while dots to the left of zero lead to lower prediction values (i.e., negative effect). Each dot represents a data point (i.e., experimental training data), and the color of the dot describes whether the variable value for that data point is high or low, according to the color scale displayed.          </div>
          </div>
        </div>
        <br>
        <hr class="mt-2 mb-3"/>
        
        
        <hr class="mt-2 mb-3"/>
        <div v-if="showVariablesInteraction" class=" graph-title d-flex flex-row justify-content-between align-items-center">
                <h4 class="text-dark"><i class="bi bi-clipboard-data"> </i> Variables Interaction | Version {{variables_interaction_version+1}}</h4>
                <button  class="btn btn-light text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVariablesToPlotInter" aria-expanded="false" aria-controls="collapseVariablesToPlotInter">Change variables to plot</button>

                <div class="dropdown">
                  <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton17877" data-bs-toggle="dropdown" aria-expanded="false">
                    Change Version
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton17877">
                    <li v-for="(version, index) in parseInt(dataneeded.numberOfVersions)" :key="version">
                      <a class="dropdown-item" href="#" @click="changeVariablesInteraction(index)" >Version {{index+1}}</a>
                    </li>

                  </ul>
                </div>
      </div>
        <div  v-if="showVariablesInteraction"  class="collapse" id="collapseVariablesToPlotInter">
            <div class="d-flex flex-column align-items-center" >

                <h4 class="title align-center ">Variables to plot</h4>
            <div class="d-flex flex-row justify-content-around align-items-center ">
              <div class="d-flex flex-column justify-content-around align-items-center border-end border-dark me-4">

                <p class="fw-2">Select the first variable</p>
                <div class="d-flex flex-row">

                  <div class="me-4" v-for="variable in parseInt(dataneeded.number_of_inputs)" :key="variable" >
                    <label class="me-2">{{dataneeded.headers[0][variable-1]}}</label>
                    <input type="checkbox" v-model="variablesToPlotInteraction[variable-1]" @change="checkCheckboxesInteraction()">
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-around align-items-center ">
                
                <p class="fw-2">Select the second variable</p>
                <div class="d-flex flex-row">

                  <div class="me-4" v-for="variable in parseInt(dataneeded.number_of_inputs)" :key="variable" >
                    <label class="me-2">{{dataneeded.headers[0][variable-1]}}</label>
                    <input type="checkbox" v-model="variablesToPlotInteraction2[variable-1]" @change="checkCheckboxesInteraction()">
                  </div>
                </div>
              </div>
            </div>
              <button class=" mt-4 btn d-flex flex-row jsutify-content-center btn-dark" :disabled="!canPlotInteraction" @click="plotNewVariablesInteraction()">Plot</button>
          
            </div>
      </div>
        <div v-if="showVariablesInteraction" class="dropdown">
          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1123" data-bs-toggle="dropdown" aria-expanded="false">
            Change Version
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1123">
            <li v-for="(version, index) in dataneeded.numberOfVersions" :key="version">
              <a class="dropdown-item font" href="#" @click="changeVariablesInteraction(index)" >Version {{index+1}}</a>
            </li>

          </ul>
        </div>
      
      <div id="variablesInteraction" ></div>
      <div class="d-flex justify-content-around">
            <h4>Interpretation</h4>
            <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleHeatmap" aria-expanded="false" aria-controls="collapseExampleHeatmap">
              Show
            </button>
          </div>
          <div class="collapse " id="collapseExampleHeatmap">
              <div class="card card-body w-50" style="margin:1rem auto">
                  <img src="../../assets/interaction_example.png" alt="interaction example">
          </div>  
        </div>
        <hr class="mt-2 mb-3"/>
        
        
        <hr class="mt-2 mb-3"/>
        <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
        <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Variable Importance | Version {{variable_importance_version+1}}</h4>
        <div class="dropdown">
          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Change Version
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="(version, index) in dataneeded.numberOfVersions" :key="version">
              <a class="dropdown-item font" href="#" @click="changeVariableImpact(2,index)" >Version {{index+1}}</a>
            </li>

          </ul>
        </div>
      </div> 
        <div class="variable_impact">

        <amplify-s3-image v-if="!showVarImportance" :img-key="dataneeded.shapbars_key[variable_importance_version]" :key="dataneeded.shapbars_key[variable_importance_version]"/>   
        <div style="width:100%" id="varImportance" ></div>
        <div class="d-flex justify-content-around">
          <h4>Interpretation</h4>
          <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleShapbars" aria-expanded="false" aria-controls="collapseExampleShapbars">
            Show
          </button>
        </div>
        <div class="collapse" id="collapseExampleShapbars">
            <div class="card card-body">
  The bar plot shows how important variables are in the predictive model. Since variables can have a negative or positive impact on predictions, based on their value, variable importance is calculated by taking the mean of the absolute value of their impact. The variables are ordered from most important to least.          </div>
          </div>
        </div>
        
      
      
      </div>
        
        <hr class="mt-2 mb-3"/>
        
        
        <hr class="mt-2 mb-3"/>
        <div class="d-flex justify-content-center">

          <button class="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExampleMore" aria-expanded="false" aria-controls="collapseExampleMore">
            Advanced Feature Importance
          </button>
        </div>
        <div class="collapse" id="collapseExampleMore">
          <div class=" graph-title d-flex flex-row justify-content-between align-items-center">
        <h4 class="title text-dark" id="train"><i class="bi bi-clipboard-data"> </i> Variable Impact Heatmap | Version {{heatmap_version+1}}</h4>
        <div class="dropdown">
          <button class="btn text-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Change Version
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="(version, index) in dataneeded.numberOfVersions" :key="version">
              <a class="dropdown-item font" href="#" @click="changeVariableImpact(3,index)" >Version {{index+1}}</a>
            </li>

          </ul>
        </div>
      </div> 
          <div class="variable_impact">

          <amplify-s3-image :img-key="dataneeded.heatmap_key[heatmap_version]" :key="dataneeded.heatmap_key[heatmap_version]" />
          <div class="d-flex justify-content-around">
            <h4>Interpretation</h4>
            <button class="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExampleHeatmap" aria-expanded="false" aria-controls="collapseExampleHeatmap">
              Show
            </button>
          </div>
          <div class="collapse" id="collapseExampleHeatmap">
              <div class="card card-body">
    The heatmap shows how variables impacted predictions across the experiments. The line graph at the top approximates the output of those experiments, and the horizontal black bars to the right of the heatmap signify variable importance, with important variables having wider bars.         </div>
          </div>   
          </div>
        </div>

      </div>
      
    </div>
    <br>
    <br><br>
    <br><br>
    <br>

</template>
<script>
export default {
    name:"Errorfi", 
    props:{
        dataneeded:{}
    },
    data(){
        return{
            errorgraph:{},
            featimpgraph:{},
            normalizedPredictionErrorVersion:this.dataneeded.versionToShow,
            featureImportanceVersion:this.dataneeded.versionToShow,
            variable_importance_version:this.dataneeded.versionToShow,
            variable_impact_version:this.dataneeded.versionToShow,
            heatmap_version:this.dataneeded.versionToShow,
            errorDataToPlot:[],
            errorDataToPlotClone:[],
            fourthInputErrorData:[],
            fifthInputErrorData:[],
            sixthInputErrorData:[],
            seventhInputErrorData:[],
            eightInputErrorData:[],
            active4InputErrorData: [],
            active5InputsErrorData:[],
            fourthInputErrorDataToDisplay:"",
            fifthInputErrorDataToDisplay:"",
            sixthInputErrorDataToDisplay:"",
            seventhInputErrorDataToDisplay:"",
            eightInputErrorDataToDisplay:"",
            filteredErrorData:[],
            filteredErrorData2:[],
            filteredErrorData3:[],
            filteredErrorData4:[],
            filteredErrorData5:[],
            training_error_vector:[],
            showImages:true,
            noDataPointsAlertClass:'',
            noDataPointsAlertMessage:'',
            variablesToPlot:new Array(parseInt(this.dataneeded.number_of_inputs)).fill(false),
            colOne:0,
            colTwo:1,
            colThree:2,
            colFour:3,
            colFive:4,
            colSix:5,
            colSeven:6,
            colEight:7,
            canPlot:false,
            showVarImportance:true,
            showStats:false,
            statsVersion:this.dataneeded.versionToShow,
            showPredObs:true,
            showPredObsNotAvailable:false,
            predObsVersion:this.dataneeded.versionToShow,
            showVariablesInteraction:true,
            variables_interaction_version:this.dataneeded.versionToShow,
            variablesToPlotInteraction:new Array(parseInt(this.dataneeded.number_of_inputs)).fill(false),
            variablesToPlotInteraction2:new Array(parseInt(this.dataneeded.number_of_inputs)).fill(false),
            canPlotInteraction:false,
            colOneInteraction:0,
            colTwoInteraction:1
        }
    },
    created(){
        if(this.dataneeded.number_of_inputs<=3){

          this.errorgraph = this.dataneeded.errorgraph
          this.featimpgraph= this.dataneeded.featimpgraph
        }
        
    },
    methods:{
        getgraph(){
          if(parseInt(this.dataneeded.number_of_inputs)>3){
            let frames1 = JSON.parse(this.errorgraph[this.normalizedPredictionErrorVersion]).frames
            Plotly.newPlot("errorgraph", JSON.parse(this.errorgraph[this.normalizedPredictionErrorVersion]).data, JSON.parse(this.training_graph[this.training_graph_version]).layout).then(function(){
      
              Plotly.addFrames("errorgraph", frames1)
            })
            let frames2 =JSON.parse(this.featimpgraph[this.featureImportanceVersion]).frames
            Plotly.newPlot("featimpgraph", JSON.parse(this.featimpgraph[this.featureImportanceVersion]).data, JSON.parse(this.training_fixed_graph[this.training_graph_fixed_scale_version]).layout).then(function(){
              Plotly.addFrames("featimpgraph",frames2)
            })
    
  
          }
          else{
            Plotly.newPlot("errorgraph", JSON.parse(this.errorgraph[this.normalizedPredictionErrorVersion]).data, JSON.parse(this.errorgraph[this.normalizedPredictionErrorVersion]).layout)
            //Plotly.newPlot("featimpgraph",JSON.parse(this.featimpgraph[this.featureImportanceVersion]).data, JSON.parse(this.featimpgraph[this.featureImportanceVersion]).layout)
          }
        },
        changeNormalizedPredictionErrorGraph(index){
          this.normalizedPredictionErrorVersion = index
          if(parseInt(this.dataneeded.number_of_inputs)==4){
            this.manage4InputsErrorGraph(index)
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==5){
            this.manage5InputsErrorGraph(index)
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==6){
            this.manage6InputsErrorGraph(index)
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==7){
            this.manage7InputsErrorGraph(index)
          }
          else{

            Plotly.newPlot("errorgraph", JSON.parse(this.errorgraph[this.normalizedPredictionErrorVersion]).data, JSON.parse(this.errorgraph[this.normalizedPredictionErrorVersion]).layout)
          }

        },
        changeFeatureImportanceGraph(index){
          this.featureImportanceVersion=index
          if(parseInt(this.dataneeded.number_of_inputs)>3){

            this.plotFeatureImportanceGraph(index)
          }
          else{

            Plotly.newPlot("featimpgraph",JSON.parse(this.featimpgraph[this.featureImportanceVersion]).data, JSON.parse(this.featimpgraph[this.featureImportanceVersion]).layout)
          }

        },
        changeVariableImpact(plot,index){
          if(plot==1){

            
            this.variable_impact_version=index
            
          }
          else if(plot==2){
            this.variable_importance_version=index
            if(this.dataneeded.variable_importance[index] != 'not_saved'){
              this.showVarImportance = true
              this.manageVarImportance(this.variable_importance_version)
            }
            else{
              this.showVarImportance = false
              Plotly.purge("varImportance")
            }
          }
          else if(plot==44){
            this.predObsVersion=index
              this.managePredObs(index)
          }
          else{
            this.heatmap_version=index
          }
        },
        plotFeatureImportanceGraph(version){
          var data = [{
            x:this.dataneeded.headers[0],
            y:this.dataneeded.mean_importance[version],
            type:"bar"
          }]
          Plotly.react("featimpgraph",data)
        },
        getCol(matrix, col){
          var column = [];
          for(var i=0; i<matrix.length; i++){
              column.push(matrix[i][col]);
          }
          return column;
        },
        manage4InputsErrorGraph(version){
          this.errorDataToPlot = []
          for(var i = 0; i<this.dataneeded.data_array[version].length;i++){
            this.errorDataToPlot.push([...this.dataneeded.data_array[version][i]])
          }
          
          
          
          this.fourthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFour)))
          this.fourthInputErrorData.sort(function(a,b){return a-b})
          
          this.training_error_vector = this.dataneeded.training_error_vector[version]
          for(var i =0; i<this.errorDataToPlot.length; i++){
            this.errorDataToPlot[i].push(this.training_error_vector[i])
          }
          this.plotNormalizedPredictionError4Inputs()
        },
        manage5InputsErrorGraph(version){
          this.errorDataToPlot = []
          for(var i = 0; i<this.dataneeded.data_array[version].length;i++){
            this.errorDataToPlot.push([...this.dataneeded.data_array[version][i]])
          }
          this.fourthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFour)))
          this.fourthInputErrorData.sort(function(a,b){return a-b})
          this.fourthInputErrorDataToDisplay = this.fourthInputErrorData[0]

          this.fifthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFive)))
          this.fifthInputErrorData.sort(function(a,b){return a-b})
          this.fifthInputErrorDataToDisplay = this.fifthInputErrorData[0]
          this.training_error_vector = [...this.dataneeded.training_error_vector[version]]
          for(var i = 0; i<this.errorDataToPlot.length;i++){
            this.errorDataToPlot[i].push(this.training_error_vector[i])
          }
          this.slide5InputsErrorGraph(this.fifthInputErrorDataToDisplay)


        },
        slide4InputsErrorGraph(value, index){
          this.fourthInputErrorDataToDisplay = value
          this.active4InputFuncErrorData(index)
          if(parseInt(this.dataneeded.number_of_inputs)==4){

            this.filteredErrorData = this.errorDataToPlot.filter(item => item[3]==this.fourthInputErrorDataToDisplay)
            this.plotNormalizedPredictionError(this.getCol(this.filteredErrorData,0), this.getCol(this.filteredErrorData,1), this.getCol(this.filteredErrorData,2),this.training_error_vector)
          
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==5){
            this.slide5InputsErrorGraph(this.fifthInputErrorDataToDisplay, this.fifthInputErrorData.indexOf(this.fifthInputErrorDataToDisplay))
          }
        },
        slide5InputsErrorGraph(value){
          this.fifthInputErrorDataToDisplay = value
          this.filteredErrorData = this.errorDataToPlot.filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
          this.filteredErrorData2 = this.filteredErrorData.filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
          this.plotNormalizedPredictionError5Inputs(this.getCol(this.filteredErrorData2,this.colOne), this.getCol(this.filteredErrorData2,this.colTwo), this.getCol(this.filteredErrorData2,this.colThree),this.getCol(this.filteredErrorData2,6))
          this.updateErrorGraph()
        },
        
        plotNormalizedPredictionError4Inputs(){
          var error = this.getCol(this.errorDataToPlot.filter(item => item[this.colFour]==this.fourthInputErrorData[0]),5)
          var max  = Math.max(...error)
          
          var sizee = error.map(item => Number(item)/max*50)
          
          var trace = {
            x:this.getCol(this.errorDataToPlot.filter(item => item[this.colFour]== this.fourthInputErrorData[0]),this.colOne),
            y:this.getCol(this.errorDataToPlot.filter(item => item[this.colFour]== this.fourthInputErrorData[0]),this.colTwo),
            z:this.getCol(this.errorDataToPlot.filter(item => item[this.colFour]== this.fourthInputErrorData[0]),this.colThree),
                  
            mode:"markers",
            marker:{
              size:sizee,
              line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
              opacity: 0.8,
            },
            type:"scatter3d"
          }
          var dataa = [trace]
          var slidersSteps = []
          for (let i =0; i< this.fourthInputErrorData.length;i++){
            slidersSteps.push({
            label:this.fourthInputErrorData[i].toString(),
            method:"animate",
            args:[
              [this.fourthInputErrorData[i].toString()]
              
              ]
            })
          }
          var framess=[]
      this.errorDataToPlotClone = [...this.errorDataToPlot]
      for (let i =0; i< this.fourthInputErrorData.length;i++){
        this.filteredErrorData = this.errorDataToPlotClone.filter(item => item[this.colFour]==this.fourthInputErrorData[i])
        
        var xx = this.getCol(this.filteredErrorData,this.colOne)
        var yy = this.getCol(this.filteredErrorData,this.colTwo)
        var zz = this.getCol(this.filteredErrorData,this.colThree)
        var maxx  = Math.max(...this.getCol(this.filteredErrorData,5))
          
        var sizeee = this.getCol(this.filteredErrorData,5).map(item => Number(item)/maxx*50)
        framess.push({
          name : this.fourthInputErrorData[i].toString(),
          data : [
            {
              'x' : xx,
              'y' : yy,
              'z' : zz,
              'marker.size':sizeee
              
            }
          ]
        })
      }
      var layoutt = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:650,
                          sliders : [{
                            pad:{
                              t:30
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 10
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                            
                          }]
                                          
                      
                    
                    };
          Plotly.react("errorgraph",{
            data:dataa,
            layout: layoutt,
            frames:framess
          })
        },
        plotNormalizedPredictionError5Inputs(x,y,z,training_error_vector){
          var max  = Math.max(...training_error_vector)
          
          var sizee = training_error_vector.map(item => Number(item)/max*50)
          
          var trace = {
            x:x,
            y:y,
            z:z,
                  
            mode:"markers",
            marker:{
              size:sizee,
              line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
              opacity: 0.8,
            },
            type:"scatter3d",
            hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z} <extra></extra>',

          }
          var dataa = [trace]
          

      var slidersSteps = []
      var slidersSteps2 = []
      for(let i = 0; i< this.fifthInputErrorData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fourthInputErrorData.length;i++){
        slidersSteps.push({
          label:this.fourthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputErrorData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          }]
                                          
                      
                    
                    };
          Plotly.react("errorgraph",{
            data:dataa,
            layout: layout,
          })
          if(this.filteredErrorData2.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        },
        updateErrorGraph(){
          var errorGraph = document.getElementById("errorgraph")
          errorGraph.on("plotly_sliderchange",(datasent)=>{
            this.vall = datasent.step.value
            if(datasent.slider._index == 1){
              this.fifthInputErrorDataToDisplay = this.vall
            }
            else{
              this.fourthInputErrorDataToDisplay = this.vall
            }
            this.filteredErrorData = this.errorDataToPlot.filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
            this.filteredErrorData2 = this.filteredErrorData.filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
            var xx  = this.getCol(this.filteredErrorData2,this.colOne)
            var yy  = this.getCol(this.filteredErrorData2,this.colTwo)
            var zz  = this.getCol(this.filteredErrorData2,this.colThree)
            var max  = Math.max(...this.getCol(this.filteredErrorData2,6))
          
            var sizee = this.getCol(this.filteredErrorData2,6).map(item => Number(item)/max*50)
            var frame = {
              data:[{
                'x':xx,
              'y':yy,
              'z':zz,
              'marker.size':sizee
              }]
            }
            Plotly.animate("errorgraph", frame)
            if(this.filteredErrorData2.length == 0){
              this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
              this.noDataPointsAlertMessage = "No Corresponding Data Points"

            }
            else{
              this.noDataPointsAlertMessage = ""
              this.noDataPointsAlertClass = ""
            }
          })
        },
        manage6InputsErrorGraph(version){
          this.errorDataToPlot = []
          for(var i = 0; i<this.dataneeded.data_array[version].length;i++){
            this.errorDataToPlot.push([...this.dataneeded.data_array[version][i]])
          }
          this.fourthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFour)))
          this.fourthInputErrorData.sort(function(a,b){return a-b})
          this.fourthInputErrorDataToDisplay = this.fourthInputErrorData[0]

          this.fifthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFive)))
          this.fifthInputErrorData.sort(function(a,b){return a-b})
          this.fifthInputErrorDataToDisplay = this.fifthInputErrorData[0]

          this.sixthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colSix)))
          this.sixthInputErrorData.sort(function(a,b){return a-b})
          this.sixthInputErrorDataToDisplay = this.sixthInputErrorData[0]


          this.training_error_vector = [...this.dataneeded.training_error_vector[version]]
          for(var i = 0; i<this.errorDataToPlot.length;i++){
            this.errorDataToPlot[i].push(this.training_error_vector[i])
          }

          this.filteredErrorData = [...this.errorDataToPlot].filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
          this.filteredErrorData2 = [...this.filteredErrorData].filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
          this.filteredErrorData3 = [...this.filteredErrorData2].filter(item => item[this.colSix]==this.sixthInputErrorDataToDisplay)
          this.plotNormalizedPredictionError6Inputs()
          this.updateErrorGraph6Inputs()
        },
        manage7InputsErrorGraph(version){
          this.errorDataToPlot = []
          for(var i = 0; i<this.dataneeded.data_array[version].length;i++){
            this.errorDataToPlot.push([...this.dataneeded.data_array[version][i]])
          }
          this.fourthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFour)))
          this.fourthInputErrorData.sort(function(a,b){return a-b})
          this.fourthInputErrorDataToDisplay = this.fourthInputErrorData[0]

          this.fifthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFive)))
          this.fifthInputErrorData.sort(function(a,b){return a-b})
          this.fifthInputErrorDataToDisplay = this.fifthInputErrorData[0]

          this.sixthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colSix)))
          this.sixthInputErrorData.sort(function(a,b){return a-b})
          this.sixthInputErrorDataToDisplay = this.sixthInputErrorData[0]

          this.seventhInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colSeven)))
          this.seventhInputErrorData.sort(function(a,b){a-b})
          this.seventhInputErrorDataToDisplay= this.seventhInputErrorData[0]

          this.training_error_vector = [...this.dataneeded.training_error_vector[version]]
          for(var i = 0; i<this.errorDataToPlot.length;i++){
            this.errorDataToPlot[i].push(this.training_error_vector[i])
          }

          this.filteredErrorData = [...this.errorDataToPlot].filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
          this.filteredErrorData2 = [...this.filteredErrorData].filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
          this.filteredErrorData3 = [...this.filteredErrorData2].filter(item => item[this.colSix]==this.sixthInputErrorDataToDisplay)
          this.filteredErrorData4 = [...this.filteredErrorData3].filter(item => item[this.colSeven]==this.seventhInputErrorDataToDisplay)
          this.plotNormalizedPredictionError7Inputs()
          this.updateErrorGraph7Inputs()
        },
        manage8InputsErrorGraph(version){
          this.errorDataToPlot = []
          for(var i = 0; i<this.dataneeded.data_array[version].length;i++){
            this.errorDataToPlot.push([...this.dataneeded.data_array[version][i]])
          }
          this.fourthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFour)))
          this.fourthInputErrorData.sort(function(a,b){return a-b})
          this.fourthInputErrorDataToDisplay = this.fourthInputErrorData[0]

          this.fifthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colFive)))
          this.fifthInputErrorData.sort(function(a,b){return a-b})
          this.fifthInputErrorDataToDisplay = this.fifthInputErrorData[0]

          this.sixthInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colSix)))
          this.sixthInputErrorData.sort(function(a,b){return a-b})
          this.sixthInputErrorDataToDisplay = this.sixthInputErrorData[0]

          this.seventhInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colSeven)))
          this.seventhInputErrorData.sort(function(a,b){a-b})
          this.seventhInputErrorDataToDisplay= this.seventhInputErrorData[0]
          
          this.eightInputErrorData = Array.from(new Set(this.getCol(this.errorDataToPlot,this.colEight)))
          this.eightInputErrorData.sort(function(a,b){a-b})
          this.eightInputErrorDataToDisplay= this.eightInputErrorData[0]

          this.training_error_vector = [...this.dataneeded.training_error_vector[version]]
          for(var i = 0; i<this.errorDataToPlot.length;i++){
            this.errorDataToPlot[i].push(this.training_error_vector[i])
          }

          this.filteredErrorData = [...this.errorDataToPlot].filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
          this.filteredErrorData2 = [...this.filteredErrorData].filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
          this.filteredErrorData3 = [...this.filteredErrorData2].filter(item => item[this.colSix]==this.sixthInputErrorDataToDisplay)
          this.filteredErrorData4 = [...this.filteredErrorData3].filter(item => item[this.colSeven]==this.seventhInputErrorDataToDisplay)
          this.filteredErrorData5 = [...this.filteredErrorData4].filter(item => item[this.colEight]==this.eightInputErrorDataToDisplay)
          this.plotNormalizedPredictionError8Inputs()
          this.updateErrorGraph8Inputs()
        },
        plotNormalizedPredictionError6Inputs(){
          
          var max  = Math.max(...this.getCol(this.filteredErrorData3,7))
          var sizee = this.getCol(this.filteredErrorData3,7).map(item => Number(item)/max*50)
          
          var trace = {
            x:this.getCol(this.filteredErrorData3,this.colOne),
            y:this.getCol(this.filteredErrorData3,this.colTwo),
            z:this.getCol(this.filteredErrorData3,this.colThree),
                  
            mode:"markers",
            marker:{
              size:sizee,
              line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
              opacity: 0.8,
            },
            type:"scatter3d",
            hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z} <extra></extra>',

          }
          var dataa = [trace]
          

      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      for(let i = 0; i< this.sixthInputErrorData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fifthInputErrorData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fourthInputErrorData.length;i++){
        slidersSteps.push({
          label:this.fourthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputErrorData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          }]
                                          
                      
                    
                    };
          Plotly.react("errorgraph",{
            data:dataa,
            layout: layout,
          })
          if(this.filteredErrorData3.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        },
        plotNormalizedPredictionError7Inputs(){
          
          var max  = Math.max(...this.getCol(this.filteredErrorData4,8))
          
          var sizee = this.getCol(this.filteredErrorData4,8).map(item => Number(item)/max*50)
          
          var trace = {
            x:this.getCol(this.filteredErrorData4,this.colOne),
            y:this.getCol(this.filteredErrorData4,this.colTwo),
            z:this.getCol(this.filteredErrorData4,this.colThree),
                  
            mode:"markers",
            marker:{
              size:sizee,
              line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
              opacity: 0.8,
            },
            type:"scatter3d",
            hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z} <extra></extra>',

          }
          var dataa = [trace]
          

      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      for(let i = 0; i< this.seventhInputErrorData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.seventhInputErrorData[i]
        })
      }
      for(let i = 0; i< this.sixthInputErrorData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fifthInputErrorData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fourthInputErrorData.length;i++){
        slidersSteps.push({
          label:this.fourthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputErrorData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:750,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          }]
                                          
                      
                    
                    };
          Plotly.react("errorgraph",{
            data:dataa,
            layout: layout,
          })
          if(this.filteredErrorData4.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        },
        plotNormalizedPredictionError8Inputs(){
          
          var max  = Math.max(...this.getCol(this.filteredErrorData5,9))
          
          var sizee = this.getCol(this.filteredErrorData5,9).map(item => Number(item)/max*50)
          
          var trace = {
            x:this.getCol(this.filteredErrorData5,this.colOne),
            y:this.getCol(this.filteredErrorData5,this.colTwo),
            z:this.getCol(this.filteredErrorData5,this.colThree),
                  
            mode:"markers",
            marker:{
              size:sizee,
              line: {
                      color: 'rgba(255, 255, 255, 0)',
                      width: 0.5},
              opacity: 0.8,
            },
            type:"scatter3d",
            hovertemplate:'x:%{x}<br>y:%{y}<br>z:%{z} <extra></extra>',

          }
          var dataa = [trace]
          

      var slidersSteps = []
      var slidersSteps2 = []
      var slidersSteps3 = []
      var slidersSteps4 = []
      var slidersSteps5 = []
      for(let i = 0; i< this.seventhInputErrorData.length;i++){
        slidersSteps4.push({
          label:this.seventhInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.seventhInputErrorData[i]
        })
      }
      for(let i = 0; i< this.eightInputErrorData.length;i++){
        slidersSteps5.push({
          label:this.eightInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.eightInputErrorData[i]
        })
      }
      for(let i = 0; i< this.sixthInputErrorData.length;i++){
        slidersSteps3.push({
          label:this.sixthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.sixthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fifthInputErrorData.length;i++){
        slidersSteps2.push({
          label:this.fifthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fifthInputErrorData[i]
        })
      }
      for(let i = 0; i< this.fourthInputErrorData.length;i++){
        slidersSteps.push({
          label:this.fourthInputErrorData[i],
          method:"skip",
          execute:false,
          value:this.fourthInputErrorData[i]
        })
      }
      var layout = {
                          scene:{
                            xaxis:{title:this.dataneeded.headers[0][this.colOne]},
                            yaxis:{title:this.dataneeded.headers[0][this.colTwo]},
                            zaxis:{title:this.dataneeded.headers[0][this.colThree]}
                          },
                          height:850,
                          sliders : [{
                            pad:{
                              t:0
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFour] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps,
                          },
                          {
                            pad:{
                              t:70
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colFive] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps2,
                            
                          },
                          {
                            pad:{
                              t:140
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSix] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps3,
                            
                          },
                          {
                            pad:{
                              t:210
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colSeven] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps4,
                            
                          },
                          {
                            pad:{
                              t:280
                            },
                            currentvalue: {
                              xanchor: 'left',
                              prefix: this.dataneeded.headers[0][this.colEight] + " : ",
                              font: {
                                color: 'black',
                                size: 14
                              }
                            },
                            len:1,
                            steps:slidersSteps5,
                            
                          }]
                                          
                      
                    
                    };
          Plotly.react("errorgraph",{
            data:dataa,
            layout: layout,
          })
          if(this.filteredErrorData5.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
        },
        updateErrorGraph6Inputs(){
          var errorGraph = document.getElementById("errorgraph")
          errorGraph.on("plotly_sliderchange",(datasent)=>{
            this.vall = datasent.step.value
            if(datasent.slider._index == 0){
              this.fourthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index == 1){
              this.fifthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index ==2){
              this.sixthInputErrorDataToDisplay = this.vall
            }
            this.filteredErrorData = this.errorDataToPlot.filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
            this.filteredErrorData2 = this.filteredErrorData.filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
            this.filteredErrorData3 = this.filteredErrorData2.filter(item => item[this.colSix]==this.sixthInputErrorDataToDisplay)
            var xx  = this.getCol(this.filteredErrorData3,this.colOne)
            var yy  = this.getCol(this.filteredErrorData3,this.colTwo)
            var zz  = this.getCol(this.filteredErrorData3,this.colThree)
            var max  = Math.max(...this.getCol(this.filteredErrorData3,7))
          
            var sizee = this.getCol(this.filteredErrorData3,7).map(item => Number(item)/max*50)
            var frame = {
              data:[{
                'x':xx,
              'y':yy,
              'z':zz,
              'marker.size':sizee
              }]
            }
            Plotly.animate("errorgraph", frame)
            if(this.filteredErrorData3.length == 0){
          this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
          this.noDataPointsAlertMessage = "No Corresponding Data Points"

        }
        else{
          this.noDataPointsAlertMessage = ""
          this.noDataPointsAlertClass = ""
        }
          })
        },
        updateErrorGraph7Inputs(){
          var errorGraph = document.getElementById("errorgraph")
          errorGraph.on("plotly_sliderchange",(datasent)=>{
            this.vall = datasent.step.value
            if(datasent.slider._index == 0){
              this.fourthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index == 1){
              this.fifthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index ==2){
              this.sixthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index ==3){
              this.seventhInputErrorDataToDisplay = this.vall
            }
            this.filteredErrorData = this.errorDataToPlot.filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
            this.filteredErrorData2 = this.filteredErrorData.filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
            this.filteredErrorData3 = this.filteredErrorData2.filter(item => item[this.colSix]==this.sixthInputErrorDataToDisplay)
            this.filteredErrorData4 = this.filteredErrorData3.filter(item => item[this.colSeven]==this.seventhInputErrorDataToDisplay)
            var xx  = this.getCol(this.filteredErrorData4,this.colOne)
            var yy  = this.getCol(this.filteredErrorData4,this.colTwo)
            var zz  = this.getCol(this.filteredErrorData4,this.colThree)
            var max  = Math.max(...this.getCol(this.filteredErrorData4,8))
          
            var sizee = this.getCol(this.filteredErrorData4,8).map(item => Number(item)/max*50)
            var frame = {
              data:[{
                'x':xx,
              'y':yy,
              'z':zz,
              'marker.size':sizee
              }]
            }
            Plotly.animate("errorgraph", frame)
            if(this.filteredErrorData4.length == 0){
              this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
              this.noDataPointsAlertMessage = "No Corresponding Data Points"

            }
            else{
              this.noDataPointsAlertMessage = ""
              this.noDataPointsAlertClass = ""
            }
          })
        },
        updateErrorGraph8Inputs(){
          var errorGraph = document.getElementById("errorgraph")
          errorGraph.on("plotly_sliderchange",(datasent)=>{
            this.vall = datasent.step.value
            if(datasent.slider._index == 0){
              this.fourthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index == 1){
              this.fifthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index ==2){
              this.sixthInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index ==3){
              this.seventhInputErrorDataToDisplay = this.vall
            }
            else if(datasent.slider._index ==4){
              this.eightInputErrorDataToDisplay = this.vall
            }
            this.filteredErrorData = this.errorDataToPlot.filter(item => item[this.colFour]==this.fourthInputErrorDataToDisplay)
            this.filteredErrorData2 = this.filteredErrorData.filter(item => item[this.colFive]==this.fifthInputErrorDataToDisplay)
            this.filteredErrorData3 = this.filteredErrorData2.filter(item => item[this.colSix]==this.sixthInputErrorDataToDisplay)
            this.filteredErrorData4 = this.filteredErrorData3.filter(item => item[this.colSeven]==this.seventhInputErrorDataToDisplay)
            this.filteredErrorData5 = this.filteredErrorData4.filter(item => item[this.colEight]==this.eightInputErrorDataToDisplay)
            var xx  = this.getCol(this.filteredErrorData5,this.colOne)
            var yy  = this.getCol(this.filteredErrorData5,this.colTwo)
            var zz  = this.getCol(this.filteredErrorData5,this.colThree)
            var max  = Math.max(...this.getCol(this.filteredErrorData5,9))
          
            var sizee = this.getCol(this.filteredErrorData5,9).map(item => Number(item)/max*50)
            var frame = {
              data:[{
                'x':xx,
              'y':yy,
              'z':zz,
              'marker.size':sizee
              }]
            }
            Plotly.animate("errorgraph", frame)
            if(this.filteredErrorData5.length == 0){
              this.noDataPointsAlertClass = "alert alert-warning w-50 text-center "
              this.noDataPointsAlertMessage = "No Corresponding Data Points"

            }
            else{
              this.noDataPointsAlertMessage = ""
              this.noDataPointsAlertClass = ""
            }
          })
        },
        checkCheckboxes(){
          let selectedVariables = this.variablesToPlot.filter(item => item===true)
          if(selectedVariables.length==3){
            this.canPlot=true
          }
          else{
            this.canPlot=false
          }
        },
        checkCheckboxesInteraction(){
          let selectedVariables = this.variablesToPlotInteraction.filter(item => item===true)
          let selectedVariables2 = this.variablesToPlotInteraction2.filter(item => item==true)
          if(selectedVariables.length==1 && selectedVariables2.length==1){
            this.canPlotInteraction=true
          }
          else{
            this.canPlotInteraction=false
          }
        },
        plotNewVariables(){
          const indexesOfVariables = this.variablesToPlot.flatMap((bool,index) => bool ? index : [])
          this.colOneTraining=indexesOfVariables[0]
          this.colTwoTraining=indexesOfVariables[1]
          this.colThreeTraining=indexesOfVariables[2]
          
          const slideIndex = this.variablesToPlot.flatMap((bool,index) => !bool ? index : [])
          this.colFour = slideIndex[0]
          if(parseInt(this.dataneeded.number_of_inputs)==5){
            this.colFive = slideIndex[1]
            this.manage5InputsErrorGraph(this.normalizedPredictionErrorVersion)
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==6){
            this.colFive = slideIndex[1]
            this.colSix = slideIndex[2]
            this.manage6InputsErrorGraph(this.normalizedPredictionErrorVersion)
            
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==7){
            this.colFive = slideIndex[1]
            this.colSix = slideIndex[2]
            this.colSeven = slideIndex[3]
            
            this.manage7InputsErrorGraph(this.normalizedPredictionErrorVersion)
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==8){
            this.colFive = slideIndex[1]
            this.colSix = slideIndex[2]
            this.colSeven = slideIndex[3]
            this.colEight = slideIndex[4]
            this.manage8InputsErrorGraph(this.normalizedPredictionErrorVersion)
          }
          else if(parseInt(this.dataneeded.number_of_inputs)==4){
            this.manage4InputsErrorGraph(this.normalizedPredictionErrorVersion)
          }
        },
        manageVarImportance(version){
          if(this.dataneeded.variable_importance[version] == "not_saved"){
            this.showVarImportance=false
          }
          else{
            this.showVarImportance = true
            this.plotVariableImportance()
          }
          
        },
        managePredObs(version){
          if(this.dataneeded.y_pred[version]=="not_saved"){
            this.showPredObsNotAvailable = true
            Plotly.purge('predObs')
          }
          else{
            this.showPredObsNotAvailable = false
            
            this.plotPredObs()
          }
        },
        plotVariableImportance(){
          var data = [

              {

                x: this.dataneeded.variable_importance[this.variable_importance_version],

                y: this.dataneeded.headers[0] ,

                type: 'bar',
                orientation:'h',
                transforms: [{
                  type: 'sort',
                  target: 'x',
                  order: 'ascending'
                }],
                width:0.5,
                marker: {

                color: '#002060'

              }
                

              }


            ];
            var layout = {
              title: 'Variable Importance',
              yaxis: {
                automargin: true
              }
            };
            Plotly.newPlot("varImportance",data, layout)
        },
        changeStatsVersion(index){
          this.statsVersion = index
          
          
        },
        plotPredObs(){
          var trace1 = {

            x: this.getCol(this.dataneeded.data_array[this.predObsVersion],parseInt(this.dataneeded.number_of_inputs[0])),

            y: this.dataneeded.y_pred[this.predObsVersion],

            mode: 'markers',

            type: 'scatter',
            hovertemplate:"Observed : %{x}<br>Predicted : %{y} <extra></extra> ",
            name:"Observed & predicted values"

          };
          var params = []
          params = this.calculateTrendLineParameters(this.getCol(this.dataneeded.data_array[this.predObsVersion],parseInt(this.dataneeded.number_of_inputs[0])),this.dataneeded.y_pred[this.predObsVersion])
          var x = this.getCol(this.dataneeded.data_array[this.predObsVersion],parseInt(this.dataneeded.number_of_inputs[0]))
          var x_max = Math.max(...x)
          var x_min = Math.min(...x)
          var step = (x_max-x_min)/100
          var x_long = []

          var value = x_min
          while(value<=x_max){
            x_long.push(value)
            value = value + step
          }
          
          var trace2 = {
            x : x_long,
            y : x_long.map(item => item*params[0]+params[1]),

            
            type:"line",
            name:'Trendline',
            line:{
              width:2, 
              color:"blue"
            },
            hovertemplate:'<b>Trendline</b><br>Predicted = ' + params[0].toFixed(4) + ' * Observed + ' + params[1].toFixed(4) + '<br><br>Observed=%{x}<br>Predicted=%{y} <b>(trend)</b><extra></extra>'
          }
          var data = [trace1, trace2];
          
          var layoutt = {
             title: {

                text:'Observed VS Predicition Target Values'},
                xaxis: {

                  title: {

                    text: 'Observed Values',

                    font: {

                      family: 'Courier New, monospace',

                      size: 18,

                      color: '#7f7f7f'

                    }

                  },

                },

              yaxis: {

                title: {

                  text: 'Predicted Values',

                  font: {

                    family: 'Courier New, monospace',

                    size: 18,

                    color: '#7f7f7f'

                  }

                }

              },
                                   
                          
                                          
                      
                    
                    };

          Plotly.newPlot('predObs', data, layoutt);
        },

        calculateTrendLineParameters(x,y){
          var x_sum = 0
          var y_sum = 0
          var xy_sum = 0
          var xx_sum = 0

          var n = x.length

          for(let i=0;i<n;i++){
            x_sum = x_sum+x[i]
            y_sum = y_sum+y[i]
            xy_sum = xy_sum+ x[i]*y[i]
            xx_sum = xx_sum + x[i]*y[i]
          }
          var slope = ((n*xy_sum)-(x_sum*y_sum))/((n*xx_sum)-(x_sum*x_sum))
          var intercept = ((xx_sum*y_sum)-(x_sum*xy_sum))/((n*xx_sum)-(x_sum*x_sum))
          return [slope,intercept]
        },
        manageVariablesInteraction(version){
          if(this.dataneeded.shap_values[version]==="not_saved"){
            this.showVariablesInteraction = false
            Plotly.purge("variablesInteraction")
          }
          else{
            this.showVariablesInteraction = true
            this.plotVariablesInteration()
          }
        },
        plotVariablesInteration(){
          console.log("1  ",this.dataneeded.shap_values[this.variables_interaction_version][0].length)
          console.log("2  ", this.dataneeded.number_of_inputs)
          if(this.dataneeded.shap_values[this.variables_interaction_version][0].length== parseInt(this.dataneeded.number_of_inputs)){
            console.log("here plotting")
            var trace = {
              x:this.getCol(this.dataneeded.data_array[this.variables_interaction_version],this.colOneInteraction),
              y:this.getCol(this.dataneeded.shap_values[this.variables_interaction_version], this.colOneInteraction),
              mode:"markers",
              marker:{
                colorscale:"Viridis",
                size:20,
                colorbar:{thickness:20, title:this.dataneeded.headers[0][this.colTwoInteraction]},
                color:this.getCol(this.dataneeded.data_array[this.variables_interaction_version],this.colTwoInteraction)
              },
              type:"scatter",
              hovertemplate:"variable x : %{x} <br> variable x Importance : %{y} <br> variable y : %{marker.color}"
  
            }
          }
          else {
            console.log("no actualy plotting here ")
            var trace = {
              x:this.getCol(this.dataneeded.shap_values[this.variables_interaction_version],this.colOneInteraction),
              y:this.getCol(this.dataneeded.shap_values[this.variables_interaction_version], this.colOneInteraction+parseInt(this.dataneeded.number_of_inputs)),
              mode:"markers",
              marker:{
                colorscale:"Viridis",
                size:20,
                colorbar:{thickness:20, title:this.dataneeded.headers[0][this.colTwoInteraction]},
                color:this.getCol(this.dataneeded.shap_values[this.variables_interaction_version],this.colTwoInteraction)
              },
              type:"scatter",
              hovertemplate:"variable x : %{x} <br> variable x Importance : %{y} <br> variable y : %{marker.color}"
  
            }
          }
          var layout={
            height:700,
            
            xaxis:{title:this.dataneeded.headers[0][this.colOneInteraction]},
            yaxis:{title:this.dataneeded.headers[0][this.colOneInteraction]+" Importance"}
          
          }
          Plotly.newPlot("variablesInteraction", [trace], layout)
        },
        changeVariablesInteraction(index){
          this.variables_interaction_version = index
          this.manageVariablesInteraction(index)
        },
        plotNewVariablesInteraction(){
          const indexesOfVariables = this.variablesToPlotInteraction.flatMap((bool,index) => bool ? index : [])
          const indexesOfVariables2 = this.variablesToPlotInteraction2.flatMap((bool,index) => bool ? index : [])
          this.colOneInteraction = indexesOfVariables[0]
          this.colTwoInteraction = indexesOfVariables2[0]
          this.plotVariablesInteration()

        }
        

        
    },
    mounted(){
      if(parseInt(this.dataneeded.number_of_inputs)<=3){

        this.getgraph()
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==4){
        
        this.manage4InputsErrorGraph(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==5){
        this.manage5InputsErrorGraph(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==6){
        this.manage6InputsErrorGraph(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==7){
        this.manage7InputsErrorGraph(this.dataneeded.versionToShow)
      }
      else if(parseInt(this.dataneeded.number_of_inputs)==8){
        this.manage8InputsErrorGraph(this.dataneeded.versionToShow)
      }

      this.manageVarImportance(this.dataneeded.versionToShow)
      if (this.dataneeded.r2.every((val,i,arr)=>val==='not_saved')){
        
        this.showStats=false
      }
      else{
        this.showStats = true
      }
      if (this.dataneeded.y_pred.every((val,i,arr)=>val==='not_saved')){
        
        this.showPredObs=false
      }
      else{
        this.showPredObs = true
      }
      this.managePredObs(this.dataneeded.versionToShow)
      this.manageVariablesInteraction(this.dataneeded.versionToShow)
     
     
    },
    updated(){
      if(this.dataneeded.shap_values[this.variables_interaction_version]==="not_saved"){
            Plotly.purge("variablesInteraction")
          }
    }
}
</script>
<style scoped>
amplify-s3-image{
  --width: 100%;
  
}

.stats{
  width: 40%;
  margin:1rem auto;
  border-radius: 10px;
}
.variable_impact{
  
  width: 70%;
  margin: auto;
}
.activebtn{
  background-color: #029676;

}
.nonactivebtn{
  background-color: white;
}
.error{
  width: 70vw;
  overflow-x: scroll;
  
}
.featimp{
  width: 71vw;
  
  overflow-x: scroll;
}
#errorgraph{
  width: fit-content;
  max-width: 80%;
  margin: auto;
  
  overflow-x: auto ;
  

}
#featimpgraph{
    
   width: fit-content;
  max-width: 95%;
  margin: auto;
  border-radius: 20px;
  overflow-x: auto ;
  

}
.blue-color{
  background: #0808ca;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #1717c4);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1054bb, #1111c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.graphs{
  height: fit-content;
  margin: 2rem auto 1rem auto;
  
  background-color: white;
  
}
.graph-title{
  width: 82%;
  margin: 0 auto;
  padding: 0.1rem 1vw;
  background-color: white;
  border-radius: 5px;
}
.graph-title h4{
  font-size: 1.6rem;
  letter-spacing: 2px;
  margin: auto 0;
  padding: 1rem;
  border-radius: 10px;
  border-bottom: black solid 1px;
  font-weight: bolder;

}
.graph-title button{
  border: white 1px solid;
  background-color: #1054bb;
  font-size: 1.2rem;
  letter-spacing: 2px;
}
button{
  font-size: 1rem;
}


.sugg-title{
  width: 70%;
  height: 10%;
  margin: 0 auto 4% auto;
  background-color: transparent;
  border-radius: 20px;
  
  
}
.sugg-title h4{
  font-size: 1rem;
  
  background: #1515b8;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #1515bd);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1054bb, #1010c4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.7vw;
  padding-bottom: 0.6vw;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px ;
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto -1vw;
  border-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
}
.sugg-title button{
  font-size: 1rem;
  margin: auto -1vw auto 0;
  padding: 0.5vw 1vw;
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
  border-radius: 10px;
}


</style>
