<template>
<div v-if="makeChanges" class="makechanges">
    <div class="pred-title d-flex flex-row justify-content-between align-items-center">
      <h4>
        UPDATE INPUT DATA & ITERATE
      </h4>
      
        <div class="dropdown">
            <button class="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton454" data-bs-toggle="dropdown" aria-expanded="false">
                Version {{projectVersion +1}} | Project Version To Iterate From
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton454">
                <li v-for="(version, index) in projectData.data_array.length" :key="version">
                    <a class="dropdown-item font" href="#" @click="changeVersion(index)">Version {{index+1}}</a>
                </li>
            </ul>
        </div>
        
    </div>

    <div class="w-50 pred-title d-flex flex-row justify-content-between align-items-center">
      <h4>
        Algorithm choice
      </h4>
      
        <div class="dropdown">
            <button class="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton4543" data-bs-toggle="dropdown" aria-expanded="false">
                {{method=='ann' ? 'Artificial Neural Networks' : 'Gradient Boosted Decision Trees'}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton4543">
                <li><a href="#" class="dropdown-item font" @click="chooseAlgo('ann')"> Artificial Neural Networks ANN (Default)</a></li>
                <li><hr></li>
                <li><a href="#" class="dropdown-item font" @click="chooseAlgo('xgb')"> Gradient Boosted Decision Trees GBDT</a></li>
            </ul>
        </div>
        
    </div>
    
    
    <div class="inputdatasection">
        <div class="graph-title blue-color text-black">

            <h1 >Dataset Update</h1>
        </div>
            <div class="tables">
                <div class="datasettable" v-if="showDataset">

                    <div class="scrolltable" >

                        <table class="table table-striped table-bordered border-dark rounded">
                            
                            <thead>
                            <tr  class="text-light table-color text-center">
                                <th :colspan="projectData.headers[0].length+3" class="">

                                    <div class="mb-1">

                                    Version {{projectVersion+1}} Input Dataset
                                    </div>
                                    
                                    <button v-if="showDeleteRowsButton" class="btn btn-sm text-light border" @click ="showDeleteRowsFunction()"> Remove Data Points</button>
                                    <button v-if="!showDeleteRowsButton && !showCancelDelete" class="btn btn-sm text-light border" @click="cancelDeleteRowsFunction()">Cancel Remove Data Points</button>
                                </th>
                                
                                <!-- <td colspan="1">

                                    <div>
                                        <button v-if="showAddOldData" class="btn text-light border" @click="addOldData()">Use This Version's Dataset</button>
                                        <button v-if="!showAddOldData" class="btn btn-danger " @click="cancelAddOldData()">Remove This Version's Dataset</button>
                                    </div>
                                    
                                </td> -->
                                
                            </tr>
                            <tr class="text-center ">
                                <th scope="col" colspan="1" v-if="showDeleteRows"></th>
                            <th scope="col" v-if="!showDeleteRows">#</th>
                            <th scope="col" v-for="header in projectData.headers[0]" :key="header">
                                {{ header }}
                            </th>
                            <th scope="col">
                                Target variable
                            </th>
                            </tr>
                            </thead>
                            <tbody class="text-center ">

                            <tr  v-for="(arr,index) in tableData[projectVersion]" :key="arr">
                                <th scope="row" v-if="showDeleteRows"> <button class="btn btn-sm btn-light" @click="removeRow(index)"><i class="bi bi-trash-fill"></i></button></th>
                            <th v-if="!showDeleteRows" scope="row">{{ index + 1 }}</th>
                            <td v-for="data in arr" :key="data">
                                {{ data }}
                            </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="addraw text-center" v-if="showSaveDeleteButton">
                        <button class="btn btn-success font" @click="saveDeletedRows">Save / Keep the remaining data points</button>
                    </div>
                    <div class="addraw" v-if="showAddValues">
                        <table  class="table table-striped table-bordered border-dark rounded">
                            <thead>
                                <tr class="text-light text-center blue-color"> 
                                    <th scope="col" v-for="header in projectData.headers[0]" :key="header">
                                        {{ header }}
                                    </th>
                                    <th scope="col">
                                        Target variable
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr>
                                    <td v-for="index in (parseInt(projectData.number_of_inputs[projectVersion])+1)" :key="index">
                                        <input type="number" v-model.number="oneRowArray[index-1]">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                            <p v-if="v$.oneRowArray.$error" class="errormessage">Fill in all the fields. Values must be numeric</p>
                        <div class="addrawbtns">

                            <button class="btn btn-success" @click="loadDataFromManual">Save Row</button>
                            <button class="btn btn-warning" @click="hideAddRow">Cancel</button>
                        </div>
                    </div>
                    <div class="add-data-section">
                        <a class="btn" style="background:#002060; color:white" data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample">
                            Update Dataset
                        </a>
                        <div class="collapse" id="collapseExample3">
                            <div class="card card-body">
                                <div>

                                    <label for="csvfile2" style="font-size:1vw" class="form-label ms-2">Add More Data Through CSV or Excel File</label>
                                    <input style="width:15vw;" class="form-control form-control-md custom-file-upload" id="csvfile2" type="file" @change="loadDataFromImport" pattern="^.+\.(xlsx|xls|csv)$" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" >   
                                
                                    <p class="errormessage font" style="font-weight:1000;" v-if="v$.numberOfInputsFromImport.$error" >CSV File Should have {{ projectData.number_of_inputs[0] }} inputs & 1 target value</p>
                                    
                                </div>
                                <div class="mt-2">
                                    <button class="btn" style="background:#029676; color:white" @click="showAddValuesTable">Add rows of data manually</button>
                                </div>                            
                            </div>
                        </div>
                        <div>
                            
                        </div>
                        
                        
                    </div>
                    
                    
                </div>
                <div>
                    <div class="scrolltable" v-if="showAddedValuesTable">

                        <table v-if="showAddedValuesTable" class="table table-striped table-bordered border-dark rounded">
                            
                                <thead>
                                    <tr>
                                        <th :colspan="projectData.headers[0].length+1" class=" align-middle text-light table-color text-center">Updated Dataset</th>
                                    </tr>   
                                    <tr class="text-center">
                                        
                                        <th scope="col" v-for="header in projectData.headers[0]" :key="header">
                                            {{ header }}
                                        </th>
                                        <th scope="col">
                                            Target variable
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr v-for="arr in temporaryData.data_array" :key="arr">
                                        
                                        <td v-for="data in arr" :key="data">
                                            {{ data }}
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                    <div v-if="showAddedValuesTable && showDeleteValues" class="cancelbtns text-center">

                        <a class="btn btn-secondary " data-bs-toggle="collapse" href="#collapseExample222" role="button" aria-expanded="true" aria-controls="collapseExample222">
                            Delete Values
                        </a>
                        
                        <div class="collapse" id="collapseExample222">
                            <div class=" card card-body border-dark">
                                <div>
                                    <button v-if="showCancelDelete" class="btn btn-sm btn-warning" @click="cancelDeleteRowsFunction()">Restore Deleted Data Points</button>
                                    <button v-if="!showAddOldData" class="btn btn-sm btn-danger" @click="cancelAddOldData()">Delete This Version's Dataset</button>
                                    <button class="btn btn-sm btn-warning " @click="cancelAddImport" v-if="changesOccuredInImport">Delete Values Added From Import</button>
                                    <button class="btn btn-sm btn-warning " @click="cancelAddManually" v-if="changesOccuredInManual">Delete Values Added Manually</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center">

                        <button class="btn btn-sm" style="background:#029676;color:white" @click="confirmDataset" v-if="showAddedValuesTable && showDataset">Save Updated Dataset</button>
                        <button class="btn btn-sm" style="background:#002060;color:white" @click="editAgainDataset" v-if="showEditDataset">Edit Dataset Again</button>
            </div>
            
        
        
    </div>

    <br>
    <hr class="mt-2 mb-3"/>
        
        
        <hr class="mt-2 mb-3"/>
    <div class="parameterssection">
        <div class="graph-title blue-color text-black d-flex flex-row justify-content-between">

            <h1 class="ms-5" >Parameters Update</h1>
            <h1 class="mx-5">Limits Update</h1>
            
        </div>
        <div class="w-100 d-flex flex-row justify-content-around align-items-center">

            <div class="parameterstable">

                <table class="table table-striped table-bordered border-dark rounded">
                    <thead class="text-light text-center table-color">
                        <tr>
                            <th scope="col">
                                Parameter
                            </th>
                            <th scope="col">
                                Value
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr>
                            <td>
                                Visualization Layers
                            </td>
                            <td>
                                <input type="number" v-model.number="visualizationLayers" @change="showConfirmParamChanges">
                            </td>
                            
                        </tr>
                        <tr >
                                <th class="errormessage" colspan="col-5" v-if="v$.visualizationLayers.$error">Field is required and should be an integer between 3 and {{maxLayers}}</th>
                        </tr>
                        <tr>
                            <td>
                                Number Of Suggested Points
                            </td>
                            <td>
                                <input type="number" v-model.number="n_batch" @change="showConfirmParamChanges">
                            </td>
                        </tr>
                        <tr >
                                <th class="errormessage" colspan="col-5" v-if="v$.n_batch.$error">Field is required and should be an integer between 1 and 15</th>
                        </tr>
                        <tr v-if="method=='ann'">
                            <td>
                                Maximum Number Of Prediction Nodes 
                            </td>
                            <td>
                                <input type="number" v-model.number="max_neurons" @change="showConfirmParamChanges">
                            </td>
                        </tr>
                        <tr v-if="method=='ann'">
                                <th class="errormessage" colspan="col-5" v-if="v$.max_neurons.$error">Field is required and should be an integer between 10 and 30</th>
                        </tr>
                        <tr>
                            <td>
                                Maximum {{method=="ann" ? 'Iterations' : 'Depth'}}
                            </td>
                            <td>
                                <input type="number" v-model.number="max_iter" @change="showConfirmParamChanges">
                            </td>
                        </tr>
                        <tr >
                                <th class="errormessage" colspan="col-5" v-if="v$.max_iter.$error">Field's required, a positive integer from {{minMaxIter}} to {{maxMaxIter}}</th>
                        </tr>
                        <tr>
                            <td>
                                Regularization Value
                            </td>
                            <td>
                                <input type="number" v-model.number="alpha_value" @change="showConfirmParamChanges">
                            </td>
                        </tr>
                        <tr >
                                <th class="errormessage" colspan="col-5" v-if="v$.alpha_value.$error">Field is required and should be a number between 0.1 to 10</th>
                        </tr>

                        <tr v-if="!changeMethod">
                            <td>
                                 

                                        Number Of {{method=='ann' ? 'Neurons ' : 'Splits '}}<i class="bi bi-info-circle-fill"  data-toggle="tooltip" data-placement="bottom" title="When set to 0, the algorithm will calculate its optimal value automatically"></i>
                                
                            </td>
                            <td>
                                <input type="number" v-model.number="neurons_opt" @change="showConfirmParamChanges">
                            </td>
                        </tr>
                        <tr v-if="!changeMethod">
                                <th class="errormessage" colspan="col-4" v-if="v$.neurons_opt.$error">Field is required and should be a positive integer less than {{neuronsMax}}</th>
                        </tr>
                    </tbody>

                </table>

                <button class="btn btn-success font" style="margin-right:1rem;" v-if="changesOccuredInParam" @click="saveParametersChanges">
                    Save Changes
                </button>
                <button class="btn btn-warning font" v-if="showCancelParametersChanges" @click="cancelParametersChanges">Cancel</button>
                <button class="btn btn-danger font" v-if="showResetParam" @click="cancelParametersChanges">Reset To Initial Values</button>
            </div>
            <div class="parameterstable">
                <table class="table table-striped table-bordered rounded">
                    <thead class="text-center text-light table-color">
                        <tr>
                            <th>
                                Attribute
                            </th>
                            <th scope="col" v-for="header in projectData.headers[0]" :key="header">
                                {{ header }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr>
                            
                            <td>Minimum limit</td>
                            <td v-for="index in parseInt(input_min_lim_list.length)" :key="index">
                                <input type="number" v-model.number="input_min_lim_list[index - 1]" @change="showConfirmLimChanges">
                            </td>
                        </tr>
                        
                        <tr>
                            
                            <td>Maximum limit</td>
                            <td v-for="index in parseInt(input_max_lim_list.length)" :key="index">
                                <input type="number" v-model.number="input_max_lim_list[index - 1]" @change="showConfirmLimChanges">
                            </td>
                        </tr>
                        <tr>
                            <th class="errormessage" colspan="col-6" v-if="v$.input_max_lim_list.$error">
                                Fill in all fields. Values must be numeric
                            </th>
                        </tr>
                        <tr>
                            
                            <td>Exploitation limit</td>
                            <td v-for="index in parseInt(lim_point_closeness.length)" :key="index" @change="showConfirmLimChanges">
                                <input type="number" v-model.number="lim_point_closeness[index - 1]">
                            </td>
                        </tr>
                        <tr>
                            <th class="errormessage" colspan="col-6" v-if="v$.lim_point_closeness.$error">
                                Fill in all fields. Values must be numeric
                            </th>
                        </tr>
                    </tbody>
                    </table>
                    <p class="errormessage" colspan="col-6" v-if="v$.input_min_lim_list.$error">
                                Fill in all fields. Values must be numeric
                            </p>
                    <button class="btn btn-success font" style="margin-right:1rem;" v-if="changesOccuredInLimits" @click="saveLimitsChanges">Save Changes</button>
                    <button class="btn btn-danger font" v-if="showCancelLimitsChanges || showResetLimits" @click="cancelLimitsChanges">Reset To Initial Values</button>
                    
            </div>
        </div>
    </div>
    <br>
    <hr class="mt-2 mb-3"/>
        
        
        <hr class="mt-2 mb-3"/>
    
       
    
    <div v-if="showSubmitBtn" class="submit text-center mt-2 " style="width:40%; margin:auto">
        <div class="mb-3 form-floating" style="margin:auto" v-if="showResetLimits || showResetParam || changesOccuredInImport || changesOccuredInManual || changesOccuredInDelete">
            <input type="text" class="form-control descinput" id="description" v-model="projectDescription"
                placeholder="Description">
            <label for="description" class="form-label">Description of the update</label>
            <div v-if="v$.projectDescription.$error">
            <p class="errormessage">Description too long</p>
            </div>

        

        </div>
        <div class="confirmationbtn" v-if="showResetLimits || showResetParam || changesOccuredInImport || changesOccuredInManual || changesOccuredInDelete || changeMethod">
            <a class="btn btn-lg" style="background:#029676; color:white"  href="#displayChanges" @click="confirmUpdates">Confirm All Updates</a>
        </div>
    </div>
</div>

<div v-if="displayChanges"  class="displaychanges">
    <br id="displayChanges">
    
    <div v-if="nochangesmadetodataset" class="alert alert-warning alert-dismissible" role="alert" id="liveAlert">
        <strong>NOTE ! </strong> No Changes Made To Dataset, The Selected Version Dataset Is Used.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div v-if="changeMethod" class="alert alert-warning alert-dismissible" role="alert" id="liveAlert">
        <strong>NOTE ! </strong> The algorithm to use has been changed. Number of {{method=="ann" ? 'Neurons' : 'Splits'}} is set to 0 to recalculate its optimal value.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div v-if="!nochangesmadetodataset" class="alert alert-warning alert-dismissible" role="alert" id="liveAlert">
        <strong>NOTE ! </strong> The Dataset has been updated. Number of {{method=="ann" ? 'Neurons' : 'Splits'}} is set to 0 to recalculate its optimal value.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <!-- #########################################"" -->
    <div class="all text-center input">
        <div class="input-data-title d-flex flex-row justify-content-around align-items-center">

            <h4>
            UPDATED INPUT DATA FROM VERSION {{projectVersion +1}}
            </h4>
            
        
        </div>
            <h5 class="border m-auto mb-3 p-2 rounded" style="width:fit-content">Algorithm to use  : {{updatedData.method=='ann' ? 'ANN' : 'GBDT'}}</h5>
        <div class="row">
        <div class="data col-6 pe-lg-3 pe-md-0 md-lg-0 mb-md-2">
            
            <table class="table table-striped table-bordered border-dark  rounded">
            <thead class="">
                <tr class="table-color text-light">

                <th v-if="projectData.headers[0].length==2" colspan="4" class="align-middle">
                    CSV File Data
                </th>
                <th v-if="projectData.headers[0].length==3" colspan="5" class="align-middle">
                    Updated Dataset                </th>
                
                </tr>
                <tr class="table-bordered border-dark" >
                <th scope="col">#</th>
                <th scope="col" v-for="header in projectData.headers[0]" :key="header">
                    {{ header }}
                </th>
                <th scope="col">
                    Target variable
                </th>
                </tr>
            </thead>
            <tbody>

            <tr v-for="(arr,index) in updatedData.data_array" :key="arr">

                <th scope="row" class="">{{ index + 1 }}</th>
                <td v-for="data in arr" :key="data">
                {{ data }}
                </td>
            </tr>
            </tbody>
            </table>
            <br>
            

        </div>
        
        <div class="col-6  pe-lg-3 pe-md-0 md-lg-0 mb-md-2">
            <div class="row">

            <div>
                <table class="table table-striped table-sm table-bordered border-dark rounded">
                <thead class="">
                    <tr class="table-color">
                    <th colspan="2" class="align-middle text-light">Updated Parameters</th>
                    
                    </tr>
                <tr class="table-bordered border-dark">
                    <th scope="col">Attribute</th>
                    <th scope="col">Value</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    
                    <td>Visualization Layers</td>
                    <td>{{ updatedData.layers }}</td>
                </tr>
                <tr>
                    
                    <td>Number of suggested points</td>
                    <td>{{ updatedData.n_batch }}</td>
                </tr>
                <tr v-if="method=='ann'">
                    
                    <td>Maximum number of prediction nodes</td>
                    <td>{{ updatedData.max_neurons }}</td>
                </tr>
                <tr>
                    
                    <td>Regularization value</td>
                    <td>{{ updatedData.alpha_value}}</td>
                </tr>
                <tr>
                
                    <td>Maximum {{method =='ann' ? 'Iterations' : 'Depth'}}</td>
                    <td>{{ updatedData.max_iter }}</td>
                </tr>
                <tr>
                    <td>Number Of {{method =='ann' ? 'Neurons' : 'Splits'}}</td>
                    <td>{{ updatedData.neurons_opt}}</td>
                </tr>
                </tbody>
                </table>
            </div>

            </div>

            <div class="mt-4"></div>
            <div class="row">

            <div>
                <table class="table table-striped table-sm table-bordered border-dark rounded">
                <thead class="">
                    <tr class="table-color">
                    <th  :colspan="projectData.headers[0].length +1" class="align-middle text-light">Updated MIN/MAX Limits</th>
                    
                    </tr>
                <tr class="table-bordered border-dark">
                    
                    <th scope="col">Attribute</th>
                    <th scope="col" v-for="header in projectData.headers[0]" :key="header">
                    {{ header }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    
                    <td>Minimum limit</td>
                    <td v-for="data in updatedData.input_min_lim_list" :key="data">{{ data }}</td>
                </tr>
                <tr>
                    
                    <td>Maximum limit</td>
                    <td v-for="data in updatedData.input_max_lim_list" :key="data">{{ data }}</td>
                </tr>
                <tr>
                    
                    <td>Exploitation limit</td>
                    <td v-for="data in updatedData.lim_point_closeness" :key="data">{{ data }}</td>
                </tr>
                </tbody>
                </table>
            </div>
            
            </div>
        </div>
            </div>
            <div class="d-flex flex-row justify-content-between pb-4">

            <div v-if="displayChanges">
                <button class="btn font" @click="backToMakeChanges" style="background:#002060; color:white"><i class="bi bi-arrow-left"></i> Back To Make More Changes</button>
            </div>
            <div>
                <div v-if="dataNotSent" class="">
                    <button @click="submitUpdatedData" class="btn mx-2" style="background:#029676; color:white">Override Current Version</button>
                    
                    <button @click="submitNewVersion" class="btn" style="background:#029676; color:white"> Create a New Version</button>
                    
                </div>
                <div class="spinner-border text-light" role="status" v-if="dataSent">
                    <span class="visually-hidden">Loading...</span>
                </div>

            </div>
        </div>
        
    </div>

    <!-- ########################################### -->
    

</div>

</template>

<script>
import XLSX from 'xlsx'
import {Auth} from 'aws-amplify'
import Mybutton from "../Mybutton.vue"
import  useVuelidate  from '@vuelidate/core'
import {required, maxLength,minLength, numeric, integer, minValue,maxValue} from '@vuelidate/validators'

const isAllNumeric = function(value){
  const arr = []
  for (let i = 0; i < value.length;i ++){
    if (typeof value[i] ==='number'){
      arr.push(true)
    }
    else{
      arr.push(false)
    }
  }
  for (let i = 0; i < arr.length;i ++){
    if (arr[i]===false){
      return false
    }
  }
  return true
}

export default {
    name:"UpdateForm",
    setup(){
        return {
            v$: useVuelidate()
        }
    },
    validations(){
        return {
            numberOfInputsFromImport:{
                integer,
                minValue:minValue(this.numberOfInputs),
                maxValue:maxValue(this.numberOfInputs),
                $autoDirty:true

            },
            oneRowArray:{
                minLength:minLength(parseInt(this.numberOfInputs) + 1),
                maxLength:maxLength(parseInt(this.numberOfInputs) + 1),
                $autoDirty:true,
                isAllNumeric
            },
            visualizationLayers:{
                required,
                integer,
                minValue : minValue(3),
                maxValue:maxValue(this.maxLayers),
                $autoDirty:true
            },
            n_batch:{
                required,
                integer,
                minValue : minValue(1),
                maxValue:maxValue(15),
                $autoDirty:true
            },
            max_neurons:{
                required,
                integer,
                minValue : minValue(10),
                maxValue : maxValue(30),
                $autoDirty:true
            },
            neurons_opt:{
                required,
                integer,
                minValue : minValue(0),
                maxValue:maxValue(this.neuronsMax),
                $autoDirty:true
            },
            max_iter:{
                required,
                integer,
                minValue : minValue(this.minMaxIter),
                maxValue: maxValue(this.maxMaxIter),
                $autoDirty:true
            },
            alpha_value:{
                required,
                numeric,
                minValue : minValue(0.1),
                maxValue:maxValue(10),
                $autoDirty:true
            }, 
            input_min_lim_list:{
                required,
                minLength:minLength(this.numberOfInputs),
                maxLength:maxLength(this.numberOfInputs),
                $autoDirty:true,
                isAllNumeric
            },
            input_max_lim_list:{
                required,
                minLength:minLength(this.numberOfInputs),
                maxLength:maxLength(this.numberOfInputs),
                $autoDirty:true,
                isAllNumeric
            },
            lim_point_closeness:{
                required,
                minLength:minLength(this.numberOfInputs),
                maxLength:maxLength(this.numberOfInputs),
                $autoDirty:true,
                isAllNumeric
            },
            projectDescription:{
                maxLength: maxLength(150),
                $autoDirty: true
            }
        }
    },
    components:{
        Mybutton
    },
    props:{
        projectData:{},
        tableData:[]
    },
    data(){
        return {
            projectVersion:this.projectData.sortKey.length -1,
            originalProjectData:[],
            method:'ann',
            updatedData:{
                "new_data_array":[],
                "original_data_array":[],
                "data_array":[],
                "input_min_lim_list":[],
                "input_max_lim_list":[],
                "lim_point_closeness":[],
                "layers":Number,
                "n_batch":Number,
                "alpha_value":Number,
                "max_iter":Number,
                "max_neurons":Number,
                "number_of_inputs":undefined,
                "project_name": this.projectData.project_name[0],
                "sortkeys":[...this.projectData.sortKey],
                "project_description": "",
                "method":'ann'
            },
            temporaryData:{
                "data_array_from_import":[],
                "data_array_from_manual":[],
                "data_array_from_suggested_points":[],
                "data_array_from_delete":[],
                "data_array_from_old_data":[],
                "data_array":[],
                "input_min_lim_list":[],
                "input_max_lim_list":[],
                "lim_point_closeness":[],
                "layers":Number,
                "n_batch":Number,
                "alpha_value":Number,
                "max_iter":Number,
                "max_neurons":Number,
                "number_of_inputs":undefined
            },
            neuronsMax:30,
            projectDescription:"",
            showDataset:true,
            showEditDataset:false,
            showDeleteValues:true,
            makeChanges:true,
            dataSent:false,
            dataNotSent:true,
            displayChanges:false,
            showAddValues:false,
            showAddedValuesTable:false,
            oneRowArray:[],
            showSubmitBtn:true,
            showDeleteRows: false,
            changesOccuredInManual:false,
            changesOccuredInImport:false,
            changesOccuredInParam:false,
            changesOccuredInLimits:false,
            changesOccuredInDelete:false,
            showDeleteRowsButton:true,
            showAddOldData:true,
            showCancelDelete:false,
            showSaveDeleteButton:false,
            showCancelParametersChanges:false,
            showCancelLimitsChanges:false,
            showResetParam:false,
            showResetLimits:false,
            visualizationLayers:this.projectData.layers[this.projectData.sortKey.length -1],
            n_batch : this.projectData.n_batch[this.projectData.sortKey.length -1],
            max_neurons:this.projectData.max_neurons[this.projectData.sortKey.length -1],
            neurons_opt:this.projectData.neurons_opt[this.projectData.sortKey.length -1] ,
            max_iter:this.projectData.max_iter[this.projectData.sortKey.length -1],
            alpha_value:this.projectData.alpha_value[this.projectData.sortKey.length -1],

            input_min_lim_list:[...this.projectData.input_min_lim_list[this.projectData.sortKey.length -1]],
            input_max_lim_list:[...this.projectData.input_max_lim_list[this.projectData.sortKey.length -1]],
            lim_point_closeness:[...this.projectData.lim_point_closeness[this.projectData.sortKey.length -1]],
            numberOfInputsFromImport:null,
            numberOfInputs:null,
            nochangesmadetodataset:false,
            dataarray:[],
            user:{},
            username:'',
            session:{},
            authHeader:'',
            maxLayers:null,
            maxMaxIter:null,
            minMaxIter:null
        }
    },
    methods:{
        chooseAlgo(value){
            this.method = value
            if (this.method=='ann'){
                this.neuronsMax = 30
            }
            else{
                this.neuronsMax=  200
    }
            
        },
        changeVersion(index){
            this.projectVersion = index
            this.visualizationLayers=this.projectData.layers[index]
            this.n_batch =this.projectData.n_batch[index]
            this.max_neurons=this.projectData.max_neurons[index]
            this.max_iter=this.projectData.max_iter[index]
            this.neurons_opt = this.projectData.neurons_opt[index]
            this.alpha_value=this.projectData.alpha_value[index]
            this.input_min_lim_list=[...this.projectData.input_min_lim_list[index]]
            this.input_max_lim_list=[...this.projectData.input_max_lim_list[index]]
            this.lim_point_closeness=[...this.projectData.lim_point_closeness[index]]
            this.method = this.projectData.ml_method[this.projectVersion]
        },
        loadDataFromImport(e) {
            this.temporaryData.data_array_from_import = []
            const file  = e.target.files[0]
            const reader = new FileReader()
            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result
                const wb = XLSX.read(bstr, { type: 'binary' })
                /* Get first worksheet */
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                /* Convert array of arrays */
                this.dataarray = XLSX.utils.sheet_to_json(ws, { header: 1 })

                if(this.dataarray[this.dataarray.length - 1].length != this.dataarray[1].length){
                    this.dataarray.splice(-1,1)
                }
                this.temporaryData.data_array_from_import = this.dataarray;
                this.numberOfInputsFromImport = (this.dataarray[0].length - 1)
                this.v$.numberOfInputsFromImport.$touch()
                if (
                    typeof this.dataarray[0][0] === "string" &&
                    isNaN(this.dataarray[0][0])
                ) {
                    this.temporaryData.data_array_from_import.shift()
                } 
                if(!this.v$.numberOfInputsFromImport.$error){
                    if(this.changesOccuredInDelete){

                        this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_delete]
                    }
                    else{
                        this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.tableData[this.projectVersion]]

                    }
                    this.showAddedValuesTable=true
                    this.changesOccuredInImport=true
                }
            }
            reader.readAsBinaryString(file)
            
        },
        loadDataFromManual(){
            this.v$.oneRowArray.$touch()
            if(!this.v$.oneRowArray.$error){
                
                if(this.oneRowArray.length == (parseInt(this.projectData.number_of_inputs) + 1)){
                    this.temporaryData.data_array_from_manual.push(this.oneRowArray)
                    this.oneRowArray = []
                    this.showAddValues=false
                    if(this.changesOccuredInDelete){

                        this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_delete]
                    }
                    else{
                        this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.tableData[this.projectVersion]]

                    }
                    this.showAddedValuesTable=true
                    this.changesOccuredInManual=true
                }
            }
            
        },
        removeRow(index){
            this.temporaryData.data_array_from_delete.splice(index,1)
            this.changesOccuredInDelete=true
            this.showSaveDeleteButton = true
        },
        saveDeletedRows(){
            this.showAddedValuesTable=true
            this.temporaryData.data_array = [...this.temporaryData.data_array_from_import, ...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_delete]
            this.showDeleteRows=false
            this.tableData[this.projectVersion] = [...this.originalProjectData]
            
            this.showDeleteRowsButton = false
            this.showCancelDelete = true
            this.showSaveDeleteButton = false
            
        },
        addOldData(){
            this.temporaryData.data_array_from_old_data = [...this.tableData[this.projectVersion]]
            if(this.changesOccuredInDelete){
                this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_delete,...this.temporaryData.data_array_from_old_data]
            }
            else{
                this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_old_data]

            }
            this.showAddedValuesTable = true
            this.showAddOldData=false
        },
        saveParametersChanges(){
            this.v$.$touch()
            if(!this.v$.visualizationLayers.$error && !this.v$.n_batch.$error && !this.v$.max_neurons.$error && !this.v$.max_iter.$error && !this.v$.alpha_value.$error && !this.v$.neurons_opt.$error){

                this.temporaryData.layers = this.visualizationLayers
                this.temporaryData.n_batch = this.n_batch
                this.temporaryData.alpha_value=this.alpha_value
                this.temporaryData.max_iter = this.max_iter
                this.temporaryData.max_neurons = this.max_neurons
                this.temporaryData.neurons_opt = this.neurons_opt
                this.changesOccuredInParam = false
                this.showCancelParametersChanges = false
                this.showResetParam = true
            }
            else{
                alert("Please Fill in the fields correctly")
            }
        },
        saveLimitsChanges(){
            this.v$.$touch()
            if(!this.v$.input_min_lim_list.$error && !this.v$.input_max_lim_list.$error && !this.v$.lim_point_closeness.$error){
                this.temporaryData.lim_point_closeness = this.lim_point_closeness
                this.temporaryData.input_min_lim_list = this.input_min_lim_list
                this.temporaryData.input_max_lim_list = this.input_max_lim_list
                this.changesOccuredInLimits = false
                this.showCancelLimitsChanges = true
                this.showResetLimits = true
                
            }
            else{
                alert("Please fill in all the fields correctly")
            }
        },
        showAddValuesTable(){
            this.showAddValues=true
        },
        showDeleteRowsFunction(){
            this.originalProjectData = [...this.tableData[this.projectVersion]]
            this.temporaryData.data_array_from_delete = this.tableData[this.projectVersion]
            this.showDeleteRows = true
            this.showDeleteRowsButton=false
            
        
        },
        hideAddRow(){
            this.showAddValues=false
            this.oneRowArray=[]
        },
        cancelAddImport(){
            if(this.changesOccuredInDelete){

                this.temporaryData.data_array = [...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_delete]
            }
            else{
                if(!this.temporaryData.data_array_from_manual.length==0){

                    this.temporaryData.data_array = [...this.temporaryData.data_array_from_manual,...this.tableData[this.projectVersion]]
                }
                else{
                    this.temporaryData.data_array = []
                }

            }
            this.temporaryData.data_array_from_import=[]
            this.changesOccuredInImport = false
            if(this.temporaryData.data_array.length ==0){
                this.showAddedValuesTable=false
            }
        },
        cancelAddManually(){
            if(this.changesOccuredInDelete){

                this.temporaryData.data_array = [...this.temporaryData.data_array_from_import, ...this.temporaryData.data_array_from_delete]
            }
            else{
                if(!this.temporaryData.data_array_from_import.length==0){

                    this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.tableData[this.projectVersion]]
                }
                else{
                    this.temporaryData.data_array=[]
                }

            }
            this.temporaryData.data_array_from_manual=[]
            this.changesOccuredInManual = false
            if(this.temporaryData.data_array.length ==0){
                this.showAddedValuesTable=false
            }

        },
        cancelDeleteRowsFunction(){
            this.showDeleteRows=false
            this.showCancelDelete=false
            this.showDeleteRowsButton = true
            this.showSaveDeleteButton = false
            if(this.temporaryData.data_array_from_import.length==0 && this.temporaryData.data_array_from_manual.length==0){
                this.temporaryData.data_array = []
            }
            else{

                this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.tableData[this.projectVersion]]
            }
            this.changesOccuredInDelete = false
            if(this.temporaryData.data_array.length ==0){
                this.showAddedValuesTable=false
            }
            this.tableData[this.projectVersion] = [...this.originalProjectData]

            
        },
        cancelAddOldData(){
            this.temporaryData.data_array_from_old_data = []
            if(this.changesOccuredInDelete){
                this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual,...this.temporaryData.data_array_from_delete]
            }
            else{
                this.temporaryData.data_array = [...this.temporaryData.data_array_from_import,...this.temporaryData.data_array_from_manual]

            }
            if(this.temporaryData.data_array.length ==0){
                this.showAddedValuesTable=false
            }
            this.showAddOldData=true
            
        },
        cancelParametersChanges(){
            this.temporaryData.layers = null
            this.temporaryData.n_batch = null
            this.temporaryData.alpha_value = null
            this.temporaryData.max_iter = null
            this.temporaryData.max_neurons = null
            this.temporaryData.neurons_opt = null
            this.visualizationLayers = this.projectData.layers[this.projectVersion]
            this.n_batch = this.projectData.n_batch[this.projectVersion]
            if(this.method==this.projectData.ml_method[this.projectVersion]){

                this.max_iter = this.projectData.max_iter[this.projectVersion]
            }
            this.max_neurons = this.projectData.max_neurons[this.projectVersion]
            this.alpha_value = this.projectData.alpha_value[this.projectVersion]
            this.neurons_opt = this.projectData.neurons_opt[this.projectVersion]
            this.changesOccuredInParam = false
            this.showCancelParametersChanges=false
            this.showResetParam=false
        },
        cancelLimitsChanges(){
            this.temporaryData.lim_point_closeness=[]
            this.temporaryData.input_min_lim_list=[]
            this.temporaryData.input_max_lim_list=[]
            this.lim_point_closeness = [...this.projectData.lim_point_closeness[this.projectVersion].map(x=>+x)]
            this.input_min_lim_list = [...this.projectData.input_min_lim_list[this.projectVersion].map(x=>+x)]
            this.input_max_lim_list = [...this.projectData.input_max_lim_list[this.projectVersion].map(x=>+x)]

            this.changesOccuredInLimits= false
            this.showCancelLimitsChanges=false
            this.showResetLimits=false
        },
        showConfirmParamChanges(){
            this.changesOccuredInParam=true
            this.showCancelParametersChanges=true
        },
        showConfirmLimChanges(){
            this.changesOccuredInLimits=true
            this.showCancelLimitsChanges=true
        },
        confirmDataset(){
            this.showDataset=false
            this.showEditDataset=true
            this.showDeleteValues=false
        },
        editAgainDataset(){
            this.showDataset=true
            this.showEditDataset=false
            this.showDeleteValues=true
        },
        async updateProject(id){
            const headers = {
                "Content-Type": "application/json",
                Authorization: this.authHeader
            }
            this.dataSent=true
            this.dataNotSent=false
            this.updatedData["variables_type"] = this.projectData.variables_type[0]
            this.updatedData["optimisation_target"] = this.projectData.optimisation_target[0]
            this.updatedData["opt_value"] = this.projectData.opt_value[0]
            const response = await fetch(
                `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/project/${id}`,
                { method:"PUT",
                    headers,
                    body:JSON.stringify(this.updatedData)}
            )     
            await response.json()
            this.$emit("backToProject", true)

        },
        async newVersionProject(){
            const headers = {
                "Content-Type": "application/json",
                Authorization: this.authHeader
            }
            this.dataSent=true
            this.dataNotSent=false
            this.updatedData["sortkey"] = this.projectData.sortKey[this.projectVersion]
            this.updatedData["variables_type"] = this.projectData.variables_type[0]
            this.updatedData["optimisation_target"] = this.projectData.optimisation_target[0]
            this.updatedData["opt_value"] = this.projectData.opt_value[0]
            const response = await fetch(
                `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/new_version_project`,
                { method:"POST",
                    headers,
                    body:JSON.stringify(this.updatedData)}
            )     
            await response.json()
            this.$emit("backToProject", true)
        },
        backToMakeChanges(){
            this.makeChanges = true
            this.displayChanges = false
        },
        submitUpdatedData(){

            this.updateProject(this.projectData.sortKey[this.projectVersion])
        },
        submitNewVersion(){
            this.newVersionProject()
        },
        confirmUpdates(){
            this.v$.$touch()
            

            if(!this.v$.$error){


                this.updatedData.data_array = [...this.temporaryData.data_array]
                this.nochangesmadetodataset=false
                if(this.updatedData.data_array.length ==0){
                    
                    this.updatedData.data_array = [...this.tableData[this.projectVersion]]
                    this.nochangesmadetodataset = true

                }
                
                
                if(this.showResetParam){
                    this.updatedData.layers = parseInt(this.temporaryData.layers)
                    this.updatedData.n_batch = parseInt(this.temporaryData.n_batch)
                    this.updatedData.alpha_value=this.temporaryData.alpha_value
                    this.updatedData.max_iter=parseInt(this.temporaryData.max_iter)
                    this.updatedData.max_neurons=parseInt(this.temporaryData.max_neurons)
                    if(this.nochangesmadetodataset){

                        this.updatedData.neurons_opt = parseInt(this.temporaryData.neurons_opt)
                    }
                    else{
                        this.updatedData.neurons_opt=0
                    }
                    if(this.changeMethod){
                        this.updatedData.neurons_opt = 0
                    }
                }
                else{
                    this.updatedData.layers = parseInt(this.visualizationLayers)
                    this.updatedData.n_batch = parseInt(this.n_batch)
                    this.updatedData.alpha_value=this.alpha_value
                    this.updatedData.max_iter=parseInt(this.max_iter)
                    this.updatedData.max_neurons=parseInt(this.max_neurons)
                    if(this.nochangesmadetodataset){

                        this.updatedData.neurons_opt = parseInt(this.neurons_opt)
                    }
                    else{
                        this.updatedData.neurons_opt=0
                    }
                    if(this.changeMethod){
                        this.updatedData.neurons_opt = 0
                    }
                }
                if(this.showResetLimits){
                    this.updatedData.input_min_lim_list = [...this.temporaryData.input_min_lim_list]
                    this.updatedData.input_max_lim_list=[...this.temporaryData.input_max_lim_list]
                    this.updatedData.lim_point_closeness = [...this.temporaryData.lim_point_closeness]
                }
                else{
                    this.updatedData.input_min_lim_list = [...this.input_min_lim_list]
                    this.updatedData.input_max_lim_list = [...this.input_max_lim_list]
                    this.updatedData.lim_point_closeness = [...this.lim_point_closeness]
                }

                this.updatedData.project_name=this.projectData.project_name[this.projectVersion]
                this.updatedData.project_description = this.projectDescription

                this.updatedData.headers = this.projectData.headers[0]
                this.updatedData.number_of_inputs = this.projectData.number_of_inputs[0]
                this.updatedData.method = this.method
                this.makeChanges = false
                this.displayChanges=true
                

                
            }
            else{
                alert("Please Fill In The Form Correctly")
            }
            
        },
        
    },
    computed:{
        changeMethod(){
            if(this.projectData.ml_method[this.projectVersion]==this.method){
                this.neurons_opt = this.projectData.neurons_opt[this.projectVersion]
                this.max_iter = this.projectData.max_iter[this.projectVersion]
                if(this.method =="ann"){
                    this.minMaxIter = 100
                    this.maxMaxIter = 5000
                }
                else{
                    this.minMaxIter = 2
                    this.maxMaxIter = 12
                    
                }
                return false
            }
            else{
                this.neurons_opt = 0
                if(this.method =="ann"){
                    this.max_iter = 2000
                    this.minMaxIter = 100
                    this.maxMaxIter = 5000
                }
                else{
                    this.max_iter = 6
                    this.minMaxIter = 2
                    this.maxMaxIter = 12
                    
                }
                return true
            }
        }
    },
    async created() {
    this.user = await Auth.currentAuthenticatedUser();
    this.username = this.user.username;
    this.session = await Auth.currentSession();
    this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
    this.numberOfInputs = this.projectData.number_of_inputs[0]
    if(parseInt(this.numberOfInputs)==2) this.maxLayers=70  
    else if(parseInt(this.numberOfInputs)==3) this.maxLayers=20
    else if(parseInt(this.numberOfInputs)==4) this.maxLayers=15
    else if(parseInt(this.numberOfInputs)>=5) this.maxLayers = 9

    for(let i =0;i< this.projectData.neurons_opt.length ; i++){
        if(this.projectData.neurons_opt[i] == "not saved"){
            this.projectData.neurons_opt[i] = 0
        }
    }
    
    
    for(let i =0;i< this.projectData.neurons_opt.length ; i++){
        if(this.projectData.ml_method[i]=="not_saved"){
            this.projectData.ml_method[i]='ann'
        }
    }

    
    
    this.neurons_opt = this.projectData.neurons_opt[this.projectVersion]
    this.method = this.projectData.ml_method[this.projectVersion]
    if (this.method=='ann'){
        this.neuronsMax = 30
        this.minMaxIter = 100
        this.maxMaxIter = 5000
    }
    else{
        this.neuronsMax=  200
        this.minMaxIter = 2
        this.maxMaxIter = 12
    }
    },
    mounted(){
        this.originalProjectData = [...this.tableData[this.projectVersion]]
        this.temporaryData.data_array_from_delete = [...this.tableData[this.projectVersion]]

    }
    
}
</script>
<style scoped>

table{
    background-color: white;
    width: 100%;
    height: fit-content;
    font-size: 1vw;
}
.sumbit{
    height: 4rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    
}
.confirmationbtn{
    border: #c2c1c1 solid 1px;
    padding: 2rem 4rem;
    width: 70%;
    margin: auto;
}
.pred-title{
  width: 75%;
  height: fit-content;
  margin: 2vh auto 9.5vh auto;
  background-color: transparent;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background: #002A7D;  /* fallback for old browsers */

}
.pred-title h4{
  font-size: 1.1vw;
  
   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.7vw;
  padding-bottom: 0.6vw;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px ;
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto -1vw;
  border-radius: 5px;
  letter-spacing: 2Px;

  
}
.pred-title button{
  font-size: 1.1vw;
  
  padding: 0.5vw 1vw;
  background-color: #002A7D;
  color: white;
  letter-spacing: 2px;
  
  
}
.blue-color{
  background: #002A7D;  /* fallback for old browsers */
   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.graph-title{
  width: 60vw;
  margin: 1vw auto;
 padding: 0.5rem 1vw;
  background-color: white;
  border-radius: 5px;
  
}
.graph-title h1{
    font-size: 1.1vw;
    margin: auto 0;
    letter-spacing: 2px;
    width: fit-content;
    border-bottom: black solid 1px;
    border-radius: 5px;
}
.parameterstable1{
    width: 30%;
}
.parameterstable2{
    width: 70%;
}
.parameterstable input{
    width: 5vw;
}

.suggestedpoints{
    width: 40vw;
    margin: auto;
}

.add-data-section{
    
    width: 25vw;
    margin: 1.5vw auto;
    padding: 0vh 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5vw;
    
    border-radius: 15px;
}
.add-data-section div{
    width: 22vw;
    
    
}
.add-data-section h4{
    background-color: blue;
    width: 20vw;
    text-align: center;
    color: white;
    padding: 1vh 1vw;
    border-radius: 15px;
}
.errormessage{
    color: red;
}
.tables{
    display: flex;
    justify-content: space-around;
    
    column-gap: 20px;
}
.cancelbtns{
    
    width:20vw;
    margin: 3vw auto;
    
}
.cancelbtns button{
    margin-bottom: 1vh;
}



.add_data{
    display: flex;
    justify-content: space-around;
}
.addraw{
    
    width: 100%;
    margin-top: 1.5vw;
    
}
.addraw input{
    width: 4vw;
}
.addrawbtns{
    margin: auto;
    
    display: flex;
    justify-content: space-around;
}
.sectionstitle{
    background-color: rgba(255, 255, 255, 0.4);
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: white;
}



.scrolltable{
    height: 40vh;
    overflow-y:scroll ;
    display: block;
    width: 35vw;
    margin: auto;
}
.datasettable{
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto ;
}
.makechanges{
    height: fit-content;
    margin: 2vh 1vw 4vh 0;
    padding: 0.5vh 1vw 2vh 1vw;
    background-color: white;
    

}


.input{
  height: fit-content;
  margin: 1vh 1vw 4vh 0;
  padding: 0.5vh 1vw 0vh 1vw;
  background-color: white;
  
}
.table-color{
  background: #8b8b8b;  /* fallback for old browsers */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.input-data-title{
  width: 50%;
  height: 6vh;
  margin: 1.5vh auto 5.5vh auto;
  background-color: transparent;
  border-radius: 20px;
  padding-left: 1vw;
  padding-right: 1vw;
  
  
  
}
.input-data-title h4{
  font-size: 1.1vw;
  
  background: #029676;  /* fallback for old browsers */
  
  color: white;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.7vw;
  padding-bottom: 0.6vw;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px ;
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto -1vw;
  border-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
}
.input-data-title button{
  font-size: 1.1vw;
  margin: auto -1vw auto 0;
  padding: 0.5vw 1vw;
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  -webkit-box-shadow: -4px 9px 16px 0px #1f1e1e; 
  box-shadow: -4px 9px 16px 0px #242323;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.data {
  max-height: 60vh;
  overflow-y: scroll;
  display: block;
  margin-bottom: 15px;
}

table {
  background-color: white;
  font-size: 0.85vw;
}
button{
    font-size: 1vw;
}
</style>