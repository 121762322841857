<template>


  <div class="project" v-if="ready=='true'">

    <div class="project__img">
      <img src="../assets/labpic.jpeg" alt="...">
    </div>
    <div class="project__info">
      <div class="project__date" style="color:white;">
        <span class="text-dark">Created On : {{ project.created_on }}</span>
      </div>
      <h1 class="project__title">{{ project.project_name }}</h1>
      <p v-if="project.project_description.length>0"  class="project__text">{{ project.project_description }}</p>
      <p v-if="!project.project_description.length>0"  class="project__text">No Description Provided</p>
      <p v-if="project.active_project"   class="project__text">Active Project <i class="bi bi-circle-fill" style="color:green"></i></p>
      <p v-if="!project.active_project"   class="project__text">Inactive Project <i class="bi bi-check2-circle" style="color:red"></i></p>
      

      <div class="details ">

        <ul class="list-group list-group-flush mb-1">
          <li class="list-group-item project-details">
            <h4 >Number Of Variables</h4>
            <h4 >{{ project.number_of_inputs }}</h4>
          </li>

          <li class="list-group-item project-details">
            <h4>Number Of Versions</h4>
            <h4>{{project.number_of_versions}}</h4>
          </li>
          <li class="list-group-item project-details">
            <h4>Optimization target</h4>
            <h4>{{opt_target}}</h4>
          </li>
        </ul>
        <div class="d-flex flex-row justify-content-between">
          <button class="btn btn-danger" @click="showDelete">Delete</button>
          <button
            class="btn text-light" style="background:#029676"
               @click="seeProject">View Results <i class="bi bi-arrow-right-circle"></i></button>

        </div>
        <div v-if="show" >
          <div class="mt-3 d-flex flex-row justify-content-around">

            <button class="btn text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              Delete a Version
            </button>
            <button class="btn text-dark" @click="deleteAllProject(project.number_of_versions)">Delete Project</button>
          </div>
          <div class="collapse" id="collapseExample">
            <ul class="d-flex flex-row justify-content-around card card-body mt-2" style="background:transparent">
              <li class="text-light p-2" style="background:grey; list-style:none; cursor:pointer" @click="sendToDelete(proj)" v-for="proj in parseInt(project.number_of_versions)" :key="proj">Version {{proj}}</li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>


  </div>
  <div class="project" v-if="ready=='false'">

    <div class="project__img">
      <img src="../assets/labpic.jpeg" alt="...">
    </div>
    <div class="project__info">
      <div class="project__date" style="color:white;">
        <span class="text-dark">Created On : {{ project.created_on }}</span>
      </div>
      <h1 class="project__title">{{ project.project_name }}</h1>
      <p v-if="project.project_description.length>0"  class="project__text">{{ project.project_description }}</p>
      <p v-if="!project.project_description.length>0"  class="project__text">No Description Provided</p>
      <div class="details ">

        <ul class="list-group list-group-flush mb-1">
          <li class="list-group-item project-details">
            <h4 >Number Of Variables</h4>
            <h4 >{{ project.number_of_inputs }}</h4>
          </li>

          <li class="list-group-item project-details">
            <h4>Number Of Versions</h4>
            <h4>{{project.number_of_versions}}</h4>
          </li>
          <li class="list-group-item project-details">
            <h4>Optimization target</h4>
            <h4>{{opt_target}}</h4>
          </li>
        </ul>
        <div class="d-flex flex-row justify-content-between">
          <button class="btn btn-danger" @click="showDelete">Delete</button>
          <h4 class="text-dark">
            Loading
          </h4>

        </div>
        <div v-if="show" >
          <div class="mt-3 d-flex flex-row justify-content-around">

            <button class="btn text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              Delete a Version
            </button>
            <button class="btn text-dark" @click="deleteAllProject(project.number_of_versions)">Delete Project</button>
          </div>
          <div class="collapse" id="collapseExample">
            <ul class="d-flex flex-row justify-content-around card card-body mt-2" style="background:transparent">
              <li class="text-light p-2" style="background:grey; list-style:none; cursor:pointer" @click="sendToDelete(proj)" v-for="proj in parseInt(project.number_of_versions)" :key="proj">Version {{proj}}</li>
            </ul>
          </div>
      </div>
    </div>
    <div   class="alert alert-warning text-center" role="alert">
              Calculations for the loading project can take up to {{awaitMessage}}
    </div>
    </div>


  </div>
  

</template>
<script>
import Mybutton from "./Mybutton.vue"

export default {
  name: "Projectitem",
  components: {
    Mybutton
  },
  data() {
    return {
      project: {},
      show:false,
      projectToDeleteInitial: this.data.sortKey.slice(0,-1),
      projectToDelete:"",
      projectVersionsToDelete:[],
      opt_target:'',
      awaitMessage:""
    }
  },
  props: {
    data: {},
    ready: ""
  },
  methods: {
    seeProject() {
      this.$emit("showPr", true)
    },
    deleteProj(index) {
      this.$emit("delProject", index)
    },
    showDelete(){
      if(this.show){
        this.show = false
      }
      else{
        this.show = true
      }
    },
    sendToDelete(version){
      this.projectToDelete = this.projectToDeleteInitial + String(version-1)
      this.deleteProj(this.projectToDelete)
    },
    deleteAllProject(value){
      for(let i = 0; i<parseInt(value); i++){
        this.projectVersionsToDelete.push(this.projectToDeleteInitial + String(i))
      }
      this.$emit("deleteAllProject", this.projectVersionsToDelete)
    }
  },
  created() {
    this.project = this.data
    if(this.project.optimisation_target=="max"){
      this.opt_target= "Maximize"
    }
    else if(this.project.optimisation_target=="min"){
      this.opt_target="Minimize"
    }
    else {
      this.opt_target = this.project.opt_value
    }
    if(parseInt(this.project.number_of_inputs)==4){
      this.awaitMessage = "1-3 minutes"
    }
    else if(parseInt(this.project.number_of_inputs)==5){
      this.awaitMessage = "2-4 minutes"
    }
    else if(parseInt(this.project.number_of_inputs)==6){
      this.awaitMessage = "3-5 minutes"
    }
    else if(parseInt(this.project.number_of_inputs)==7){
      this.awaitMessage = "6 minutes"
    }
    else if(parseInt(this.project.number_of_inputs)==8){
      this.awaitMessage = "7-10 minutes"
    }
    else{
      this.awaitMessage = "1-3 minutes"
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 80%;
  max-height: 80%;
  border-radius: 20px;
  box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .5);
}

.project {
  width: 27vw;
  max-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 1.5rem;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
  border-radius: 20px;

  background-color: #f0f0f0;
  margin:1% auto;

}
@media screen and (min-height:1000px){
  .project{
    width: 50vw;
  }
 
}
@media screen and (max-width:1050px){
  .project{
    width: 50vw;
  }
  
}
.project:hover {
  background-color: #f0f0f0;
  animation: scaleup 0.3s linear;
  animation-fill-mode: forwards;
}

@keyframes scaleup {
  to {
    transform: scale(1.02);

  }

}

.project__img {
  min-width: 95%;
  max-width: 95%;
  min-height: 40%;
  max-height: 40%;
  
  
  
}


.project__text{
  color: black;
  padding:0.3rem 0.8rem;
  font-size: 1rem;
}

.project__title {
  background:#002060;
  border-radius: 20px;
  font-size: 1.5rem;
  color: white;
  padding: 0.6rem 1rem;
  text-align: center;
  letter-spacing: 4px;

}

.project__info {
  width:95%;
  font-size: 1rem;
}

.project-details {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: black;
  letter-spacing: 2px;
  margin-bottom: 0.2rem;
  border-radius: 10px;
  
}

.project__date {
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: 1rem;
}

/* @media screen and (max-width: 1060px) {
  .project {

    padding: 0.5rem 1rem;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 70%;
    margin: auto;
    margin-top: 5rem;
  }

  .project__img {
    min-width: 35rem;
    max-width: 50%;
    transform: translate(0, -6rem);
    padding-bottom: 0;
  }

  .project__info {
    margin-left: 0;
    min-width: 25rem;

  }

  .project__date, .project__title, .project__text {
    margin: 0;
  }

  .project__img {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .project {
    margin-top: 8rem;
    margin-bottom: 8rem;
    flex-direction: column;
  }

  .project__img {
    min-width: 20rem;
    max-width: 100%;
    transform: translate(0, -6rem);
    padding: 0 1rem;
  }

  .project__info {
    margin-left: 0;
    min-width: 18rem;
  }
} */
h4{
  font-size: 1.2rem;
}
.details{
  
  align-items: flex-end;
}
.list-group{
  width: 100%;
}
</style>
