<template>
  <div class="dash">
    <section id="dashboardlayout" v-if="authState === 'signedin' && userAttributesToPass.attributes['custom:agreed_stand_terms']=='true'">
      <div id="dashboard" v-if="showDashboardSection">
        
        <div class="dashboardheader">
          
          <NavBar />
          <QuotaDetails :userAttr="userData" :quotaConsum="quotaConsum" v-if="dataLoaded"/>
          <div :class="alertClass" role="alert" v-html="alertMessage"></div>
          <div class="dashboardcontent">
            <h1>DASHBOARD</h1>

            <div class="buttons">
                  <button @click="showAlert" class="btn">
                    <button
                    class="text-light btn me-2" style="background:#029676"
                    @click="createNewProject"
                    v-if="dataLoaded && activeLicense && canCreateNewProject"
                    ><i class="bi bi-plus-circle"></i> Create New Project</button>
                  </button>
              
                  <div class="dropdown me-3" v-if="projects.length != 1">
                    <button
                      class="btn dropdown-toggle text-light"
                      style="background:#002060"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-funnel"></i> Sort Projects By :
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" href="#" @click="sortAtoZ"
                          ><i class="bi bi-sort-alpha-down"></i> A to Z</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="sortZtoA"
                          ><i class="bi bi-sort-alpha-down-alt"></i> Z to A</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="sortNewToOld"
                          ><i class="bi bi-calendar3"></i> Last created</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="sortOldToNew"
                          ><i class="bi bi-calendar4-week"></i> First created</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="sortModefication"
                          ><i class="bi bi-calendar4-week"></i> Last Modified</a
                        >
                      </li>
                    </ul>
                  </div>
                  

                    <button
                      class="btn btn-danger btn-sm"
                      @click="signOut"
                    ><i class="bi bi-box-arrow-left"></i> Log Out</button>
                  
                  
            </div>
          </div>
          <div class="maincontent">
            <div v-if="projectLoading"  class="alert alert-warning text-center" role="alert">
              Calculations for the loading project can take up to {{awaitMessage}}
            </div>
            <div v-if="dataLoaded" class="d-flex flex-row justify-content-center">
              <div v-if="projects[0]" class="projects">
                <div class="project-item"  v-for="project in projects" :key="project">
                  
                    <slot v-if="!project.being_created">

                      <Projectitem
                        :data="project"
                        @showPr="projectPage(project.sortKey)"
                        @delProject="deletePro"
                        @deleteAllProject="deletePro"
                        ready="true"
                      />
                    </slot>
                    <slot v-if="project.being_created">

                      <Projectitem
                        :data="project"
                        @showPr="projectPage(project.sortKey)"
                        @delProject="deletePro"
                        @deleteAllProject="deletePro"
                        ready="false"
                      />
                    </slot>
                  
                  
                </div>
              </div>
              <div v-if="!projects[0]" >
                <br />
                <br />
                <div class="noprojects">
                  <h1>No projects created</h1>
                  <br />
                  <h4>Start Now</h4>
                  <Mybutton
                    bgc="green"
                    txtc="white"
                    textcontent="Create New Project"
                    @click="createNewProject"
                    v-if="canCreateNewProject && activeLicense"
                  />
                </div>
              </div>
            </div>

            <div class="projects" style="min-height:60vh" v-if="!dataLoaded">
              <div class="spinner-grow text-light spin" role="status">

              </div>
                
              
            </div>
          </div>
        </div>
            <Footer colorr="white" />

      </div>
      <div v-if="showNewProjectSection" class="newproject">
        <NewProject
          @projectCreated="showDashboard2"
          @showdash="showDashboard"
          :numActProj="numberOfActiveProjects"
        />
        <br>
        <br>
            <Footer colorr="white" />

      </div>
      <div v-if="switchToProject">
        <div
          class="spinner-border position-absolute top-50 start-50 text-light"
          role="status"
          v-if="dataNotLoaded">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div v-if="showProjectSection">
          <!-- <Project
            :data="projectData"
            :graphs="projectgraphs"
            @dashb="showDashboard"
            @refreshPage="showDashboard2"
            @deleteThisProject="deletePro"
            @requestSignOut="signOut"
          /> -->
          <Project2 
            :data="projectData"
            :graphs="projectgraphs"
            :activelicense="activeLicense"
            @dashb="showDashboard"
            @dashbRefrsh="showDashboard2"
            @refreshPage="showDashboard2"
            @deleteThisProject="deletePro"
            @requestSignOut="signOut"
            @activateDisactivateProject="activateDisactivate"
          />
        </div>
      </div>
    </section>
    <section id="termsAndConditions" class="d-flex justify-content-center align-items-center" v-if="authState==='signedin' && userAttributesToPass.attributes['custom:agreed_stand_terms']=='false'">
      <div class="terms">

        <div class="termsTitle d-flex justify-content-center align-items-center">
          <img class="img img-responsive navbar-brand" height="100"
                                                src="../assets/logo.png">
          <h1>Legal Standard Terms</h1>
        </div>
        <div class="termsContent">
          <object data="./terms.pdf" type="application/pdf" >
        </object>
        </div>
        <div class="termsAgree d-flex flex-column justify-content-center align-items-center">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value=true v-model="agreeToTerms" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              I agree and accept the Legal Standard Terms
            </label>
          </div>
          <button class="btn btn-success" v-if="!loading && agreeToTerms" @click="acceptTerms()" >
            Access the platform
          </button>
          <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>


      
    </section>
    
      <div v-if="authState != 'signedin'" class="authform">
      <div class="f">

          <amplify-authenticator>
            <amplify-sign-in
              slot="sign-in"
              hide-sign-up="true"
              header-text="Sign in to access the platform"
            >
            </amplify-sign-in>
          </amplify-authenticator>
      </div>

          <Footer colorr="white" />
        
        

      </div>
    </div>

  
</template>

<script>
import NewProject from "../components/NewProject.vue";
import Projectitem from "../components/Project-item.vue";
import Mybutton from "../components/Mybutton.vue";
import QuotaDetails from "../components/quotaDetails.vue"
import Project2 from "../components/Project2.vue"
import NavBar from "../components/Navbar.vue";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import Footer from "../components/footer.vue"
export default {
  mounted() {
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },
  components: {
    Mybutton,
    Projectitem,
    NavBar,
    NewProject,
    Project2,
    QuotaDetails,
    Footer
  },
  data() {
    return {
      agreeToTerms:false,
      showDashboardSection: true,
      shownNewProjectSection: false,
      showProjectSection: false,
      switchToProject: false,
      project: {},
      projectId: undefined,
      projectData: {},
      projectgraphs: {},
      projects: [],
      get_projects_response:{},
      inputdata: "",
      predictiondata: "",
      errordata: "",
      user: undefined,
      userData: {
                 attributes:"loading" 
              },
      quotaConsum:{
        "number_of_projects_created":"loading" 

      },
      loading:false,
      auth: Auth,
      username: null,
      session: {},
      authHeader: {},
      authState: undefined,
      unsubscribeAuth: undefined,
      status: undefined,
      dataLoaded: false,
      timer: null,
      timer2: null,
      refresh: false,
      projectsReady: true,
      canCreateNewProject:false,
      numberOfActiveProjects:0,
      alertMessage:'',
      alertClass:'',
      userAttributesToPass:{
        "attributes":{}
      },
      activeLicense:false,
      awaitTime:"",
      projectLoading:false,
      awaitMessage:""
    };
  },
  methods: {
    async acceptTerms(){
      this.loading=true
      const headers ={
        Authorization:this.authHeader,
        "Content-Type":"application/json"

      }
      const response = await fetch(
        `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/users`,
        { headers }
      );
      
      this.$router.go("/dashboard")    },
    compareProjectNamesFromAtoZ(a, b) {
      if (a.project_name < b.project_name) {
        return -1;
      }
      if (a.project_name > b.project_name) {
        return 1;
      }
      return 0;
    },
    compareProjectNamesFromZtoA(a, b) {
      if (a.project_name > b.project_name) {
        return -1;
      }
      if (a.project_name < b.project_name) {
        return 1;
      }
      return 0;
    },
    compareProjectDatesFromNewToOld(a, b) {
      if (new Date(a.created_on) > new Date(b.created_on)) {
        return -1;
      }
      if (new Date(a.created_on) < new Date(b.created_on)) {
        return 1;
      }
      return 0;
    },
    compareProjectDatesFromOldToNew(a, b) {
      if (new Date(a.created_on) < new Date(b.created_on)) {
        return -1;
      }
      if (new Date(a.created_on) > new Date(b.created_on)) {
        return 1;
      }
      return 0;
    },
    compareProjectModefFromNewToOld(a, b) {
      if (a.modified_on && b.modified_on) {
        if (new Date(a.modified_on) > new Date(b.modified_on)) {
          return -1;
        }
        if (new Date(a.created_on) < new Date(b.modified_on)) {
          return 1;
        }
      } else if (!a.modified_on && b.modified_on) {
        return 1;
      }
      return 0;
    },
    compareProjectModef(a, b) {
      if (a.modified_on && b.modified_on) {
        if (new Date(a.modified_on) < new Date(b.modified_on)) {
          return 1;
        }
        if (new Date(a.created_on) > new Date(b.modified_on)) {
          return -1;
        }
      } else if (!a.modified_on && b.modified_on) {
        return 1;
      }
      return 0;
    },
    sortAtoZ() {
      this.projects.sort(this.compareProjectNamesFromAtoZ);
    },
    sortZtoA() {
      this.projects.sort(this.compareProjectNamesFromZtoA);
    },
    sortNewToOld() {
      this.projects.sort(this.compareProjectDatesFromNewToOld);
    },
    sortOldToNew() {
      this.projects.sort(this.compareProjectDatesFromOldToNew);
    },
    sortModefication() {
      this.projects.sort(this.compareProjectModef);
    },

    createNewProject() {
      (this.showDashboardSection = false),
        (this.showProjectSection = false),
        (this.switchToProject = false),
        (this.showNewProjectSection = true);
    },
    showDashboard() {
      (this.showDashboardSection = true),
        (this.showNewProjectSection = false),
        (this.showProjectSection = false),
        (this.switchToProject = false);
    },
    showDashboard2() {
      this.showProjectSection = false;
      (this.showNewProjectSection = false),
        (this.switchToProject = false),
        (this.showDashboardSection = true);
      this.dataLoaded = false;
      this.getProjects();
    },
    async projectPage(id) {
      (this.showDashboardSection = false),
        (this.shownNewProjectSection = false),
        (this.dataNotLoaded = true),
        (this.switchToProject = true),
        await this.getProject(id);
      this.showProjectSection = true;
      this.dataNotLoaded = false;
    },
    async getProject(id) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: this.authHeader,
      };
      const response = await fetch(
        `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/project/${id}`,
        { headers }
      );
      this.project = await response.json();
      this.projectData = this.project.data;
      this.projectData["userName"] = this.username;
      this.projectData["number_of_iterations_per_project_allowed"] = this.userData.attributes["custom:num_iter_per_pro_alw"]
      this.projectData["overall_iterations_allowed"] = parseInt(this.userData.attributes["custom:num_all_iter_alw"])> parseInt(this.quotaConsum["number_of_overall_iterations"])
      this.projectData["iterations_per_project_allowed"] = parseInt(this.userData.attributes["custom:num_iter_per_pro_alw"]) +1 > this.projectData["layers"].length
      this.projectData["activate_project_allowed"] = parseInt(this.userData.attributes["custom:num_act_proj_alw"]) > this.quotaConsum.number_of_active_projects
      this.projectData["active_project"] = this.projectData["active_project"][0]
      if(this.project.data.number_of_inputs[0]<=3){

        this.projectgraphs = this.project.graphs;
      }
      else{
        this.projectgraphs = "no graphs"
      }
    },
    async activateDisactivate(value){
      await this.getProject(value)
      this.showDashboard2()
      this.$router.go("/dashboard")
    },
    async getProjects() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: this.authHeader,
      };
      try {
        const response = await fetch(
          "https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/projects",
          { headers }
        );
        try{

          this.get_projects_response = await response.json();
          if("license_quota" in this.get_projects_response){

            this.projects = this.get_projects_response["projects"]
            this.quotaConsum = this.get_projects_response["license_quota"]
            if(parseInt(this.quotaConsum["number_of_projects_created"])< parseInt(this.userData.attributes["custom:num_proj_alw"])){
              this.canCreateNewProject = true
            }
            else{
              this.canCreateNewProject = false
            }
          }
          else{

            this.projects = this.get_projects_response
          }
        }catch{
        }
        this.dataLoaded = true;
        this.sortNewToOld();
        this.numberOfActiveProjects = 0
        
        

        for (let i = 0; i < this.projects.length; i++) {
          if(this.projects[i].active_project){
            this.numberOfActiveProjects ++
          }
          
          if (this.projects[i].being_created) {
            await new Promise((r) => setTimeout(r, 10000));
            return this.getProjects();
          }
          
        }
        
        this.quotaConsum["number_of_active_projects"] = this.numberOfActiveProjects
      } catch (e) {
        console.log("error");
      }
    },
    async deleteProject(id, all) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: this.authHeader,
      };

      const response = await fetch(
        `https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/project/${id}`,
        { method: "DELETE", headers }
      );
      if(all){

        this.showDashboard2();
      }
    },
    async signOut() {
      await Auth.signOut();
      this.$router.push("/")
    },
    deletePro(value) {
      if(typeof value=== 'string'){

        const toDelete = confirm(
          "Are you sure you want to delete this version of the project ?"
        );
        if (toDelete) {
          this.deleteProject(value,true);
        }
      }
      else if(typeof value=== 'object'){
        const valuesReversed = value.reverse()
        const projectToDel = confirm(
          "This will delete the project with all its versions, Proceed ?"
        )
        if(projectToDel){
          for(let i =1; i<=valuesReversed.length; i++ ){
            if(i == value.length){
              this.deleteProject(valuesReversed[i-1], true)
            }
            else{

              this.deleteProject(valuesReversed[i-1], false)
            }
          }
        }
      }
    },
    async getAuthData() {
      try {
        this.userData = await Auth.currentAuthenticatedUser({bypassCache: true});
        this.username = this.userData.username;
        this.session = await Auth.currentSession();
        this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
        this.userAttributesToPass = await this.userData
        if(this.userAttributesToPass.attributes['custom:license_is_active']=='True'){
          this.activeLicense=true
                   

        }
        else{
          
          this.activeLicense=false
        }
       
        // if(this.userAttributesToPass.attributes["custom:agreed_stand_terms"] == 'true'){

        //   
        // }
        this.getProjects();
      } catch (e) {
        console.log("error");
      }
    },
    showAlert(){
      if(!this.canCreateNewProject){
        this.alertClass = "alert alert-danger text-center"
        this.alertMessage = "You can't create new projects. License maxed out, please contact to upgrade your license"
      }
    }
  },
  async created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if(this.authState==="signedin"){
        this.getAuthData()
      }
    });

  },
  
};
</script>


<style scoped>
.f{
  height: 80vh;
}

.authform{
  height: 100%;
}

.footer{
  background-color: aqua;
}
object{
  width: 100%;
  height: 100%;
}
.terms{
  background-color: #f0f0f0;
  width: 60%;
  height: 70%;
  border-radius: 10px;
  
}
.terms .termsTitle{
  height: 15%;
}
.terms .termsContent{
  height: 70%;
  width:80%;
  margin: auto;
  background-color: white;
  overflow-y: scroll;
}
.terms .termsAgree{
  height: 15%;
}
#termsAndConditions{
  width: 100vw;
  height: 100vh;
}
.button {
  background: rgb(4, 179, 10);
  background: linear-gradient(
    90deg,
    rgba(4, 179, 10, 0.8533788515406162) 0%,
    rgba(4, 131, 44, 0.8169642857142857) 48%,
    rgba(5, 70, 0, 0.8253676470588236) 100%
  );
}
.spin {
  margin-top: 10rem;
  width: 4rem;
  height: 4rem;
}

.dashboardheader {
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.dashboardcontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  width: 80%;
  
  padding: 0 3%;
  border-radius: 10px;
}

.maincontent{
  width: 70%;
  margin: 3% auto;
  padding: 1%;
  border-radius: 15px;
  border: 2px solid white;
}
.dashboardcontent h1 {
  color: #002060;
  display: flex;
  align-items: center;
  font-size: 3rem;
  letter-spacing: 4px;
  
  margin: auto 0;
}
.buttons {
  display: flex;
  align-items: center;
}
.dash {
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  background-image: linear-gradient(120deg, #193c7091 0%, #002060 150%),
    url("../assets/dash2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.noprojects {
  width: fit-content;
  min-width: 500px;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.25);
  margin-left: auto;
  border-radius: 20px;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noprojects h1,
h4 {
  color: white;
}
.projects {
  width: 95%;
  height: fit-content;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 1% 1%;
  border-radius: 20px;
  row-gap: 20px;
}
.project-item{
  width: 45%;
  margin-left:0;
  
}
@media screen and (min-height:1000px){
  .projects{
    flex-direction: column;
  }
  .project-item{
    width: 80%;
  }
  .dashboardcontent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding-bottom: 3%;
  }
}
@media screen and (max-width:1050px){
  .projects{
    flex-direction: column;
  }
  .project-item{
    width: 80%;
  }
}

@media screen and (max-width: 1000px) {
  .dashboardcontent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding-bottom: 3%;
  }
  .spin {
    margin-top: 7rem;
  }
}
@media screen and (max-width: 800px) {
  
  .spin {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 500px) {
  .dashboardcontent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 200px;
  }
  .dashboardcontent h1 {
    font-size: 25px;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .spin {
    margin-top: 0.5rem;
  }
}
</style>
