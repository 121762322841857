<template>
    <div class="quotacontent2">
        <h3>{{userAttr.username}}</h3>
        <h3>License Quota <i class="bi bi-list" data-bs-toggle="collapse" href="#collapseExample1234" role="button" aria-expanded="false" aria-controls="collapseExample1234"></i>   </h3>
          
        
    </div>
    <div v-if="userAttr.attributes['custom:license_is_active']=='false'" class="alert alert-danger" role="alert">
      Your license expired, Please Contact us to renew it
    </div>
    <div class="collapse" id="collapseExample1234">
      <div class="quotacontent">
            <div class=" quota-item">
              <h4 >New Projects Allowed : </h4>
              <div class="d-flex flex-row justify-content-between">
                <h5 v-if="userAttr.attributes!='laoding'">{{userAttr.attributes["custom:num_proj_alw"]}}</h5>
                <h6 v-if="quotaConsum['number_of_projects_created'] !='loading'">Left : {{parseInt(userAttr.attributes["custom:num_proj_alw"]) - parseInt(quotaConsum["number_of_projects_created"])}}</h6>

              </div>
            </div>
            <div class=" quota-item">
              <h4 class="">Overall Iterations Allowed : </h4>
              <div class="d-flex flex-row justify-content-between">
                <h5 v-if="userAttr.attributes!='laoding'">{{userAttr.attributes["custom:num_all_iter_alw"]}}</h5>
                <h6 v-if="quotaConsum['number_of_projects_created'] !='loading'">Left : {{parseInt(userAttr.attributes["custom:num_all_iter_alw"]) - parseInt(quotaConsum["number_of_overall_iterations"])}}</h6>
              </div>
            </div>
            <div class=" quota-item">
              <h4 class="">Active Projects Allowed : </h4>
              <div class="d-flex flex-row justify-content-between">
                <h5 v-if="userAttr.attributes!='laoding'">{{userAttr.attributes["custom:num_act_proj_alw"]}}</h5>
                <h6 v-if="quotaConsum['number_of_projects_created'] !='loading'">Left : {{parseInt(userAttr.attributes["custom:num_act_proj_alw"]) - parseInt(quotaConsum["number_of_active_projects"])}}</h6>
              </div>
            </div>
            <div class=" quota-item">
              <h4 class="">Versions per project allowed: </h4>
              <div class="d-flex flex-row justify-content-between">
                <h5 ></h5>
                <h6 v-if="userAttr.attributes!='laoding'">{{parseInt(userAttr.attributes["custom:num_iter_per_pro_alw"]) +1}}</h6>
              </div>
            </div>
            <div class=" quota-item">
              <h4 class="">License ends : </h4>
              <div class="d-flex flex-row justify-content-between">
                <h5 ></h5>
                <h6 v-if="userAttr.attributes!='laoding'">{{userAttr.attributes["custom:lic_end"]}}</h6>
              </div>
            </div>
          </div>
    </div>
</template>
<script>
export default {
    name:"QuotaDetails",
    props:{
        userAttr:{},
        quotaConsum:{}
    },
    
}
</script>
<style scoped>
.quotacontent{
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  
  border-radius: 10px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}
.quotacontent2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 0 2%;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #f0f0f0;
}

.quota-item h4, h5{
  font-size: 1rem;
}
h4{
    color: #002060;
}
.quota-item h6{
    font-size: 1rem;
    background-color: #002060;
    border-radius: 5px;
    color:white;
    padding: 0 0.2rem;
}
.quota-item{
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 0.1rem 0.8rem;

}
</style>